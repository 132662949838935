import * as React from 'react';
import { useState } from 'react';
import './component.css';

interface INameMap {
    [key: string]: string;
}

interface IDate {
    year: number;
    month: number;
    day ?: number
}
interface IDateRange {
    start: IDate;
    end: IDate;
}
interface IDateMappings {
    [key: string]: IDateRange;
}

interface IProps {
    title: string;
    options: string[];
    nameMappings: INameMap;
    dateMappings: IDateMappings;
    currentSelection: string;
    onChange: Function;
}

export default function NeuBacktestDropdown(props: IProps) {

    const [ isExpanded, setIsExpanded ] = useState(false);
    const { title, dateMappings, options, nameMappings, currentSelection, onChange } = props;

    const reverseMap = nameMappings ?

        Object.keys(nameMappings).reduce((fullMap: any, currentKey: string) => {
            const mapCopy = Object.assign({}, fullMap);
            const currentValue = nameMappings[currentKey];
            mapCopy[currentValue] = currentKey;
            return mapCopy;
        }, {})
        :
        null;


    const cssExpanded = isExpanded ? "expanded" : "";

    const firstItem = nameMappings ? nameMappings[currentSelection] : currentSelection; 
    const otherItems = options
        .filter((option: string) => { return option != currentSelection })
        .map((name: string) => { return (nameMappings ? nameMappings[name] : name) })
        .filter(name => !!name);


    //We need to do this to stop styling issues with the selected option being too big
    const selectedIsTooBig = firstItem.length > 20;
    const cssSmallerText = selectedIsTooBig  ? "smallerDropdownText" : "";


    return (
        <div className="marketConditions historic">
            <div className="description">
                <h4>{title}</h4>
                <p>{`${dateMappings[currentSelection].start.day || 31}/${dateMappings[currentSelection].start.month}/${dateMappings[currentSelection].start.year} - ${dateMappings[currentSelection].end.day || 31}/${dateMappings[currentSelection].end.month}/${dateMappings[currentSelection].end.year}`}</p>
            </div>
            <div className="field">
                <ul className={"customSelect " + cssExpanded} onClick={() => { setIsExpanded(!isExpanded) }}>
                    <li value={firstItem} key={firstItem} className={cssSmallerText} onClick={(e) => {
                        if (isExpanded) { onChange(reverseMap ? reverseMap[e.currentTarget.innerText] : e.currentTarget.innerText); }
                    }}>{firstItem}</li>
                    {otherItems.map((option: string) => {
                        return <li value={option} key={option} className={option.length > 20 ? "smallerDropdownText" : ""} onClick={(e) => {
                            if (isExpanded) { onChange(reverseMap ? reverseMap[e.currentTarget.innerText] : e.currentTarget.innerText); }
                        }}>{option}</li>
                    })}
                </ul>
            </div>
        </div>
    );
}
