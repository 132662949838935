import * as React from 'react';
//import OutputGraph from '../OutputGraph/OutputGraph';
import Backtest from '../Backtest/Backtest';
import Matrix from '../Matrix/Matrix';


import './component.css';

//type imports
import {IBacktestOutputs,IProjectionOutputs} from '../../Types/DataInterfaces';
import HelperInfo from '../HelperInfo/HelperInfo';
import { userInfo } from 'os';


interface IProps{
    projectionData: IProjectionOutputs;
    backtestData: IBacktestOutputs;
    currentTabId: number;
    currentRequest: any;
    onBacktestResponse: any;
    isRetirementLoading: boolean;
    isBacktestLoading: boolean;
    setIsBacktestLoading: React.Dispatch<React.SetStateAction<boolean>>;
    userData: any;
}


export default function Outputs(props: IProps) {
    const { projectionData, backtestData, currentTabId, currentRequest, onBacktestResponse, isRetirementLoading, isBacktestLoading, setIsBacktestLoading, userData} = props;
    let graphOutputDisplay = (<div> Enter some values to begin! </div>);

    //-----------Handle matrix output----------
    const matrixOutput = (<Matrix outputs={projectionData.matrixOutput} currentAge={projectionData.currentAge} loading={isRetirementLoading}/>) ;
    //-----------------------------------------------

    /*----------------Now handle graph output--------
    if (projectionData && projectionData.graphOutput && Object.entries(projectionData.graphOutput).length > 0) {
        const mappedAssetAllocationName = AssetAllocationToColumnNameMap(projectionData.AssetAllocationInRequest);
        graphOutputDisplay = <OutputGraph values={projectionData.graphOutput} userDefinedName={mappedAssetAllocationName.toUpperCase()} />
    }
    *///----------------------------------------------

    //-----------Lastly handle backtest output-------
    const backtestOutput = (<Backtest setIsBacktestLoading={setIsBacktestLoading} values={backtestData.backtestOutput}  currentRequestValues={currentRequest} onBacktestResponse={onBacktestResponse} loading={isBacktestLoading}/>); 
    //----------------------------------------------
    const graphOutput = (<div className="graphOutputs" id="go">{graphOutputDisplay}</div>);
    const helpOutput = (<HelperInfo/>);

    const potentialOutputs = [matrixOutput, backtestOutput, helpOutput]; //ADD ALL POTENTIAL OUTPUT SCREENS HERE
    const pOlength = potentialOutputs.length;
    const outputTodisplay = currentTabId % pOlength;
    const toDisplay = potentialOutputs[outputTodisplay]; 


    return (
        <div className="outputs" id="output">
            {toDisplay} 
        </div>
    )

}