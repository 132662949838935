import * as React from 'react';
import { useLayoutEffect } from 'react';
import InputWrapper from '../InputWrapper/InputWrapper';
import { InputShapes } from '../../Types/DataInterfaces';


const $ = require("jquery");

interface IProps {
    onChange: Function;
    label: string;
    currentValue: string;
    options: string[];
    optionsMapper?: Function;
    helpGenerator?: Function;
    zIndex?: number;
}

export default function NeuExpando(props: IProps) {

    const { options, currentValue, label, onChange, optionsMapper, helpGenerator, zIndex } = props;
    //todo: localise this jquery to JUST this component.  Right now it modifies EVERY element in the DOM that meets these conditions
    useLayoutEffect(() => {

        $(".editableSelect").click(function(this: HTMLElement){
            $(this).closest(".inputs").addClass("expanded");
            $(this).addClass("expanded");
            $(".legals").addClass("voided");
            $('.veil').removeClass("hideVeil");
        });

        $(".editableSelect li").click(function (this: HTMLElement) {
            $(this).closest(".inputs").addClass("expanded");
            $(this).addClass("expanded");
            $(this).siblings().removeClass("expanded");
            //$(this).parent().parent().addClass("noFade");
        });

    }, []);


    const optionsinlist = optionsMapper
        ? options.map((option: string) => { return  optionsMapper(option) })
        : options;

    const reverseMap = optionsMapper && options.reduce((map: any, option: string) => {
        const temp = Object.assign({}, map);
        temp[optionsMapper(option)] = option
        return temp;
    }, {});


    const firstItem = optionsMapper ? optionsMapper(currentValue) : currentValue;
    const otherItems = optionsinlist.filter((option: string) => { return option != firstItem });

    const help = helpGenerator && helpGenerator(label);

    return (
        <InputWrapper label={label} easeOpacity={true} shape={InputShapes.plain} help={help} zIndex={zIndex} noFade>
            <ul className="editableSelect investment investmentBenchmark">
                <li value={firstItem} key={firstItem} onClick={(e) => { optionClick(e,reverseMap,onChange)}}>{firstItem}</li>
                {otherItems.map((option: string) => {
                    return (<li value={option} key={option} onClick={(e) => { optionClick(e,reverseMap,onChange)}}>{option}</li>);
                })}
            </ul> 
         </InputWrapper>    
    ); 

}

function optionClick(e:React.MouseEvent<HTMLLIElement,MouseEvent>, reverseMap:any, onChange:Function) {
    const reversedName = reverseMap[e.currentTarget.innerText];
    onChange(reverseMap ? reversedName : e.currentTarget.innerText);
}
