import { IDateDaily, IDateRange } from '../Types/DataInterfaces'
import digitToMonthName from './digitToMonthName';

export default function getDateArray(range: IDateRange<IDateDaily>): number[]{

    const currentDate = new Date(`${digitToMonthName(range.start.month, true)} ${range.start.day}, ${range.start.year}`);
    const endDate = new Date(`${digitToMonthName(range.end.month, true)} ${range.end.day}, ${range.end.year}`);

    function isWeekday(date: Date) {
        return date.getDay() != 0 && date.getDay() != 6;
    }

    const weekdaysInMonth = [];
    let currentWeekdayCount = 0;

    while (currentDate.getDate() != endDate.getDate() || currentDate.getMonth() != endDate.getMonth() || currentDate.getFullYear() != endDate.getFullYear()){

        isWeekday(currentDate) && currentWeekdayCount++;
        const monthCopy = currentDate.getMonth();
        currentDate.setDate(currentDate.getDate() + 1);

        if (currentDate.getMonth() != monthCopy) {
            weekdaysInMonth.push(currentWeekdayCount);
            currentWeekdayCount = 0;
        }

    }

    weekdaysInMonth.push(currentWeekdayCount);

    return weekdaysInMonth;
}
