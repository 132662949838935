// source: Types.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.SmartShield.Api.V100.AssetAllocation', null, global);
goog.exportSymbol('proto.SmartShield.Api.V100.BackTestScenarioCases', null, global);
goog.exportSymbol('proto.SmartShield.Api.V100.RiskProfile', null, global);
goog.exportSymbol('proto.SmartShield.Api.V100.ShockCase', null, global);
/**
 * @enum {number}
 */
proto.SmartShield.Api.V100.AssetAllocation = {
  HIGHGROWTH: 0,
  GROWTH: 1,
  BALANCED: 2,
  MODERATE: 3,
  USERDEFINEDALLOCATION: 4
};

/**
 * @enum {number}
 */
proto.SmartShield.Api.V100.ShockCase = {
  GFC: 0,
  TECHBUBBLE: 1
};

/**
 * @enum {number}
 */
proto.SmartShield.Api.V100.RiskProfile = {
  RPHIGHGROWTH: 0,
  RPGROWTH: 1,
  RPBALANCED: 2,
  RPMODERATE: 3
};

/**
 * @enum {number}
 */
proto.SmartShield.Api.V100.BackTestScenarioCases = {
  LONG15YEARHISTORY: 0,
  GLOBALFINANCIALCRISIS: 1,
  BULLMARKET: 2,
  BEARANDREBOUND: 3,
  COVID19: 5
};

goog.object.extend(exports, proto.SmartShield.Api.V100);
