/* eslint-disable */ 
import { commaify } from "../Commaify";
import { PToE } from '../ProtobufferAndExternalNameConversion';
import { truncate} from '../Truncate';
import NormalFees from '../NormalFeeTable';
import SSFees from '../SSFeeTable';

import getDateByDivision from '../../utility/getDateByDivision' 
import digitToMonthName from '../../utility/digitToMonthName' 
import getDateArray from '../getDateArray';

export function drawPdf(projection, projectionGfc, backtest, request, userData,pdfName, callback) {
    require("buffer");
    const doc = new PDFDocument({ size: [839.5, 593.5] });
    const stream = doc.pipe(blobStream());
    const maxWidth = doc.page.width;
    const maxHeight = doc.page.height;
    const isUserDefined = request.standardInputValues.additionalComparison == "USERDEFINEDALLOCATION"; 

    //page 1
    const page1Image = require("./Templates/page1");
    doc.image(new Buffer(page1Image.replace('data:image/png;base64,', ""), "base64"), 0, 0, { width: maxWidth });
    const advisorName = `${userData.givenName} ${userData.surname}`;//

    const publishingDate = new Date().toLocaleString("en-AU").split(",")[0];//Get just the date not the time

    doc.fillColor("#404040");
    doc.text(advisorName, 270, 369);
    doc.text(publishingDate, 310, 387);


    //page 2
    doc.addPage();
    const page2Image = isUserDefined ? require("./Templates/page2B") : require("./Templates/page2");
    doc.image(new Buffer(page2Image.replace('data:image/png;base64,', ""), "base64"), 0, 0, { width: maxWidth });

    const { currentAge, targetAge, currentBalance, annualWithdrawal, additionalComparison, investmentEquivalent, ssEquivalent } = request.standardInputValues;
    //const { auDirectPropertyWeight, auGovFixedIncomeWeight, auListedPropertyWeight, australianCashWeight, australianEquityWeight, intlEquityHedgedWeight, intlEquityUnhedgedWeight, intlGovFixedIncomeWeight, uCorpFixedIncomeWeight } = request.customAssetAllocationValues;
    const { additionalComparisonFees, additionalComparisonAlpha } = request.slideOutValues;

    const currentBalanceFormatted = commaify(currentBalance);//
    const annualIncome = commaify(annualWithdrawal);//
    const smartShield = PToE(true)(ssEquivalent);//
    const reference = PToE(false)(investmentEquivalent);//
    const benchmark = PToE(false)(additionalComparison);//


        //Now place the elements on the page dude
    doc.fontSize(24);
    doc.fillColor("#404040");
    doc.text(`${currentAge}`, 51 ,227);
    doc.text(`${targetAge}`, 51 ,308);
    doc.text(`$${currentBalanceFormatted}`, 51 ,387);
    doc.text(`$${annualIncome}`, 51 ,465);


    //custom portfolio

        //Every other row
    const customFields = request.customAssetAllocationValues;
    const nameX = 571;
    const valueX = 713;
    const ySpacing = 31;
    const firstY = 195;

    const maxValueWidth = 50;

    //fees alpha
    const ssFees = SSFees[projection.likelihoodRingValues[0].name.toUpperCase()];
    const ssAlpha = 0;
    const refFees = NormalFees[projection.likelihoodRingValues[1].name.toUpperCase()];
    const refAlpha = 0;

    //Portfolios
    doc.fillColor("white");
    printPage2PortfolioLabel(smartShield, ssFees, ssAlpha ,253, 214, doc);
    printPage2PortfolioLabel(reference, refFees, refAlpha ,253, 300, doc);
    printPage2PortfolioLabel(benchmark, additionalComparisonFees,additionalComparisonAlpha,253, 386, doc);
    /*doc.text(smartShield, 253, 214);
    doc.text(reference, 253, 300);
    doc.text(benchmark, 253, 386);

    doc.fontSize(24);
    doc.fillColor("#404040");
    doc.text(additionalComparisonFees, 571, 495, {lineBreak:false});
    doc.text(additionalComparisonAlpha, 698, 495, { lineBreak: false });*/

    //The custom fee allocations
    if (isUserDefined) {
        const customValues = [
            { name: "AU Equity", value: customFields.australianEquityWeight },
            { name: "Intl Equity Hedged", value: customFields.intlEquityHedgedWeight },
            { name: "Intl Equity Unhedged", value: customFields.intlEquityUnhedgedWeight },
            { name: "AU Govt Fixed Income", value: customFields.auGovFixedIncomeWeight },
            { name: "AU Corp Fixed Income", value: customFields.auCorpFixedIncomeWeight },
            { name: "Intl Govt Fixed Income", value: customFields.intlGovFixedIncomeWeight },
            { name: "AU Listed Property", value: customFields.auListedPropertyWeight },
            { name: "AU Direct Property", value: customFields.auDirectPropertyWeight },
            { name: "AU Cash", value: customFields.australianCashWeight }
        ];

        doc.fillColor("white");
        doc.fontSize(12);
        customValues.forEach((field, index) => {
            const percentage = `${field.value}%`;
            doc.text(field.name, nameX, firstY + ySpacing * index);
            doc.text(percentage, valueX + (maxValueWidth - doc.widthOfString(percentage)), firstY + ySpacing * index, { lineBreak: false });
        });

        //The smartshield row
        doc.fillColor("#48fd80");
        const dropdownOptionsMap = {};
        dropdownOptionsMap["HIGHGROWTH"] = "High Growth";
        dropdownOptionsMap["GROWTH"] = "Growth";
        dropdownOptionsMap["BALANCED"] = "Balanced";
        dropdownOptionsMap["MODERATE"] = "Moderate";

        const smartShieldChoice = dropdownOptionsMap[request.smartShieldChoice];
        const smartShieldChoiceValue = `${request.smartShieldChoiceValue}%`;
        doc.text(smartShieldChoice, nameX + 65, firstY - ySpacing);
        doc.text(smartShieldChoiceValue, valueX + (maxValueWidth - doc.widthOfString(smartShieldChoiceValue)), firstY - ySpacing);
    }

    //page 3 - circles
    doc.addPage();
    const majorCenterY = 396.7;
    const majorCenterLeftLeft = 139.15;
    const majorCenterLeftRight = majorCenterLeftLeft + 174;
    const majorCenterRightLeft = majorCenterLeftRight + 213.5;
    const majorCenterRightRight = majorCenterRightLeft + 173;

    const page3Image = require("./Templates/page3");
    doc.image(new Buffer(page3Image.replace('data:image/png;base64,',""), "base64"), 0, 0, {width: maxWidth});

    circularDisplay(majorCenterLeftLeft,majorCenterY,projection.likelihoodRingValues,"%",doc);
    circularDisplay(majorCenterLeftRight,majorCenterY,projectionGfc.likelihoodRingValues,"%",doc);
    circularDisplay(majorCenterRightLeft,majorCenterY,projection.shortfallRingValues,"yrs",doc);
    circularDisplay(majorCenterRightRight,majorCenterY,projectionGfc.shortfallRingValues,"yrs",doc);

    //page4 - bars
    doc.addPage();

    const page4Image = isUserDefined ? require("./Templates/page4B") : require("./Templates/page4");
    doc.image(new Buffer(page4Image.replace('data:image/png;base64,', ""), "base64"), 0, 0, { width: maxWidth });

    barDisplay(majorCenterLeftLeft, majorCenterY+57.7,projection.withdrawalBarValues,doc,isUserDefined);
    barDisplay(majorCenterLeftRight, majorCenterY+57.7,projectionGfc.withdrawalBarValues,doc, isUserDefined);
    barDisplay(majorCenterRightLeft, majorCenterY+57.7,projection.bequestBarValues,doc);
    barDisplay(majorCenterRightRight, majorCenterY+57.7,projectionGfc.bequestBarValues,doc);

    //Page 5 - Backtests
    const { longHistory, gfc, bullMarket, bearRebound,covid } = backtest;
    newBacktestPage(longHistory,maxWidth,maxHeight, doc);
    newBacktestPage(gfc,maxWidth,maxHeight,doc);
    newBacktestPage(bullMarket,maxWidth,maxHeight, doc);
    newBacktestPage(bearRebound, maxWidth, maxHeight, doc);
    newBacktestPage(covid, maxWidth, maxHeight, doc, true);
    //page 6 - disclaimers
    doc.addPage();
    const page6Image = require("./Templates/page6");
    doc.image(new Buffer(page6Image.replace('data:image/png;base64,',""), "base64"), 0, 0, {width: maxWidth});

    const disclaimers1 = `1. Details on the outputs and assumptions presented in the report can be found online at https://smartshield.millimandigital.com/assumptions

2. General Disclaimer

Milliman Pty Ltd ABN 51 093 828 418 AFSL 340679 is licensed to deal and advise to wholesale clients only as defined by Section 761G of the Corporations Act 2001.

The information contained in this website is for use by persons and institutions who are "wholesale clients” only. To the extent that this document may contain financial product advice, it is general advice only as it does not take into account the objectives, financial situation or needs of any particular person. Further, any such general advice does not relate to any particular financial product and is not intended to influence any person in making a decision in relation to a particular financial product. Read the full disclaimer.

Australian Financial Services Disclaimer
The information contained in this website has been prepared by Milliman Pty Ltd ABN 51 093 828 418 AFSL 340679 (Milliman AU) for provision to Australian financial services (AFS) licensees and their representatives, and for other persons who are wholesale clients under section 761G of the Corporations Act.

To the extent that this document may contain financial product advice, it is general advice only as it does not take into account the objectives, financial situation or needs of any particular person. Further, any such general advice does not relate to any particular financial product and is not intended to influence any person in making a decision in relation to a particular financial product. No remuneration (including a commission) or other benefit is received by Milliman AU or its associates in relation to any advice in this document apart from that which it would receive without giving such advice. No recommendation, opinion, offer, solicitation or advertisement to buy or sell any financial products or acquire any services of the type referred to or to adopt any particular investment strategy is made in this document to any person.
The information in relation to the types of financial products or services referred to in this document reflects the opinions of Milliman AU at the time the information is prepared and may not be representative of the views of Milliman, Inc., Milliman Financial Risk Management LLC, or any other company in the Milliman group (Milliman group). If AFS licensees or their representatives give any advice to their clients based on the information in this document they must take full responsibility for that advice having satisfied themselves as to the accuracy of the information and opinions expressed and must not expressly or impliedly attribute the advice or any part of it to Milliman AU or any other company in the Milliman group. Further, any person making an investment decision taking into account the information in this document must satisfy themselves as to the accuracy of the information and opinions expressed. Many of the types of products and services described or referred to in this document involve significant risks and may not be suitable for all investors. No advice in relation to products or services of the type referred to should be given or any decision made or transaction entered into based on the information in this document. Any disclosure document for particular financial products should be obtained from the provider of those products and read and all relevant risks must be fully understood and an independent determination made, after obtaining any required professional advice, that such financial products, services or transactions are appropriate having regard to the investor's objectives, financial situation or needs.
All investment involves risks. Any discussion of risks contained in this document with respect to any type of product or service should not be considered to be a disclosure of all risks or a complete discussion of the risks involved. Investing in foreign securities is subject to greater risks including: currency fluctuation, economic conditions, and different governmental and accounting standards. There are also risks associated with futures contracts. Futures contract positions may not provide an effective hedge because changes in futures contract prices may not track those of the securities they are intended to hedge. Futures create leverage, which can magnify the potential for gain or loss and, therefore, amplify the effects of market, which can significantly impact performance. There are also risks associated with investing in fixed income securities, including interest rate risk, and credit risk.
`;

    addDisclaimers(doc,disclaimers1); // < this doesn't really need its own function but I thought it might get a bit chunky so I'm giving it it's own space

    //page 7 - page 2 of disclaimers

    doc.addPage();
    doc.image(new Buffer(page6Image.replace('data:image/png;base64,',""), "base64"), 0, 0, {width: maxWidth});

    const disclaimers2 = `Any source material included in this document has been sourced from providers that Milliman AU believe to be reliable from information available publicly or with consent of the provider of the source material. To the fullest extent permitted by law, no representation or warranty, express or implied is made by any company in the Milliman group as to the accuracy or completeness of the source material or any other information in this document.

Past performance information provided in this document is not indicative of future results and the illustrations are not intended to project or predict future investment returns.

Any index performance information is for illustrative purposes only, does not represent the performance of any actual investment or portfolio. It is not possible to invest directly in an index.

Any hypothetical, back tested data illustrated herein is for illustrative purposes only, and is not representative of any investment or product. Results based on simulated or hypothetical performance results have certain inherent limitations. Unlike the results shown in an actual performance record, these results do not represent actual trading. Also, because these trades have not actually been executed, these results may have under-or over-compensated for the impact, if any, of certain market factors, such as lack of liquidity. Simulated or hypothetical trading programs in general are also subject to the fact that they are designed with the benefit of hindsight. No representation is being made that any account will or is likely to achieve profits or losses similar to these being shown. For any hypothetical simulations illustrated, Milliman AU does not manage, control or influence the investment decisions in the underlying portfolio. The underlying portfolio in hypothetical simulations use historically reported returns of widely known indices. In certain cases where live index history is unavailable, the index methodology provided by the index may be used to extend return history. To the extent the index providers have included fees and expenses in their returns, this information will be reflected in the hypothetical performance.`;

    addDisclaimers(doc,disclaimers2); // < this doesn't really need its own function but I thought it might get a bit chunky so I'm giving it it's own space

    //disclaimers here//
    doc.end();

    stream.on('finish',()=>{
        const blob = stream.toBlob('application/pdf');

        const temp = document.createElement("a");
        temp.style = "display:none";
        const url = window.URL.createObjectURL(blob);
        temp.href = url;
        temp.download = pdfName;
        setTimeout(() => {temp.click()},0);
        callback();
    });  
}

function drawBacktestLabel(portfolioX, portfolioY, label, value, difference, pdfkit) {
    pdfkit.fillColor("#404040");
    pdfkit.fillOpacity(1);
    pdfkit.fontSize(7);
    pdfkit.text(label, portfolioX, portfolioY);
    pdfkit.fontSize(14);
    const offset = pdfkit.widthOfString(value);
    pdfkit.text(value, portfolioX, portfolioY + 20);
    pdfkit.fontSize(7);
    pdfkit.text(difference, portfolioX + offset, portfolioY + 15);
}

function flexBacktestGraphSet(width, height, bottom, minVal, maxVal, range ,withMarkers, pdfkit) {

    const maxBacktestHeight = bottom - height;
    const startOffsetUnits = 12 - range.start.month;
    const endOffsetUnits = range.end.month;
    pdfkit.fontSize(24);
    const yearsToDisplay = range.end.year - range.start.year - 1; //exclude the start year from years to label.  We still display it in the line graph.
    const yearsUnits = yearsToDisplay * 12;
    const totalUnits = startOffsetUnits + endOffsetUnits + yearsUnits
    const unitLength = width / totalUnits;
    const globalXOffset = startOffsetUnits * unitLength;
    const divisionBoundaries = range.end.year - range.start.year;
    const unitsPerDivision = 12;



    //Draw graph markers
    if (withMarkers) {
        for (let divisionCount = 0; divisionCount < divisionBoundaries + 1; divisionCount++) { //The +1 here is to add an extra iteration for the final year which we excluded earlier in the unitLength calculations
            const currentPosition = divisionCount * unitsPerDivision * unitLength + globalXOffset;

            const grad = pdfkit.linearGradient(currentPosition, maxBacktestHeight, currentPosition, bottom);
            grad.stop(0, "#ffffff")
                .stop(0.5, "#5e5e5e")
                .stop(1, "#5e5e5e");
            pdfkit.rect(currentPosition, maxBacktestHeight, 0.1, bottom);
            pdfkit.fill(grad);

            const backtestLabelfontSize = 10;
            pdfkit.fontSize(backtestLabelfontSize);
            const labelValue = range.start.year + divisionCount;
            const labelSettings = { lineBreak: false, align: "center", width:unitsPerDivision * unitLength, height: backtestLabelfontSize };
            pdfkit.fillColor("#404040");
            pdfkit.text(labelValue.toString(), currentPosition - unitsPerDivision * unitLength, bottom - 20, labelSettings);
        }
    }


    const yValOnPdf = getLerper(bottom, bottom - height, minVal, maxVal); //the larger a number the lower it is on the graph
    return (points, x, lineColor, fillColor) => {
        const pointDistance = width / (points.length - 1);
        pdfkit.moveTo(x, bottom);
        points.forEach((point, index) => {
            const yVal = yValOnPdf(point.value);
            const xVal = x + pointDistance * index;
            pdfkit.lineTo(xVal, yVal);
        });
        pdfkit.lineTo(x + width, bottom);
        pdfkit.fillOpacity(0.33)
            .strokeOpacity(0.5)
            .fillAndStroke(fillColor, lineColor);
    }
}

function flexBacktestGraphSetDaily(width, height, bottom, minVal, maxVal, range ,withMarkers, pdfkit) {

    if (withMarkers) {
        const maxBacktestHeight = bottom - height;
        pdfkit.fontSize(24);

        const xAxisData = getDateArray(range);
        const totalUnits = xAxisData.reduce((acc, num) => { return acc + num });
        const unitLength = width / totalUnits;

        let currentPosition = 0;
        //Draw graph markers
        for (let divisionCount = 0; divisionCount < xAxisData.length; divisionCount++) {
            currentPosition = currentPosition + xAxisData[divisionCount] * unitLength;

            const grad = pdfkit.linearGradient(currentPosition, maxBacktestHeight, currentPosition, bottom);
            grad.stop(0, "#ffffff")
                .stop(0.5, "#5e5e5e")
                .stop(1, "#5e5e5e");
            pdfkit.rect(currentPosition, maxBacktestHeight, 0.1, bottom);
            pdfkit.fill(grad);

            const backtestLabelfontSize = 10;
            pdfkit.fontSize(backtestLabelfontSize);
            const labelDate = getDateByDivision(divisionCount, range.start);
            const labelValue = `${digitToMonthName(labelDate.month)} ${labelDate.year}`;
            const labelPositionCoeff = xAxisData[divisionCount] <= 10 ? 10 : xAxisData[divisionCount];
            const labelX = currentPosition - labelPositionCoeff * unitLength;

            const labelSettings = { lineBreak: true, align: "center", width: xAxisData[divisionCount] * unitLength, height: backtestLabelfontSize };
            pdfkit.fillColor("#404040");
            pdfkit.text(labelValue, labelX, bottom - 20, labelSettings);
        }
    }

    const yValOnPdf = getLerper(bottom, bottom - height, minVal, maxVal); //the larger a number the lower it is on the graph
    return (points, x, lineColor, fillColor) => {
        const pointDistance = width / (points.length - 1);
        pdfkit.moveTo(x, bottom);
        points.forEach((point, index) => {
            const yVal = yValOnPdf(point.value);
            const xVal = x + pointDistance * index;
            pdfkit.lineTo(xVal, yVal);
        });
        pdfkit.lineTo(x + width, bottom);
        pdfkit.fillOpacity(0.33)
            .strokeOpacity(0.5)
            .fillAndStroke(fillColor, lineColor);
    }
}


function getLerper(pdfRangeBottom,pdfRangeTop,dataRangeMin,dataRangeMax){
    return (value)=>{
        const lerpValue = (value-dataRangeMin)/(dataRangeMax-dataRangeMin);
        return pdfRangeBottom - (pdfRangeBottom-pdfRangeTop)*lerpValue;
    }
}
function barDisplay(centerRootX,centerRootY,data,pdfkit, isUserDefined=false){

    const benchColor = "#504B98";
    const equivColor = "#27A6D2";
    const SSColor = "#33CA96";
    const maxBarHeight = 115.5; //idk just guessing here

    const valueA = data[0].value;
    const valueB = data[1].value;
    const valueC = data[2].value;
    const labelA = data[0].display;
    const labelB = data[1].display;
    const labelC = data[2].display;
    const offsetA = data[0].offset;
    const offsetB = data[1].offset;
    const offsetC = data[2].offset;
    const portfolioA = "SmartShield";
    const portfolioB = "Reference";
    const portfolioC = "Client";

    const barSpread = 54;
    const barXA = centerRootX - barSpread;
    const barXB = centerRootX;
    const barXC = centerRootX + barSpread;

    const leftOffset = isUserDefined ? 27 : 0; //The lef offset helps center the bars when we remove the chosen allocation bar

    const drawBar = barSet(centerRootY, maxBarHeight, pdfkit);
    drawBar(barXA+leftOffset,offsetA,SSColor,valueA, portfolioA,labelA);
    drawBar(barXB+leftOffset,offsetB,equivColor,valueB, portfolioB,labelB);
    !isUserDefined && drawBar(barXC,offsetC,benchColor,valueC, portfolioC,labelC);
}

function barSet(centerY, maxBarHeight, pdfkit){
    return (barX,offset,color, value,portfolio, label)=>{
        pdfkit.lineWidth(13.7);
        pdfkit.lineCap("round")
            .moveTo(barX,centerY)
            .lineTo(barX,centerY - (maxBarHeight*offset))
            .stroke(color);

        //print value
        const dollarValue = "$"+value;
        const maxTextwidth = 100;
        const valueX = barX-(maxTextwidth/2); 
        const valueY = centerY + 30;
        pdfkit.fontSize(7);
        const barLablefontSize = 5;
        const labelSettings = {lineBreak:false,align:"center", width:maxTextwidth, height:barLablefontSize } ;
        pdfkit.text(dollarValue, valueX, valueY ,labelSettings);

        //print explainers
        const portfolioY = valueY + 30;
        const labelY = portfolioY + 8;

        pdfkit.fontSize(barLablefontSize);
        pdfkit.text(portfolio, valueX,portfolioY,labelSettings);
        pdfkit.text(label, valueX,labelY,labelSettings);
    }
}


function circularDisplay(majorCenterX, majorCenterY,data,postfix,pdfkit){

    const legendSpread = 44.6;
    const legendXA = majorCenterX - legendSpread;
    const legendXB = majorCenterX;
    const legendXC = majorCenterX + legendSpread;
    const legendY = majorCenterY + 96.2;

    const benchColor = "#504B98";
    const equivColor = "#27A6D2";
    const SSColor = "#33CA96";
    const smallRadius = 27.3;
    const mediumRadius = smallRadius * 1.553956;
    const largeRadius = smallRadius * 2.10311751;
    const legendRadius = 17.65;

    const valueA = data[0].value;
    const valueB = data[1].value;
    const valueC = data[2].value;
    const labelA = data[0].display;
    const labelB = data[1].display;
    const labelC = data[2].display;
    const portfolioA = "SmartShield";
    const portfolioB = "Reference";
    const portfolioC = "Client";
    const offsetA = data[0].offset;
    const offsetB = data[1].offset;
    const offsetC = data[2].offset;


    const drawMajorCircle = circleSet(majorCenterX,majorCenterY,pdfkit);
    drawMajorCircle(offsetA,largeRadius,SSColor);
    drawMajorCircle(offsetB,mediumRadius,equivColor);
    drawMajorCircle(offsetC,smallRadius,benchColor);

    const drawLegendCircle = legendSet(legendY, legendRadius,postfix,pdfkit);
    drawLegendCircle(legendXA, offsetA, SSColor,valueA,labelA, portfolioA);
    drawLegendCircle(legendXB, offsetB, equivColor,valueB,labelB, portfolioB);
    drawLegendCircle(legendXC, offsetC, benchColor,valueC,labelC, portfolioC);
}

function legendSet(legendY, legendRadius,postfix,pdfkit){
    return (legendX, offset,color,value,label,portfolio) =>{
        pdfkit.fontSize(7);//14 is the default
        pdfkit.lineWidth(3.7);
        pdfkit.lineCap("round")
            .circle(legendX, legendY, legendRadius)
            .rotate(90,{origin:[legendX,legendY]})
            .dash(getCircumference(legendRadius)*offset,{space:getCircumference(legendRadius)})
            .stroke(color);
        pdfkit.rotate(-90,{origin:[legendX,legendY]} ) 
        //print value
        const percentValue = value+postfix;
        const valueX = legendX-pdfkit.widthOfString(percentValue)/2.2;
        const valueY = legendY-pdfkit.heightOfString(percentValue)/3;
        pdfkit.text(percentValue, valueX, valueY ,{lineBreak:false});

        //print explainers
        const portfolioY = valueY + 30;
        const labelY = portfolioY + 8;

        const legendLablefontSize = 5;
        const legendLabelSettings = {lineBreak:false,align:"center", width:legendRadius*2, height:legendLablefontSize } ;
        pdfkit.fontSize(legendLablefontSize);
        pdfkit.text(portfolio, legendX - legendRadius, portfolioY, legendLabelSettings);
        pdfkit.text(label, legendX - legendRadius, labelY, legendLabelSettings);
    }
}

function getCircumference(radius){
    return 2*Math.PI*radius;
}

function circleSet(centerX,centerY,pdfkit){
    return (offset,radius,color)=>{
    pdfkit.lineWidth(13.7);
    const circumference = getCircumference(radius);
        pdfkit.lineCap("round")
            .circle(centerX,centerY,radius)
            .rotate(90,{origin:[centerX,centerY]})
            .dash(circumference*offset,{space:circumference})
            .stroke(color);
        pdfkit.rotate(-90,{origin:[centerX,centerY]});
    }
}

function percentageDifference(start, end) {
    const difference = (end - start)/start * 100;
    const prefix = difference > 0 ? "+" : "";
    return `${prefix}${difference.toFixed(1)}%`;
}


function newBacktestPage(backtest, maxWidth,maxHeight,doc, isDaily){

    doc.addPage();
    const page5Image = require("./Templates/page5");
    doc.image(new Buffer(page5Image.replace('data:image/png;base64,',""), "base64"), 0, 0, {width: maxWidth});

    const benchColor = "#504480";
    const equivColor = "#008bcc";
    const SSColor = "#00b69f";
    const minVal = backtest.min;//
    const maxVal = backtest.max;//
    const pointsA = backtest.lineData.ss.data;//The next three lines access enums from the types file.  Those types can't be used here as PDFKIt doesn't play nice with TypeScript yet
    const pointsB = backtest.lineData.equiv.data;//
    const pointsC = backtest.lineData.chosen.data;//

    const ranges = {
        LONG15YEARHISTORY: { start: { year: 2004, month: 12 } , end: { year: 2019, month: 12 }, displayName:"Long Term History" },
        GLOBALFINANCIALCRISIS: { start: { year: 2007, month: 10 } , end: { year: 2009, month: 2 }, displayName:"Global Financial Crisis" },
        BULLMARKET: { start: { year: 2009, month: 12 } , end: { year: 2019, month: 12 }, displayName:"Bull Market" },
        BEARANDREBOUND: { start: { year: 2007, month: 10 } , end: { year: 2012, month: 12 }, displayName:"Bear and Rebound" },
        COVID19: { start: { year:2020,month: 1, day:31 }, end: {year:2020,month:3, day:31 }, displayName:"Covid-19" },
    };

    const range = ranges[backtest.scenario]

    const graphInitializer = isDaily ? flexBacktestGraphSetDaily : flexBacktestGraphSet;
    const drawBacktestGraph = graphInitializer(maxWidth,doc.page.height/1.8,maxHeight,minVal,maxVal,range,true,doc);    
    drawBacktestGraph(pointsA,0,SSColor,"#1bd9c1");
    drawBacktestGraph(pointsB,0,equivColor,"#18a9ed");
    drawBacktestGraph(pointsC,0,benchColor,"#8f84b5");

    const drawGraphLegend = flexBacktestGraphSet(45, 20,230,minVal,maxVal,range,false,doc)
    drawGraphLegend(pointsA,347,SSColor,"#1bd9c1");
    drawGraphLegend(pointsB,541,equivColor,"#18a9ed");
    drawGraphLegend(pointsC,735,benchColor,"#8f84b5");


    //draw y axis

    const maxBacktestHeight = maxHeight - doc.page.height / 1.8; 
    const yValueBetweenPoints = (maxVal - minVal) / 11;
    const yVals = [minVal + yValueBetweenPoints * 4, minVal + yValueBetweenPoints * 7, minVal + yValueBetweenPoints * 10];
    const intervalSpace = (maxHeight - maxBacktestHeight) / 11;
    const intervalPoints = [maxHeight - intervalSpace * 4, maxHeight - intervalSpace * 7, maxHeight - intervalSpace * 10];
    const paddingLeft = 10;

    doc.fontSize(10);
    doc.fillOpacity(1);
    doc.fillColor('#404040');


    doc.text(`$${truncate(yVals[0], 0)}`, paddingLeft, intervalPoints[0], { lineBreak: false });
    doc.text(`$${truncate(yVals[1], 0)}`, paddingLeft, intervalPoints[1], { lineBreak: false });
    doc.text(`$${truncate(yVals[2], 0)}`, paddingLeft, intervalPoints[2], { lineBreak: false });


    //Date
    const dateRange = isDaily ? `${range.start.day} ${digitToMonthName(range.start.month, true)} ${range.start.year} - ${range.start.day} ${digitToMonthName(range.end.month,true)} ${range.end.year}` : `${range.start.year} - ${range.end.year}`;
    const rangeName = range.displayName;
    doc.fillOpacity(1);
    doc.fillColor("#404040");
    doc.fontSize(20);
    doc.text(`${rangeName} (${dateRange})`, 51,150);

    //Backtest Tag Labels
    const startingBalance = pointsA[0].value;

    doc.fontSize(14);
    doc.text(`$${commaify(startingBalance)}`,66.5,223);
    const ssPortfolio = backtest.lineData.ss.name;//
    const refPortfolio = backtest.lineData.equiv.name;//
    const benchPortfolio = backtest.lineData.chosen.name;//
    const lastAValue = pointsA[pointsA.length - 1].value;
    const lastBValue = pointsB[pointsB.length - 1].value;
    const lastCValue = pointsC[pointsC.length - 1].value;
    const differenceA = percentageDifference(startingBalance, lastAValue);
    const differenceB = percentageDifference(startingBalance, lastBValue);
    const differenceC = percentageDifference(startingBalance, lastCValue);
    drawBacktestLabel(260,203,`SmartShield ${ssPortfolio}`,`$${commaify(lastAValue)}`,differenceA,doc);
    drawBacktestLabel(454,203,`Reference ${refPortfolio}`,`$${commaify(lastBValue)}`,differenceB,doc);
    drawBacktestLabel(648,203,`Client ${benchPortfolio}`,`$${commaify(lastCValue)}`,differenceC,doc);


}

function printPage2PortfolioLabel(name,fee,alpha,x,y, pdfkit){
    pdfkit.fontSize(24);
    pdfkit.fillColor("white");
    const feeAlphaY = y + 26.8;
    pdfkit.text(name, x, y);
    pdfkit.fontSize(11);
    pdfkit.fillColor("#7a8790");
    pdfkit.text(`${fee}%`, x+28, feeAlphaY);
    pdfkit.text(`${alpha}%`, x+169, feeAlphaY);
}

function addDisclaimers(pdfkit, text) {
    pdfkit.fillColor("#404040");
    pdfkit.fontSize(9);
    pdfkit.text(text, 51,140);
}

/*
 
Any source material included in this document has been sourced from providers that Milliman AU believe to be reliable from information available publicly or with consent of the provider of the source material. To the fullest extent permitted by law, no representation or warranty, express or implied is made by any company in the Milliman group as to the accuracy or completeness of the source material or any other information in this document.

Past performance information provided in this document is not indicative of future results and the illustrations are not intended to project or predict future investment returns.

Any index performance information is for illustrative purposes only, does not represent the performance of any actual investment or portfolio. It is not possible to invest directly in an index.

Any hypothetical, back tested data illustrated herein is for illustrative purposes only, and is not representative of any investment or product. Results based on simulated or hypothetical performance results have certain inherent limitations. Unlike the results shown in an actual performance record, these results do not represent actual trading. Also, because these trades have not actually been executed, these results may have under-or over-compensated for the impact, if any, of certain market factors, such as lack of liquidity. Simulated or hypothetical trading programs in general are also subject to the fact that they are designed with the benefit of hindsight. No representation is being made that any account will or is likely to achieve profits or losses similar to these being shown. For any hypothetical simulations illustrated, Milliman AU does not manage, control or influence the investment decisions in the underlying portfolio. The underlying portfolio in hypothetical simulations use historically reported returns of widely known indices. In certain cases where live index history is unavailable, the index methodology provided by the index may be used to extend return history. To the extent the index providers have included fees and expenses in their returns, this information will be reflected in the hypothetical performance.
 
 */
