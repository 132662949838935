import * as React from 'react';
import "./component.css";

interface IProps {
    zIndex: number;
    onClick: Function;
}

export default function ClickVeil(props: IProps) {

    const { zIndex, onClick } = props;

    return (<div className="veil hideVeil" style={{ zIndex: zIndex }} onClick={() => { onClick() }}></div>)

}
