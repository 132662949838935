import * as EngineRequest from '../JSProtoBuffer/GeneratedCode/ProjectionData_pb'; //TODO CHange to client request
import { protobufferToPascalCase } from "./ProtobufferPascalMap"
const { AssetAllocation,  RiskProfile, ShockCase, BackTestScenarioCases} = require('../JSProtoBuffer/GeneratedCode/Types_pb');

export class ProtoXml{

    constructor() {
        this.advisorTool = new EngineRequest.ProjectionData.AdvisorToolType();
        this.userDefined = new EngineRequest.ProjectionData.UserDefinedInvestment();
        this.userDefSim = {};
        this.advisorToolSim = {}; 
    }

    getRequest(){
        const request = new EngineRequest.ProjectionData();
        this.advisorTool.setUserdefinedassetallocation(this.userDefined);
        request.setAdvisortool(this.advisorTool);
        return request;
    }

    printSim(){
        this.advisorToolSim["UserDefinedAssetAllocation"] = this.userDefSim;
        const toPrint = {
            ProjectionData: {
                AdvisorTool: this.advisorToolSim
            }
        }

        const convert = require('xml-js');
        console.log(convert.js2xml(toPrint, {compact:true, spaces:4}));
    }

    setAustralianequityweight(value) {
        this.userDefined.setAustralianequityweight(value);
        this.userDefSim["AustralianEquityWeight"] = value;
    };

    setIntlequityhedgedweight(value) {
        this.userDefined.setIntlequityhedgedweight(value);
        this.userDefSim["IntlEquityHedgedWeight"] = value;
    };
    setIntlequityunhedgedweight(value) {
        this.userDefined.setIntlequityunhedgedweight(value);
        this.userDefSim["IntlEquityUnhedgedWeight"] = value;
    };
    setAugovfixedincomeweight(value) {
        this.userDefined.setAugovfixedincomeweight(value);
        this.userDefSim["AuGovFixedIncomeWeight"] = value;
    };

    setAucorpfixedincomeweight(value) {
        this.userDefined.setAucorpfixedincomeweight(value);
        this.userDefSim["AuCorpFixedIncomeWeight"] = value;
    };

    setIntlgovfixedincomeweight(value) {
        this.userDefined.setIntlgovfixedincomeweight(value);
        this.userDefSim["IntlGovFixedIncomeWeight"] = value;
    };
    setAulistedpropertyweight(value) {
        this.userDefined.setAulistedpropertyweight(value);
        this.userDefSim["AuListedPropertyWeight"] = value;
    };
    setAudirectpropertyweight(value) {
        this.userDefined.setAudirectpropertyweight(value);
        this.userDefSim["AuDirectPropertyWeight"] = value;
    };
    setAustraliancashweight(value) {
        this.userDefined.setAustraliancashweight(value);
        this.userDefSim["AustralianCashWeight"] = value;
    };
    setSmartshieldhighgrowthweight(value) {
        this.userDefined.setSmartshieldhighgrowthweight(value);
        this.userDefSim["SmartShieldhighgrowthweight"] = value;
    };
    setSmartshieldgrowthweight(value) {
        this.userDefined.setSmartshieldgrowthweight(value);
        this.userDefSim["SmartShieldGrowthWeight"] = value;
    };
    setSmartshieldmoderateweight(value) {
        this.userDefined.setSmartshieldmoderateweight(value);
        this.userDefSim["SmartShieldModerateWeight"] = value;

    };
    setSmartshieldbalancedweight(value) {
        this.userDefined.setSmartshieldbalancedweight(value);
        this.userDefSim["SmartShieldBalancedWeight"] = value;
    };

    setEffectivedate(value){
        this.advisorTool.setEffectivedate(value);
        this.advisorToolSim["EffectiveDate"] = value;
	};
    setCurrentage(value){
        this.advisorTool.setCurrentage(value);
        this.advisorToolSim["CurrentAge"] = value;
	};
    setTargetage(value){
        this.advisorTool.setTargetage(value);
        this.advisorToolSim["TargetAge"] = value;
	};
    setCurrentbalance(value){
        this.advisorTool.setCurrentbalance(value);
        this.advisorToolSim["CurrentBalance"] = value;
	};
    setRequiredincome(value){
        this.advisorTool.setRequiredincome(value);
        this.advisorToolSim["RequiredIncome"] = value;
	};
    setPercentagefeesuserdefined(value){
        this.advisorTool.setPercentagefeesuserdefined(value);
        this.advisorToolSim["PercentageFeesUserDefined"] = value;
	};

    setPercentagefeesriskprofilebase(value){
        this.advisorTool.setPercentagefeesriskprofilebase(value);
        this.advisorToolSim["PercentageFeesRiskProfileBase"] = value;
	}; //Gets value in bps
    setPercentagefeesriskprofileek(value){
        this.advisorTool.setPercentagefeesriskprofileek(value);
        this.advisorToolSim["PercentageFeesRiskProfileEK"] = value;
	}; //Get value in bps
    setAlphariskprofilebase(value){
        this.advisorTool.setAlphariskprofilebase(value);
        this.advisorToolSim["AlphaRiskProfileBase"] = value;
	}; //As per the documentation
    setAlphauserdefined(value){
        this.advisorTool.setAlphauserdefined(value);
        this.advisorToolSim["AlphaUserDefined"] = value;
	};
    setAssetallocation(value){
        this.advisorTool.setAssetallocation(value);
        this.advisorToolSim["AssetAllocation"] = this.valueToKey(value, AssetAllocation);
	};
    setRiskprofile(value){
        this.advisorTool.setRiskprofile(value);
        this.advisorToolSim["RiskProfile"] = this.valueToKey(value,RiskProfile);
	};
    setShockevents(value){
        this.advisorTool.setShockevents(value);
        this.advisorToolSim["ShockEvents"] = this.valueToKey(value,ShockCase);
	};
    setInflationadjusted(value){
        this.advisorTool.setInflationadjusted(value);
        this.advisorToolSim["InflationAdjusted"] = value;
	};
    setUserdefinedassetallocation(value){
        this.advisorTool.setUserdefinedassetallocation(value);
        this.advisorToolSim["UserDefinedAssetAllocation"] = value;
	};
    setReturnmode(value){
        this.advisorTool.setReturnmode(value);
        this.advisorToolSim["ReturnMode"] = value;
	};
    setBacktestscenario(value){
        this.advisorTool.setBacktestscenario(value);
        this.advisorToolSim["BacktestScenario"] = this.valueToKey(value,BackTestScenarioCases);
	};
    setAdviserid(value){
        this.advisorTool.setAdviserid(value);
        this.advisorToolSim["AdviserID"] = value;
	};
    setBusinessid(value){
        this.advisorTool.setBusinessid(value);
        this.advisorToolSim["BusinessID"] = value;
	};
    setEmailaddress(value){
        this.advisorTool.setEmailaddress(value);
        this.advisorToolSim["EmailAddress"] = value;
	};

    valueToKey(value,object){
        const reverseMap = Object.keys(object).reduce((acc, key) => {
            const copy = { ...acc };
            const value = object[key];
            copy[value] = key;
            return copy;
        }, {});

        const protoKey = reverseMap[value];

        return protobufferToPascalCase(protoKey);
    }

}