import * as React from 'react';
import '../NeuComponents.css';
import './legals.css';
import BackButton from '../BackButton/BackButton';

export default function Disclaimer() {

    document.title = "SmartShield Disclaimer";

    return (
        <div className="legalDoc">
            <BackButton url="/"/>
            <h1>Disclaimers</h1>

            <h2>General Disclaimer</h2>
                <p>Milliman Pty Ltd ABN 51 093 828 418 AFSL 340679 is licensed to deal and advise to wholesale clients only as defined by Section 761G of the Corporations Act 2001.</p>
                <p>The information contained in this website is for use by persons and institutions who are "wholesale clients” only. To the extent that this document may contain financial product advice, it is general advice only as it does not take into account the objectives, financial situation or needs of any particular person. Further, any such general advice does not relate to any particular financial product and is not intended to influence any person in making a decision in relation to a particular financial product. Read the full disclaimer.</p>
            <h2>Australian Financial Services Disclaimer</h2>
                <p>The information contained in this website has been prepared by Milliman Pty Ltd ABN 51 093 828 418 AFSL 340679 (Milliman AU) for provision to Australian financial services (AFS) licensees and their representatives, and for other persons who are wholesale clients under section 761G of the Corporations Act.  </p>
                <p>To the extent that this document may contain financial product advice, it is general advice only as it does not take into account the objectives, financial situation or needs of any particular person.  Further, any such general advice does not relate to any particular financial product and is not intended to influence any person in making a decision in relation to a particular financial product. No remuneration (including a commission) or other benefit is received by Milliman AU or its associates in relation to any advice in this document apart from that which it would receive without giving such advice.  No recommendation, opinion, offer, solicitation or advertisement to buy or sell any financial products or acquire any services of the type referred to or to adopt any particular investment strategy is made in this document to any person.  </p>
                <p>The information in relation to the types of financial products or services referred to in this document reflects the opinions of Milliman AU at the time the information is prepared and may not be representative of the views of Milliman, Inc., Milliman Financial Risk Management LLC, or any other company in the Milliman group (Milliman group).  If AFS licensees or their representatives give any advice to their clients based on the information in this document they must take full responsibility for that advice having satisfied themselves as to the accuracy of the information and opinions expressed and must not expressly or impliedly attribute the advice or any part of it to Milliman AU or any other company in the Milliman group. Further, any person making an investment decision taking into account the information in this document must satisfy themselves as to the accuracy of the information and opinions expressed.  Many of the types of products and services described or referred to in this document involve significant risks and may not be suitable for all investors.  No advice in relation to products or services of the type referred to should be given or any decision made or transaction entered into based on the information in this document. Any disclosure document for particular financial products should be obtained from the provider of those products and read and all relevant risks must be fully understood and an independent determination made, after obtaining any required professional advice, that such financial products, services or transactions are appropriate having regard to the investor's objectives, financial situation or needs.</p>
                <p>All investment involves risks. Any discussion of risks contained in this document with respect to any type of product or service should not be considered to be a disclosure of all risks or a complete discussion of the risks involved. Investing in foreign securities is subject to greater risks including: currency fluctuation, economic conditions, and different governmental and accounting standards. There are also risks associated with futures contracts. Futures contract positions may not provide an effective hedge because changes in futures contract prices may not track those of the securities they are intended to hedge. Futures create leverage, which can magnify the potential for gain or loss and, therefore, amplify the effects of market, which can significantly impact performance. There are also risks associated with investing in fixed income securities, including interest rate risk, and credit risk.   </p>

                <p>Any source material included in this document has been sourced from providers that Milliman AU believe to be reliable from information available publicly or with consent of the provider of the source material. To the fullest extent permitted by law, no representation or warranty, express or implied is made by any company in the Milliman group as to the accuracy or completeness of the source material or any other information in this document.  </p>
                <p>Past performance information provided in this document is not indicative of future results and the illustrations are not intended to project or predict future investment returns.  </p>
                <p>Any index performance information is for illustrative purposes only, does not represent the performance of any actual investment or portfolio. It is not possible to invest directly in an index. </p>
                <p>Any hypothetical, backtested data illustrated herein is for illustrative purposes only, and is not representative of any investment or product. Results based on simulated or hypothetical performance results have certain inherent limitations. Unlike the results shown in an actual performance record, these results do not represent actual trading. Also, because these trades have not actually been executed, these results may have under-or over-compensated for the impact, if any, of certain market factors, such as lack of liquidity. Simulated or hypothetical trading programs in general are also subject to the fact that they are designed with the benefit of hindsight. No representation is being made that any account will or is likely to achieve profits or losses similar to these being shown.  For any hypothetical simulations illustrated, Milliman AU does not manage, control or influence the investment decisions in the underlying portfolio. The underlying portfolio in hypothetical simulations use historically reported returns of widely known indices. In certain cases where live index history is unavailable, the index methodology provided by the index may be used to extend return history. To the extent the index providers have included fees and expenses in their returns, this information will be reflected in the hypothetical performance.</p>

        </div>
    );
}


