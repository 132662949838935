import * as React from 'react';
import './component.css';
import '../fontello.css';

interface IProps{
    highlight?: boolean;
    smartShieldChoice: any;
    setSmartShieldChoice: any;
    smartShieldChoiceValue: number;
    setSmartShieldChoiceValue: React.Dispatch<React.SetStateAction<number|null>>;
    SS?: boolean;
    feeValue: number;
    id: string;
    optionsMap : INameMap;
}

interface INameMap {
    [key: string]: string;
}

export default function NeuFeeStackDropdown(props:IProps) {

    const { id, highlight,smartShieldChoice, setSmartShieldChoice, smartShieldChoiceValue, setSmartShieldChoiceValue, SS, feeValue, optionsMap } = props

    const uniqueId = id + "NFSD";

    const options = Object.keys(optionsMap);

    const reverseMap = optionsMap
        ? Object.keys(optionsMap).reduce((acc, key) => {
            const copy = Object.assign({},acc);
            copy[optionsMap[key]] = key;
            return copy;
        }, {} as any)
        : null;

    const cssHighlight = highlight ? "highlight" : "";
    const cssSS = SS ? "fee-ss" : "fee-nonSS";

    return (
        <div className="field noFade">
            <div>
                <div className="feesDropdown dropLabel">SmartShield</div>
                <select id={uniqueId} className={"feesDropdown dropBorder " + cssHighlight} value={optionsMap[smartShieldChoice]} onChange={() => {
                        const selected = reverseMap ? reverseMap[getValue(uniqueId)] : getValue(uniqueId);
                        setSmartShieldChoice(selected);
                    }}>
                    {createOptions(options, optionsMap)}
                </select>
            </div>
            <div className="percentageUnit">
                <input className="asset" type="number" value={smartShieldChoiceValue} onChange={(e) => {
                    setSmartShieldChoiceValue(parseInt(e.currentTarget.value,10) || null);
                }}/>
            </div>
            <div className="percentageUnit"><input className={"fee " + cssSS} type="number" value={feeValue.toFixed(2)} disabled/></div>
        </div>
    );
}

function getValue(id:string, reverseMap ?: any) {
    const select = document.getElementById(id) as HTMLSelectElement; 
    const unmappedValue = reverseMap ? reverseMap[select.value] : select.value; 
    return unmappedValue;
}

function createOptions(options:string[], optionsMap ?: INameMap) {
    return options.map((option: string) => {
        return <option key={option}>{optionsMap ? (optionsMap[option] || "???") : option}</option>
    });
}
