import * as React from 'react';
import {useEffect, useState } from 'react';
import { SVG } from '@svgdotjs/svg.js';
import '../NeuComponents.css';
import './component.css';
import NeuBacktestTag  from "../NeuBacktestTag/NeuBacktestTag";
import NeuBacktestAllTag from '../NeuBacktestAllTag/NeuBacktestAllTag';
import NeuBacktestDropdown from '../NeuBacktestDropdown/NeuBacktestDropdown';
import { IDateDaily, IDateMappings,IDateRange, IDateMonthly, RangeTypes } from '../../Types/DataInterfaces';

import getDateByDivision from '../../utility/getDateByDivision' 
import digitToMonthName from '../../utility/digitToMonthName';

const $ = require("jquery");

interface datastore {
    name: string;
    data: number[][];
}

interface IProps {
    investmentSmartShield: datastore;
    investmentEquivalent: datastore;
    investmentBenchmark: datastore;
    dateOptionsList: string[];
    onDropdownChange: Function;
    currentSelection: string;
}


const roundingValue = 50; //helps set a senisbile y axis range e.g. is initialMaxY = 672 and roundingValue = 50 then finalMaxY = 700;
export default function NeuBacktest(props: IProps) {

    const [lastLines, setLastLines] = useState([] as any[]);
    const [pastValuesTracker, setPastValuesTracker] = useState("");
    //Handle we want to trigger a rerender on window resize
    useEffect(() => {

        function handleWindowResize() {
            setTimeout(() => {
                const lastLinesWidthAppended = "RESIZED!";
                setPastValuesTracker(lastLinesWidthAppended);
            }, 10); //we need this delay for some reason
        }

        window.addEventListener("resize", handleWindowResize);

        //useEffect can return a function to be run on cleanup
        return () => { window.removeEventListener("resize", handleWindowResize); }

    });

    const { investmentSmartShield, investmentEquivalent, investmentBenchmark, dateOptionsList, onDropdownChange } = props;
    const currentSelection = props.currentSelection || "LONG15YEARHISTORY";

    const investmentSmartShieldData = investmentSmartShield.data; 
    const investmentEquivalentData = investmentEquivalent.data; 
    const investmentBenchmarkData = investmentBenchmark.data;

    const lastSSValue = Math.floor(investmentSmartShieldData[investmentSmartShieldData.length - 1][1]);
    const lastIEValue = Math.floor(investmentEquivalentData[investmentEquivalentData.length - 1][1]);
    const lastIBValue = Math.floor(investmentBenchmarkData[investmentBenchmarkData.length - 1][1]);

    const firstSSValue = Math.floor(investmentSmartShieldData[0][1]);
    const firstIEValue = Math.floor(investmentEquivalentData[0][1]);
    const firstIBValue = Math.floor(investmentBenchmarkData[0][1]);

    const percentDiffSS = (((lastSSValue-firstSSValue) / firstSSValue) * 100);
    const percentDiffIE = (((lastIEValue-firstIEValue) / firstIEValue) * 100);
    const percentDiffIB = (((lastIBValue-firstIBValue) / firstIBValue) * 100);

    const pdSS = (percentDiffSS >= 0 ? "+" + percentDiffSS.toFixed(1) : percentDiffSS.toFixed(1))+"%"; 
    const pdIE = (percentDiffIE >= 0 ? "+" + percentDiffIE.toFixed(1) : percentDiffIE.toFixed(1))+"%"; 
    const pdIB = (percentDiffIB >= 0 ? "+" + percentDiffIB.toFixed(1) : percentDiffIB.toFixed(1))+"%"; 

    const investmentSmartShieldName = investmentSmartShield.name; 
    const investmentEquivalentName = investmentEquivalent.name; 
    const investmentBenchmarkName = investmentBenchmark.name;
        

    const dateMappings = {
        LONG15YEARHISTORY: { start: { year: 2004, month: 12 } as IDateMonthly, end: { year: 2019, month: 12 } as IDateMonthly, type:RangeTypes.monthly },
        GLOBALFINANCIALCRISIS: { start: { year: 2007, month: 10 } as IDateMonthly, end: { year: 2009, month: 2 } as IDateMonthly , type:RangeTypes.monthly},
        BULLMARKET: { start: { year: 2009, month: 12 } as IDateMonthly, end: { year: 2019, month: 12 } as IDateMonthly , type:RangeTypes.monthly},
        BEARANDREBOUND: { start: { year: 2007, month: 10 } as IDateMonthly, end: { year: 2012, month: 12 } as IDateMonthly , type:RangeTypes.monthly},
        COVID19: { start: { year:2020,month: 1, day:31 } as IDateDaily, end: {year:2020,month:3, day:31 } as IDateDaily, type:RangeTypes.daily},
    } as IDateMappings;

    
    const dateRange = dateMappings[currentSelection];
    setTimeout(() => {
        const investments = {} as any;
        investments.data = [investmentSmartShieldData, investmentEquivalentData, investmentBenchmarkData];
        //Little code monstrosity to get a string representation of all our graph data
        const newValues = investments.data.reduce((currentTotalString:string, currentArray:any[]) => {
            return currentTotalString + currentArray.reduce((currentArrayString:string, point:number[]) => {
                return currentArrayString + point.reduce((pointString: string, coordinate: number) => {
                    return pointString + coordinate.toString();
                },"")
            }, "")
        }, "")
        //End monstrosity

        if (newValues === pastValuesTracker) {
            return;
        }
        if (lastLines) {
            (lastLines || [] ).forEach((element: any) => {
                element!.remove();
            });
        }

        const backtest = SVG().addTo('#backtest');
        backtest.data('parentID', '#backtest');
        const investmentSmartShield = SVG().addTo('#investmentSmartShieldInner');
        investmentSmartShield.data('parentID', '#investmentSmartShieldInner');
        const investmentEquivalent= SVG().addTo('#investmentEquivalentInner');
        investmentEquivalent.data('parentID', '#investmentEquivalentInner');
        const investmentBenchmark= SVG().addTo('#investmentBenchmarkInner');
        investmentBenchmark.data('parentID', '#investmentBenchmarkInner');

        investments.type = ['investmentSmartShield', 'investmentEquivalent', 'investmentBenchmark'];
        investments.element = { investmentSmartShield, investmentEquivalent, investmentBenchmark};

        xAxisLabels(backtest, dateRange);
        sortData(backtest, investments);

        setPastValuesTracker(newValues);
        setLastLines([backtest, investmentSmartShield, investmentEquivalent, investmentBenchmark]);

    }, 0);

    //The starting balance for all values should be the same

    const nameMappings = {
        LONG15YEARHISTORY : "Long Term",
        GLOBALFINANCIALCRISIS : "Global Financial Crisis",
        BULLMARKET : "Bull Market",
        BEARANDREBOUND : "Bear and Rebound",
        COVID19: "Covid-19"
    };


    return (
        <div className="FillDiv">
            <header>
                <div className="icon icon-investment"></div>
                <h1>Simulated Historical Performance</h1>
            </header>
            <NeuBacktestDropdown title="Date Range:" options={dateOptionsList} dateMappings={dateMappings} nameMappings={nameMappings} currentSelection={currentSelection} onChange={onDropdownChange} />
            <div id="lineChartLegendHolder">
                <NeuBacktestAllTag startingBalance={firstSSValue} />
                <NeuBacktestTag startValue={firstSSValue} endValue={lastSSValue} name={`SmartShield ${investmentSmartShieldName}`} outerId={"investmentSmartShieldLegend"} innerId={"investmentSmartShieldInner"} cssClass="investmentSmartShieldFill" />
                <NeuBacktestTag startValue={firstIEValue} endValue={lastIEValue} name={`Reference ${investmentEquivalentName}`} outerId={"investmentEquivalentLegend"} innerId={"investmentEquivalentInner"} cssClass="investmentEquivalentFill"/>
                <NeuBacktestTag startValue={firstIBValue} endValue={lastIBValue} name={`Client ${investmentBenchmarkName}`} outerId={"investmentBenchmarkLegend"} innerId={"investmentBenchmarkInner"} cssClass="investmentBenchmarkFill"/>
            </div>
            <div id="backtest" className="fullWidthChart">

            </div>
        </div>
    );
}


function buildChart(parent:any, data:any, boundaryMetrics:any, plotClass:any) {
    const maxWidth = $(parent.data('parentID')).width() || 0;
    const maxHeight = $(parent.data('parentID')).height() || 0;
    parent.size(maxWidth, maxHeight);
    const pointArray = new Array();
    let xPosPrev = null;
    let yPosPrev = null;
    let xPos = 0;
    let yPos = 0;
    const incr = maxWidth / boundaryMetrics.xRange;
    const scaleY = maxHeight / boundaryMetrics.yRange;
    for (let i = 0; i < data.length; i++) {
        yPos = (boundaryMetrics.hiValueY - data[i][1]) * scaleY;
        const dataPoint = parent.circle(10, 10).attr({ fill: '#000000', cx: xPos, cy: yPos, 'fill-opacity': 0 });
        pointArray.push(xPos);
        pointArray.push(yPos);
        xPosPrev = xPos;
        yPosPrev = yPos;
        xPos = xPos + incr;
    }
    const linePlot = parent.polyline().plot(pointArray).addClass('lineChartStyle ' + plotClass);
    pointArray.push(xPos);
    pointArray.push(maxHeight);
    pointArray.push(0);
    pointArray.push(maxHeight);
    const areaBelow = parent.polyline().plot(pointArray).addClass('areaChart ' + plotClass);
}

function xAxisLabels(parent: any, range: IDateRange<any>){
    const maxHeight = $(parent.data('parentID')).height();
    const maxWidth = $(parent.data('parentID')).width();
    if (range.type === RangeTypes.monthly) {
        const startOffsetUnits = 12 - range.start.month;
        const endOffsetUnits = range.end.month;
        const yearsToDisplay = range.end.year - range.start.year - 1; //exclude the start year from years to label.  We still display it in the line graph.
        const yearsUnits = yearsToDisplay * 12;
        const totalUnits = startOffsetUnits + endOffsetUnits + yearsUnits;

        const unitLength = maxWidth / totalUnits;

        const globalXOffset = startOffsetUnits * unitLength;

        const divisionBoundaries = range.end.year - range.start.year;

        const unitsPerDivision = 12;
        const gradient = parent.gradient('linear', function (add: any) {
            add.stop(0, '#49505e', 0)
            add.stop(0.5, '#49505e', 0.25)
            add.stop(1, '#49505e', 0.25)
        }).from(0, 0).to(0, 1)
        for (let divisionCount = 0; divisionCount < divisionBoundaries + 1; divisionCount++) { //The +1 here is to add an extra iteration for the final year which we excluded earlier in the unitLength calculations
            const currentPosition = divisionCount * unitsPerDivision * unitLength + globalXOffset;
            const marker = parent.rect(1, maxHeight).attr({ fill: gradient, x: currentPosition });
            const labelValue = range.start.year + divisionCount;
            const xAxisLabel = parent.text(labelValue.toString()).attr({ x: (currentPosition - (unitsPerDivision * unitLength) / 2), y: (maxHeight - 40) });

            xAxisLabel.font({
                family: 'benton-sans, Arial',
                size: 12,
                anchor: 'middle',
                leading: '1.5em',
                weight: 400,
                fill: '#272a2b'
            })
        }
    } else if (range.type === RangeTypes.daily) {

        const daysArray = getDateArray(range);

        const xAxisData = [...daysArray];
        const totalUnits = xAxisData.reduce((acc,num) => {return acc+num});

        const unitLength = maxWidth / totalUnits;

        let currentPosition = 0;

        const gradient = parent.gradient('linear', function (add: any) {
            add.stop(0, '#49505e', 0)
            add.stop(0.5, '#49505e', 0.25)
            add.stop(1, '#49505e', 0.25)
        }).from(0, 0).to(0, 1)
        for (let divisionCount = 0; divisionCount < xAxisData.length; divisionCount++) {
            currentPosition = currentPosition + xAxisData[divisionCount]*unitLength;
            const marker = parent.rect(1, maxHeight).attr({ fill: gradient, x: currentPosition });
            const labelDate = getDateByDivision(divisionCount, range.start);
            const labelValue = `${digitToMonthName(labelDate.month)}\n${labelDate.year}`;
            const labelPositionCoeff = xAxisData[divisionCount] <= 10 ? 10 : xAxisData[divisionCount];
            const xAxisLabel = parent.text(labelValue.toString()).attr({ x: (currentPosition - (labelPositionCoeff * unitLength) / 2), y: (maxHeight - 40) });

            xAxisLabel.font({
                family: 'benton-sans, Arial',
                size: 12,
                anchor: 'middle',
                leading: '1.5em',
                weight: 400,
                fill: '#272a2b'
            })
        }

    }
}


function sortData(parent:any, data:any) {
    const boundaryMetrics = findBoundary(data['data']);
    for (let i = 0; i < data['data'].length; i++) {
        buildChart(parent, data['data'][i], boundaryMetrics, data['type'][i]);
        buildChart(data.element[data['type'][i]], data['data'][i], boundaryMetrics, data['type'][i]);
    }
}

function findBoundary(data:any[]) {
    const rangeBuffer = 100;
    const yValues = [];
    const xValues = [];
    for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
            yValues.push(data[i][j][1]);
            xValues.push(data[i][j][0]);
        }
    }
    const hiValueY = roundUnit(Math.max(...yValues)) + rangeBuffer;
    const lowValueY = roundUnit(Math.min(...yValues)) - rangeBuffer;
    const yRange = hiValueY - lowValueY;
    const hiValueX = Math.max(...xValues);
    const lowValueX = Math.min(...xValues);
    const xRange = hiValueX - lowValueX;

    const boundaryMetrics = {
        xRange: xRange,
        yRange: yRange,
        hiValueY: hiValueY
    };

    return (boundaryMetrics);
}

function roundUnit(raw:number) {
    if (raw >= 0) {
        return Math.ceil(raw / roundingValue) * roundingValue;
    }
    else {
        return Math.floor(raw / roundingValue) * roundingValue;
    }
}


function getDateArray(range: IDateRange<IDateDaily>):number[]{

    const currentDate = new Date(`${digitToMonthName(range.start.month, true)} ${range.start.day}, ${range.start.year}`);
    const endDate = new Date(`${digitToMonthName(range.end.month, true)} ${range.end.day}, ${range.end.year}`);

    function isWeekday(date: Date) {
        return date.getDay() != 0 && date.getDay() != 6;
    }

    const weekdaysInMonth = [];
    let currentWeekdayCount = 0;

    while (currentDate.getDate() != endDate.getDate() || currentDate.getMonth() != endDate.getMonth() || currentDate.getFullYear() != endDate.getFullYear()){

        isWeekday(currentDate) && currentWeekdayCount++;
        const monthCopy = currentDate.getMonth();
        currentDate.setDate(currentDate.getDate() + 1);

        if (currentDate.getMonth() != monthCopy) {
            weekdaysInMonth.push(currentWeekdayCount);
            currentWeekdayCount = 0;
        }

    }

    weekdaysInMonth.push(currentWeekdayCount);

    return weekdaysInMonth;
}


