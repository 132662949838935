import * as React from 'react';
import '../NeuComponents.css';
import './component.css';

interface IProps {
    text: string;
    onClick ?: Function;
    enabled: boolean;
    inverted?: boolean;
}

export default function NeuButton(props:IProps) {

    const {text, onClick, enabled, inverted} = props;

    const cssDisabled = enabled ? "" : "disabled";
    const cssInverted = inverted ? "darkModeButton" : "";

    return (
        <div className={`field easeBackground easeColor easeInOut`}>
            <input className={`${cssInverted} ${cssDisabled}`} type="submit" value={text} onClick={() => { onClick && onClick() }}/>
        </div>
    );
}

