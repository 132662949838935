import SSFees from './SSFeeTable';
import NormalFees from './NormalFeeTable';

import { Portfolios } from '../Types/DataInterfaces';

import { ProtoXml } from './ProtoXml';

const { ProjectionClient } = require('../JSProtoBuffer/GeneratedCode/ProjectionService_grpc_web_pb');
const { AssetAllocation,  RiskProfile, ShockCase, BackTestScenarioCases} = require('../JSProtoBuffer/GeneratedCode/Types_pb');

function projectionSend(allValues,sendLocation,postprocess) {

    const request = composeRequest(allValues);

    const client = new ProjectionClient(sendLocation);


    const enginePromise = new Promise((resolve, reject) => {
        const response = client.runProjection(request, {}, (err, response) => {
            if (err) {
                console.log(err.code);
                console.log(err.message);
                reject(err.message);
            } else {
                const engineResponse = response.getScenariodataList();
                resolve(engineResponse);
            }
        });
    }).catch((err) => console.log(err));

    const pregenPromise = new Promise((resolve, reject) => {
        const response = client.getPreGeneratedValues(request, {}, (err, response) => {
            if (err) {
                console.log(err.code);
                console.log(err.message);
                reject(err.message);
            } else {
                const preGenValues = response;
                resolve(preGenValues);
            }
        });
    })

    return Promise.all([enginePromise, pregenPromise]).then((values) => {
        const [engineResponse, pregenResponse] = values;
        const newState = postprocess(engineResponse, pregenResponse, allValues.standardInputValues);
        newState.currentAge = allValues.standardInputValues.currentAge;
        return newState;
    })
}

function backtestSend(allValues,sendLocation,postprocess, isDebugging){
    //compose request
    const request = composeRequest(allValues, isDebugging);

    const client = new ProjectionClient(sendLocation);
    const backtestPromise = new Promise((resolve, reject) => {
        const response = client.runBackTest(request, {}, (err, response) => {
            if (err) {
                console.log(err.code);
                console.log(err.message);
                reject(err.message);
            } else {
                const backtestValues = response.getScenariodataList();
                resolve(backtestValues);
            }
        });
    })

    return backtestPromise.then((value) => {
               const newState = postprocess(value, allValues.standardInputValues);
               if (newState) {
                   newState.currentAge = allValues.standardInputValues.currentAge;
               }
               return newState;
           });
           
}

function composeRequest(allValues, isDebugging){

    const {smartShieldChoice, smartShieldChoiceValue, standardInputValues, slideOutValues, customAssetAllocationValues, userData} = allValues;

    const customNonSSSum = Object.values(customAssetAllocationValues).reduce((acc, num) => { return acc + num })/100;

    const customNonSSSumFee = slideOutValues.additionalComparisonFees*100; //convert to bps
    const weightedNonSSFee = (customNonSSSum * customNonSSSumFee);

    const customNonSSSumAlpha = slideOutValues.additionalComparisonAlpha*100; //convert to bps
    const weightedNonSSSumAlpha = (customNonSSSum * customNonSSSumAlpha);

    const customSSSum = smartShieldChoiceValue/100; 
    const customSSSumFee = SSFees[smartShieldChoice]*100;
    const weightedSSSumFee = customSSSum * customSSSumFee;
    const customSSSumAlpha = 0; //As per the documentation

    const finalAdditionalComparisonFee = standardInputValues.additionalComparison.toUpperCase() === "USERDEFINEDALLOCATION"
        ? weightedNonSSFee + weightedSSSumFee
        : customNonSSSumFee;
    const finalAdditionalComparisonAlpha = standardInputValues.additionalComparison.toUpperCase() === "USERDEFINEDALLOCATION"
        ? weightedNonSSSumAlpha + customSSSumAlpha
        : customNonSSSumAlpha;

    const smartShieldCustomValues = {};
    smartShieldCustomValues[Portfolios.balanced] = 0;
    smartShieldCustomValues[Portfolios.growth] = 0;
    smartShieldCustomValues[Portfolios.highgrowth] = 0;
    smartShieldCustomValues[Portfolios.moderate] = 0;
    //now set the value//
    smartShieldCustomValues[smartShieldChoice] = smartShieldChoiceValue;

    //const additionalComparisonFees = standardInputValues.additionalComparison === "USERDEFINEDALLOCATION." ? 

    const protoXml = new ProtoXml();
    //craft request
    //const userDefinedInvestment = new EngineRequest.ProjectionData.UserDefinedInvestment();
    protoXml.setAustralianequityweight(customAssetAllocationValues.australianEquityWeight);
    protoXml.setIntlequityhedgedweight(customAssetAllocationValues.intlEquityHedgedWeight);
    protoXml.setIntlequityunhedgedweight(customAssetAllocationValues.intlEquityUnhedgedWeight);
    protoXml.setAugovfixedincomeweight(customAssetAllocationValues.auGovFixedIncomeWeight);
    protoXml.setAucorpfixedincomeweight(customAssetAllocationValues.auCorpFixedIncomeWeight);
    protoXml.setIntlgovfixedincomeweight(customAssetAllocationValues.intlGovFixedIncomeWeight);
    protoXml.setAulistedpropertyweight(customAssetAllocationValues.auListedPropertyWeight);
    protoXml.setAudirectpropertyweight(customAssetAllocationValues.auDirectPropertyWeight);
    protoXml.setAustraliancashweight(customAssetAllocationValues.australianCashWeight);
    protoXml.setSmartshieldhighgrowthweight(smartShieldCustomValues[Portfolios.highgrowth]);
    protoXml.setSmartshieldgrowthweight(smartShieldCustomValues[Portfolios.growth]);
    protoXml.setSmartshieldmoderateweight(smartShieldCustomValues[Portfolios.moderate]);
    protoXml.setSmartshieldbalancedweight(smartShieldCustomValues[Portfolios.balanced]);

    //const advisorTool = new EngineRequest.ProjectionData.AdvisorToolType();
    protoXml.setEffectivedate("2019/06/30");
    protoXml.setCurrentage(standardInputValues.currentAge);
    protoXml.setTargetage(standardInputValues.targetAge);
    protoXml.setCurrentbalance(standardInputValues.currentBalance);
    protoXml.setRequiredincome(standardInputValues.annualWithdrawal);
    protoXml.setPercentagefeesuserdefined(finalAdditionalComparisonFee);

    protoXml.setPercentagefeesriskprofilebase(NormalFees[standardInputValues.investmentEquivalent]*100); //Gets value in bps
    protoXml.setPercentagefeesriskprofileek(SSFees[standardInputValues.investmentEquivalent] * 100); //Get value in bps
    protoXml.setAlphariskprofilebase(0); //As per the documentation
    protoXml.setAlphauserdefined(finalAdditionalComparisonAlpha);
    protoXml.setAssetallocation(AssetAllocation[standardInputValues.additionalComparison]);
    protoXml.setRiskprofile(RiskProfile[standardInputValues.investmentEquivalent]);
    protoXml.setShockevents(ShockCase.GFC);
    protoXml.setInflationadjusted(true);
    //protoXml.setUserdefinedassetallocation(userDefinedInvestment);
    protoXml.setReturnmode(true);
    protoXml.setBacktestscenario(BackTestScenarioCases[standardInputValues.backTestScenario] || BackTestScenarioCases.LONG15YEARHISTORY);
    protoXml.setAdviserid(userData.id);
    protoXml.setBusinessid("");
    protoXml.setEmailaddress("");
    /*Name is AdviserID
    BusinessName is BusinessID
    EmailAddress is EmailAddress*/

    //if debug is set
    isDebugging && protoXml.printSim();

    const request = protoXml.getRequest();
    return request;
}

export { projectionSend, backtestSend }
