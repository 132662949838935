import * as React from 'react';
import './component.css';

export default function HelperInfo() {
    return (
        <div className="helperInfo">
            <header>
                <h1>SmartShield - User Guide & Documentation</h1>
            </header>
            <div className="content">
    
                <h2>SmartShield</h2>
                <h3>1. What is it?</h3>
                    <p>SmartShield is an easy to use, interactive tool that allows advisers to have better conversations with their clients.</p>
                    <p>It allows an adviser to easily demonstrate the key benefits of SmartShield Series portfolios, and the impact that these can have on customer outcomes.</p>
                <h3>2. How it Works</h3>
                    <p>The tool is powered by Milliman’s GBA Platform to simulate thousands of potential economic scenarios, and to construct back tests that show the theoretical historical performance of each selected portfolio.</p>
                    <p>This analysis is then summarised, using a series of simple measures to facilitate discussion with clients; where the potential benefits, features and customer impacts of using SmartShield Series in your client’s portfolio are explained. </p>
                <h2>3. How to Use SmartShield </h2>
                <p>SmartShield has two key components:</p>
                <ul>
                    <li>The input panel (left hand side) allows navigation between output views, and data entry to allow the analysis to be tailored to specific client needs</li>
                    <li>The output panel (right hand side) displays the results of the simulationanalysis in each of the output views</li>
                </ul>
                <h2>3.1. Input Panel</h2>
                    <p>The input panel allows Navigation between the two output views in the tool:</p>
                <h2>Simulated Historical Performance</h2>
                    <p>This view shows the hypothetical performance of an individual’s investment portfolio over various past performance periods  (“back tests”).</p>
                    <p>The analysis is tailored to an individual’s balance, selected annual withdrawals, and chosen investment portfolios.	</p>
                    <p>Investment portfolios can be tailored to illustrate specific asset allocations, fees and active management performance – both with and without use of SmartShield Series portfolios.</p>
                <h2>Retirement Outcomes</h2>
                    <p>This view shows a series of metrics that show the impact each selected portfolio has on the ability of an individual to meet a series of retirement goals. </p>
                    <p>The analysis is based on a series of forward-looking simulations of possible future outcomes.</p>
                <h2>Current Age</h2>
                    <p>The current age of the client at the start of the simulation.  This can be selected to be any age between 55 and 90 years old.</p>
                <h2>Target Age</h2>
                    <p>The age of the client at the end of the projection period. This can be selected to be any age between ages 65 and 110, and must be larger than Current Age.  It must also be between 10 and 40 years after Current Age.</p>
                <h2>Current Balance</h2>
                    <p>The amount of any investment assets to be included in projections.</p>
                <h2>Annual Income</h2>
                    <p>The amount of income to be drawn from the product each year.  This amount is assumed to be deducted in each time period within the back tests and simulations, and is assumed to vary each year in line with changes in Consumer Price Inflation (CPI).</p>
                <h2>Investment Portfolios</h2>
                    <p>Three alternative investment portfolios can be selected and compared:</p>
                <h3>1. SmartShield Portfolio</h3>
                    <p>This portfolio assumes 100% investment in one of the available SmartShield Series portfolios:  High Growth, Growth, Balanced or Moderate.  Fees for these options will be set equal to the actual fees (model manager and investment) payable on the relevant portfolio.  No alpha is assumed to apply to this portfolio.</p>
                <h3>2. Reference Portfolio</h3>
                    <p>This portfolio assumes 100% investment in a portfolio constructed in line with the Morningstar Australia Multisector Category Average benchmarks: Aggressive, Growth, Balanced or Moderate.  Returns used in Back tests for these portfolios are net of fees and any alpha.  For Retirement Outcomes, fees are based on assumptions representative of the Morningstar benchmarks, and zero alpha. For the Covid-19 scenario, the Morningstar Australia MultiSector Indices have been used instead.</p>
                <h3>3. Client Portfolio</h3>
                    <p>A user-defined portfolio can be constructed by providingasset allocation weights to a number of standard asset classes, or to an investment in one of the SmartShield Series portfolios.  Fees and alpha can be entered for the non-SmartShield Series portfolio elements.  For SmartShield Series portfolios, actual fees and no alpha will be assumed.</p>

                <p>Where any of these assumptions are changed, analysis can be re-run by pressing the <i><b>Calculate</b></i> button.</p>

                <h2>3.2. Output Panel</h2>
                    <p>The output panel shows the results of analysis carried out based on the client details entered in the input panel.</p>
                <h2>3.2.1. Simulated Historical Performance</h2>
                    <p>This view shows the hypothetical performance of an individual’s investment portfolio over various past performance periods, tailored to their balance, selected annual withdrawals, and chosen investment portfolios.  Back tests are shown at monthly intervals.</p>
                    <p>Investment portfolios can be customised to illustrate user-defined asset allocations, fees and active management outperformance (alpha) – both with and without use of SmartShield Series portfolios.</p>
                    <p>Four back test scenarios are currently available for selection, which cover the following time periods:</p>

                    <table className="fancy">
                        <caption>Back Test Date Ranges</caption>
                        <tr>
                            <th  className="colHeading">Back test Scenario</th>
                            <th  className="colHeading">Start Period</th>
                            <th  className="colHeading">End Period</th>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Long Term</th>
                            <td>31 December 2004</td>
                            <td>31 December 2009</td>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Global Financial Crisis</th>
                            <td>31 October 2007</td>
                            <td>28 February 2009</td>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Bull Market</th>
                            <td>31 December 2009</td>
                            <td>31 December 2019</td>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Bear and Rebound</th>
                            <td>21 October 2007</td>
                            <td>31 December 2012</td>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Covid-19</th>
                            <td>31 January 2020</td>
                            <td>31 March 2020</td>
                        </tr>
                    </table>


                    <p>In addition, for each investment option, the change in portfolio capital balance is shown after withdrawals, calculated as the change in account balance, from Start Period to End Period.  </p>

                <h2>3.2.2.Retirement Income</h2>
                    <p>This view shows a series of metrics that illustrate the impact each selected portfolio has on the ability of an individual to meet a series of retirement goals, based on a forward-looking simulation of possible future outcomes.</p>
                    <p>The outcome metrics show the following indicators for each of the selected investment options, calculated by simulating 5000 future economic scenarios:</p>

                    <h4><b>Success:</b> shows the probability that a client can achieve their annual income goal every year up to the specified Target Age</h4>
                    <h4><b>Deficit:</b> shows the potential size (in years) of any shortfall in a client’s annual withdrawal goal relative to the specified Target Age.  This is calculated as the average shortfall in the worst 10% of simulated scenarios.</h4>
                    <h4><b>Sustainable:</b> shows the amount of income which can be sustainably withdrawn from a portfolio each year.  This is calculated as the amount of withdrawal that results in at least a 90% Likelihood of meeting the sustainable income amount, with a potential Shortfall no greater than 5 years.  Note that this metric is not shown for user-defined portfolios.</h4>
                    <h4><b>Estate:</b> shows the average amount of capital remaining in the client’s portfolio at Target Age, after taking income withdrawals in line with their annual income goal.  This amount is shown in today’s dollar terms, discounted by future Consumer Price Inflation rates.</h4>

                    <p>In addition to the base analysis, a further option exists in the Retirement Outcomes view to calculate the above metrics immediately after a predefined “What If” stress event.  This event is assumed to reflect a fall in asset class values consistent with falls seen during the Global Financial Crisis.  For each portfolio, the impact of the fall on each invested asset class and SmartShield Series portfolio has been modelled, with Retirement Outcome metrics updated to reflect the client’s position immediately after the What If event occurs.</p>

                <h2>4. Assumptions annd Methodology</h2>
                    <h4><b><i>Simulated Historical Performance</i></b> has been calculated from historical returns, using daily return data sourced from Bloomberg and Morningstar Direct.  SmartShield Series and User Defined portfolios are assumed to be rebalanced monthly.</h4>

                    <p>Benchmark portfolios use the following indices for historic returns:</p>

                    <table className="fancy">
                        <caption>Benchmark Historic Return Indices</caption>
                        <tr>
                            <th  className="colHeading">Benchmark Portfolio</th>
                            <th  className="colHeading">Indices Used</th>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Aggressive</th>
                            <td>Morningstar Australia MultiSector Category Average Aggressive</td>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Growth</th>
                            <td>Morningstar Australia MultiSector Category Average Growth</td>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Balanced</th>
                            <td>Morningstar Australia MultiSector Category Average Balanced</td>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Moderate</th>
                            <td>Morningstar Australia MultiSector Category Average Moderate</td>
                        </tr>
                    </table>


                    <table className="fancy">
                        <caption>Benchmark Historic Return Indices - for the Covid-19 scenario</caption>
                        <tr>
                            <th  className="colHeading">Benchmark Portfolio</th>
                            <th  className="colHeading">Indices Used</th>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Aggressive</th>
                            <td>Morningstar Australia MultiSector Indices Aggressive</td>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Growth</th>
                            <td>Morningstar Australia MultiSector Indices Growth</td>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Balanced</th>
                            <td>Morningstar Australia MultiSector Indices Balanced</td>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Moderate</th>
                            <td>Morningstar Australia MultiSector Indices Moderate</td>
                        </tr>
                    </table>

                    <p>SmartShield portfolios use the following indices for historical returns:</p>
                
                    <table className="fancy">
                    <caption>SmartShield Historic Return Indices</caption>
                        <tr>
                            <th  className="colHeading">Asset Class</th>
                            <th className="colHeading">Indices Used</th>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Australian Equity</th>
                            <td className="rowHeading">S&P/ASX 200 Total Return Index</td>
                        </tr>
                        <tr>
                            <th  className="rowHeading">International Equity</th>
                            <td>MSCI World ex Australia Net Total Return AUD Index</td>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Australian Fixed Income</th>
                            <td>Solactive Australian Bank Senior Floating Rate Bond Index Solactive Australian Investment Grade Corporate Bond Select Total Return Index </td>
                        </tr>
                        <tr>
                            <th  className="rowHeading">International Fixed Income</th>
                            <td>Bloomberg Barclays Global Aggregate - Corporates Total Return Index Value Hedged AUD</td>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Property</th>
                            <td>S&P/ASX 200 A-REIT Total Return Index</td>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Cash</th>
                            <td>Bloomberg AusBond Bank Bill Index</td>
                        </tr>
                    </table>

                    <p>User Defined asset allocations use the following indices for historic returns</p>

                    
                    <table className="fancy">
                        <caption>User Defined Historic Indices</caption>
                        <tr>
                            <th  className="colHeading">Asset Class</th>
                            <th className="colHeading">Indices Used</th>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Australian Equity</th>
                            <td className="rowHeading">S&P/ASX 200 Total Return Index</td>
                        </tr>
                        <tr>
                            <th  className="rowHeading">International Equity Hedged</th>
                            <td>MSCI World ex Australia Hedged AUD Net Total Return Index</td>
                        </tr>
                        <tr>
                            <th  className="rowHeading">International Equity Unhedged</th>
                            <td>MSCI World ex Australia Net Total Return AUD Index</td>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Australian Govt Fixed Income</th>
                            <td>Bloomberg AusBond Govt 0+ Yr Index</td>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Australian Corp Fixed Income</th>
                            <td>Bloomberg AusBond Credit 0+ Yr Index</td>
                        </tr>
                        <tr>
                            <th  className="rowHeading">International Govt Fixed Income Hedged</th>
                            <td>Bloomberg Barclays Global Aggregate - Government Total Return Index Hedged AUD</td>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Australian Listed Property</th>
                            <td>S&P/ASX 200 A-REIT Total Return Index</td>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Australian Direct Property</th>
                            <td>S&P/ASX 200 A-REIT Total Return Index</td>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Cash</th>
                            <td>Bloomberg AusBond Bank Bill Index</td>
                        </tr>
                    </table>

                    <p>Client portfolios use annual fees and alpha (estimated annual active fund manager outperformance) input by the user.  The following SmartShield Series and Reference portfolio fees and alpha have been assumed in all projections:</p>

                    <table className="fancy">
                        <caption>SmartShield Fees and Alpha</caption>
                        <tr>
                            <th  className="colHeading">SmartShield Series Portfolio</th>
                            <th  className="colHeading">Annual Fee</th>
                            <th  className="colHeading">Alpha</th>
                        </tr>
                        <tr>
                            <th  className="rowHeading">High Growth</th>
                            <td>0.43%</td>
                            <td>0%</td>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Growth</th>
                            <td>0.44%</td>
                            <td>0%</td>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Balanced</th>
                            <td>0.47%</td>
                            <td>0%</td>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Moderate</th>
                            <td>0.49%</td>
                            <td>0%</td>
                        </tr>
                    </table>

                    <table className="fancy">
                        <caption>Reference Fees and Alpha</caption>
                        <tr>
                            <th  className="colHeading">Reference Portfolio</th>
                            <th  className="colHeading">Annual Fee</th>
                            <th  className="colHeading">Alpha</th>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Aggressive</th>
                            <td>0.90%</td>
                            <td>0%</td>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Growth</th>
                            <td>0.90%</td>
                            <td>0%</td>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Balanced</th>
                            <td>0.90%</td>
                            <td>0%</td>
                        </tr>
                        <tr>
                            <th  className="rowHeading">Moderate</th>
                            <td>0.90%</td>
                            <td>0%</td>
                        </tr>
                    </table>

                    <h4><b><i>Retirement Income</i></b> outcomes are calculated by simulating client cashflows across 5000 randomly generated scenarios, modelled using the Milliman GBA Platform.  outcomes are calculated by simulating client cashflows across 5000 randomly generated scenarios, modelled using the Milliman GBA Platform.</h4>
                    <p>All projections assume income is withdrawn from each portfolio at the end of each year, with income earned reinvested within each portfolio during the year.  Annual withdrawals are assumed to change each year in line with changes in Consumer Price Inflation.  For Benchmark and User Defined portfolios, holdings to each asset class or SmartShield Series portfolio are assumed to be rebalanced back to input weights every year.</p>
                    <p>SmartShield Series portfolios are modelled assuming weekly rebalancing of the portfolios in line with the SmartShield risk management strategies employed.  </p>
                    <p>Fees / alpha are assumed to be deducted from / added to portfolio returns each year prior to drawing of that year’s withdrawal amount.</p>
                    <p>Economic parameters use Milliman’s standard Australian economic scenario generator calibration as at 28st March 2024.  This calibration models a variety of economic variables, including interest rates, asset class returns and inflation indices, where the value of all variables are modelled assuming uncertainty about their future values.  Sample statistics for the modelled economic variables, benchmark and SmartShield Series portfolios are as follows.  All show key distribution statistics for returns / rates for annual returns / rates over a 40-year time horizon.  Returns are all stated before fees.</p>
                                            
                <table className="fancy">
                    <caption>Returns</caption>
                        <tr>
                            <th className="colHeading"></th>
                            <th className="colHeading"></th>
                            <th className="colHeading" colSpan={5}>| Annual Rate / Total Return</th>
                        </tr>
                        <tr>
                            <th className="colHeading">Variable / Portfolio Type</th>
                            <th className="colHeading">Name</th>
                            <th className="colHeading">5th percentile</th>
                            <th className="colHeading">25th percentile</th>
                            <th className="colHeading">50th percentile</th>
                            <th className="colHeading">75th percentile</th>
                            <th className="colHeading">95th percentile</th>
                        </tr>
                        <tr>
                            <td className="colHeading" rowSpan={10}>Economic Variables</td>
                            <td>Consumer Price Inflation</td>
                            <td>-0.5%</td>
                            <td>1.4%</td>
                            <td>2.6%</td>
                            <td>3.9%</td>
                            <td>5.8%</td>
                        </tr>
                        <tr>
                            <td>Australian Shares</td>
                            <td>-15.7%</td>
                            <td>-0.6%</td>
                            <td>9.8%</td>
                            <td>21.0%</td>
                            <td>39.4%</td>
                        </tr>
                        <tr>
                            <td>International Shares Hedged</td>
                            <td>-18.0%</td>
                            <td>-1.2%</td>
                            <td>9.7%</td>
                            <td>21.3%</td>
                            <td>41.8%</td>
                        </tr>
                        <tr>
                            <td>International Shares Unhedged</td>
                            <td>-16.7%</td>
                            <td>-0.9%</td>
                            <td>9.8%</td>
                            <td>21.4%</td>
                            <td>41.6%</td>
                        </tr>
                        <tr>
                            <td>Australian Fixed Income Corporate</td>
                            <td>-0.8%</td>
                            <td>3.7%</td>
                            <td>6.6%</td>
                            <td>9.6%</td>
                            <td>14.3%</td>
                        </tr>
                        <tr>
                            <td>Australian Fixed Income Government</td>
                            <td>-2.3%</td>
                            <td>1.7%</td>
                            <td>4.5%</td>
                            <td>7.3%</td>
                            <td>11.6%</td>
                        </tr>
                        <tr>
                            <td>International Fixed Income</td>
                            <td>-1.9%</td>
                            <td>2.2%</td>
                            <td>5.0%</td>
                            <td>7.9%</td>
                            <td>12.2%</td>
                        </tr>
                        <tr>
                            <td>Property Listed</td>
                            <td>-12.6%</td>
                            <td>1.5%</td>
                            <td>9.0%</td>
                            <td>16.8%</td>
                            <td>29.9%</td>
                        </tr>
                        <tr>
                            <td>Property Direct</td>
                            <td>-3.2%</td>
                            <td>5.3%</td>
                            <td>9.3%</td>
                            <td>13.2%</td>
                            <td>19.5%</td>
                        </tr>
                        <tr>
                            <td>Cash</td>
                            <td>1.7%</td>
                            <td>3.3%</td>
                            <td>4.4%</td>
                            <td>5.7%</td>
                            <td>7.6%</td>
                        </tr>
                        <tr>
                            <td rowSpan={4}>SmartShield Series Portfolios</td>
                            <td>High Growth</td>
                            <td>-7.8%</td>
                            <td>0.8%</td>
                            <td>8.1%</td>
                            <td>16.8%</td>
                            <td>31.5%</td>
                        </tr>
                        <tr>
                            <td>Growth</td>
                            <td>-6.0%</td>
                            <td>1.2%</td>
                            <td>7.5%</td>
                            <td>15.2%</td>
                            <td>28.3%</td>
                        </tr>
                        <tr>
                            <td>Balanced</td>
                            <td>-3.7%</td>
                            <td>2.0%</td>
                            <td>6.9%</td>
                            <td>12.8%</td>
                            <td>22.8%</td>
                        </tr>
                        <tr>
                            <td>Moderate</td>
                            <td>-1.4%</td>
                            <td>2.8%</td>
                            <td>6.2%</td>
                            <td>10.3%</td>
                            <td>17.2%</td>
                        </tr>
                        <tr>
                            <td rowSpan={4}>Benchmark Portfolios</td>
                            <td>Aggressive</td>
                            <td>-10.6%</td>
                            <td>0.9%</td>
                            <td>9.3%</td>
                            <td>18.2%</td>
                            <td>33.0%</td>
                        </tr>
                        <tr>
                            <td>Growth</td>
                            <td>-7.2%</td>
                            <td>1.8%</td>
                            <td>8.2%</td>
                            <td>15.2%</td>
                            <td>26.6%</td>
                        </tr>
                        <tr>
                            <td>Balanced</td>
                            <td>-4.1%</td>
                            <td>2.5%</td>
                            <td>7.2%</td>
                            <td>12.3%</td>
                            <td>20.7%</td>
                        </tr>
                        <tr>
                            <td>Moderate</td>
                            <td>-1.2%</td>
                            <td>3.1%</td>
                            <td>6.2%</td>
                            <td>9.4%</td>
                            <td>14.7%</td>
                        </tr>
                    </table> 


                    <p>Where a What-If event is selected, the following changes in each asset class and portfolio are assumed to apply due to the event:</p>

                    <table className="fancy">
                        <caption>GFC Alterations</caption>
                        <tr>
                            <th className="colHeading">Variable / Portfolio Type</th>
                            <th className="colHeading">Name</th>
                            <th className="colHeading">Asset Class / Portfolio Change</th>
                        </tr>
                        <tr>
                            <td>Economic Variables</td> 
                            <td className="rowHeading">Australian Shares</td>
                            <td className="rowHeading">-47.18%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="rowHeading">International Shares</td>
                            <td>-32.85%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="rowHeading">Australian Fixed Income</td>
                            <td>15.46%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="rowHeading">International Fixed Income</td>
                            <td>10.60%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="rowHeading">Property</td>
                            <td>-69.60%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="rowHeading">Cash</td>
                            <td>9.60%</td>
                        </tr>
                        <tr>
                            <td className="colHeading">SmartShield Series Portfolios</td>
                            <td className="rowHeading">High Growth</td>
                            <td>-22.64%</td>
                        </tr>
                        <tr>
                            <td className="rowHeading"></td>
                            <td>Growth</td>
                            <td>-12.18%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="rowHeading">Balanced</td>
                            <td>-6.74%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="rowHeading">Moderate</td>
                            <td>-1.00%</td>
                        </tr>
                        <tr>
                            <td className="colHeading">Benchmark Portfolios</td>
                            <td className="rowHeading">Aggressive</td>
                            <td>-40.84%</td>
                        </tr>
                        <tr>
                            <td className="rowHeading"></td>
                            <td>Growth</td>
                            <td>-32.49%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="rowHeading">Balanced</td>
                            <td>-24.45%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="rowHeading">Moderate</td>
                            <td>-13.99%</td>
                        </tr>
                    </table>

                    <p>All projections are gross of tax, while back tests are gross of tax for domestic investments, and net of withholding tax for foreign income, consistent with the Morningstar benchmarks used.  No allowance in these projections is made for any features of the Superannuation system (e.g. minimum income withdrawals, transfer balance caps, etc).  No other sources of income (e.g. Age Pension) are modelled outside of portfolio withdrawals.All projections are gross of tax, while backtests are gross of tax for domestic investments, and net of withholding tax for foreign income, consistent with the Morningstar benchmarks used.  No allowance in these projections is made for any features of the Superannuation system (e.g. minimum income withdrawals, transfer balance caps, etc).  No other sources of income (e.g. Age Pension) are modelled outside of portfolio withdrawals.</p>
                                        
                <h2>5. Support</h2>
                    <p>If you have any questions or enquiries, please contact us by email at SmartShield@Milliman.com.	</p>
                    <p>Please provide your contact details and we will aim to get back to you within 24 hours.</p>
                <h2>6. Disclaimers</h2>
                    <p>General Disclaimer</p>
                    <p>Milliman Pty Ltd ABN 51 093 828 418 AFSL 340679 is licensed to deal and advise to wholesale clients only as defined by Section 761G of the Corporations Act 2001.</p>
                    <p>The information contained in this website is for use by persons and institutions who are "wholesale clients” only. To the extent that this document may contain financial product advice, it is general advice only as it does not take into account the objectives, financial situation or needs of any particular person. Further, any such general advice does not relate to any particular financial product and is not intended to influence any person in making a decision in relation to a particular financial product. Read the full disclaimer.</p>
                    <p>Australian Financial Services Disclaimer</p>
                    <p>The information contained in this website has been prepared by Milliman Pty Ltd ABN 51 093 828 418 AFSL 340679 (Milliman AU) for provision to Australian financial services (AFS) licensees and their representatives, and for other persons who are wholesale clients under section 761G of the Corporations Act.  </p>
                    <p>To the extent that this document may contain financial product advice, it is general advice only as it does not take into account the objectives, financial situation or needs of any particular person. Further, any such general advice does not relate to any particular financial product and is not intended to influence any person in making a decision in relation to a particular financial product. No remuneration (including a commission) or other benefit is received by Milliman AU or its associates in relation to any advice in this document apart from that which it would receive without giving such advice.  No recommendation, opinion, offer, solicitation or advertisement to buy or sell any financial products or acquire any services of the type referred to or to adopt any particular investment strategy is made in this document to any person.  </p>
                    <p>The information in relation to the types of financial products or services referred to in this document reflects the opinions of Milliman AU at the time the information is prepared and may not be representative of the views of Milliman, Inc., Milliman Financial Risk Management LLC, or any other company in the Milliman group (<b>Milliman group</b>).  If AFS licensees or their representatives give any advice to their clients based on the information in this document they must take full responsibility for that advice having satisfied themselves as to the accuracy of the information and opinions expressed and must not expressly or impliedly attribute the advice or any part of it to Milliman AU or any other company in the Milliman group. Further, any person making an investment decision taking into account the information in this document must satisfy themselves as to the accuracy of the information and opinions expressed.  Many of the types of products and services described or referred to in this document involve significant risks and may not be suitable for all investors.  No advice in relation to products or services of the type referred to should be given or any decision made or transaction entered into based on the information in this document. Any disclosure document for particular financial products should be obtained from the provider of those products and read and all relevant risks must be fully understood and an independent determination made, after obtaining any required professional advice, that such financial products, services or transactions are appropriate having regard to the investor's objectives, financial situation or needs.</p>
                    <p>All investment involves risks. Any discussion of risks contained in this document with respect to any type of product or service should not be considered to be a disclosure of all risks or a complete discussion of the risks involved. Investing in foreign securities is subject to greater risks including: currency fluctuation, economic conditions, and different governmental and accounting standards. There are also risks associated with futures contracts. Futures contract positions may not provide an effective hedge because changes in futures contract prices may not track those of the securities they are intended to hedge. Futures create leverage, which can magnify the potential for gain or loss and, therefore, amplify the effects of market, which can significantly impact performance. There are also risks associated with investing in fixed income securities, including interest rate risk, and credit risk.   </p>

                    <p>Any source material included in this document has been sourced from providers that Milliman AU believe to be reliable from information available publicly or with consent of the provider of the source material. To the fullest extent permitted by law, no representation or warranty, express or implied is made by any company in the Milliman group as to the accuracy or completeness of the source material or any other information in this document.  </p>
                    <p>Past performance information provided in this document is not indicative of future results and the illustrations are not intended to project or predict future investment returns.  </p>
                    <p>Any index performance information is for illustrative purposes only, does not represent the performance of any actual investment or portfolio. It is not possible to invest directly in an index. </p>
                    <p>Any hypothetical, back tested data illustrated herein is for illustrative purposes only, and is not representative of any investment or product. Results based on simulated or hypothetical performance results have certain inherent limitations. Unlike the results shown in an actual performance record, these results do not represent actual trading. Also, because these trades have not actually been executed, these results may have under-or over-compensated for the impact, if any, of certain market factors, such as lack of liquidity. Simulated or hypothetical trading programs in general are also subject to the fact that they are designed with the benefit of hindsight. No representation is being made that any account will or is likely to achieve profits or losses similar to these being shown.  For any hypothetical simulations illustrated, Milliman AU does not manage, control or influence the investment decisions in the underlying portfolio. The underlying portfolio in hypothetical simulations use historically reported returns of widely known indices. In certain cases where live index history is unavailable, the index methodology provided by the index may be used to extend return history. To the extent the index providers have included fees and expenses in their returns, this information will be reflected in the hypothetical performance.</p>

            </div>
        </div>
    )

}

