function bequest(value:number,bequestValues:number[]) {
    const maxScale = Math.max(...bequestValues);
    const minScale = 0.8*Math.min(...bequestValues);

    return (value - minScale)/(maxScale - minScale)
}

function likelihood(value:number) {
    return value / 100;
}
function shortfall(value:number, shortfallValues:number[], targetAge:number, currentAge:number) {

    const maxScale = targetAge;
    const minScale = Math.max(currentAge, targetAge - 2 * Math.max(...shortfallValues) - 2 * Math.min(...shortfallValues));
    return (1 - (value/(maxScale-minScale))) || 1; //If the user is has too much money and always has 0 shortfal, we divide be zero in the first case
}

function withdrawal(value:number,withdrawalValues:number[] ) {
    const safeValue = value || 0;
    const values = withdrawalValues.map(value => value || 0);
    const maxScale = Math.max(...values);
    const minScale = 0.8*Math.min(...values);

    return (safeValue - minScale)/(maxScale - minScale)
}

export { bequest, likelihood, shortfall, withdrawal};

