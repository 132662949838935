import * as React from 'react';

import './component.css';


interface IProps {
    isOpen: boolean;	
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;	
    title ?: string;	
    subtitle?: string;	
    content: React.ReactElement | null;	
    cleanUp?: Function;	
    zIndex ?: number;
}

export default function Modal(props:IProps) {

    const { isOpen, setIsOpen,title,subtitle,content,cleanUp,zIndex} = props;

    const cssIsOpen = isOpen ? "open" : "closed";
    const $ = require('jquery');

    isOpen && $("#main-container").addClass('modalBlur');

    const closeModal = () => {
        cleanUp && cleanUp();
        setIsOpen(false);
        $("#main-container").removeClass('modalBlur');
    }

     return (

        <div style={{ zIndex: zIndex }} className={cssIsOpen}>

            <div className="backing" onClick={closeModal}/>
            <div style={{ zIndex: 100 }} className="modal">
                <div className="modalTop">
                    <div className="titles">
                        <div className="bigTitle">{title}</div>
                        <div className="subTitle">{subtitle}</div>
                    </div>
                    <div className="modalCloseButton icon-cross" onClick={closeModal}/>
                </div>
                {content && React.cloneElement(content, {closeModal})} 
            </div>
        </div>
    );
}