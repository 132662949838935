import * as React from "react"
import './component.css'

interface IProps {
    url: string;
}

export default function BackButton(props: IProps) {

    const { url } = props;

    return (
            <div className="backButton">
                <a href={url}>{"< Back"}</a>
            </div>
    )

}
