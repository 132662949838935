import * as React from 'react';
import { useState } from 'react';
import './component.css';
import { gfc, IProjectionTree } from '../../Types/DataInterfaces';
import NeuCircularDisplay from '../NeuCircularDisplay/NeuCircularDisplay';
import NeuBarDisplay from '../NeuBarDisplay/NeuBarDisplay';
import NeuSwitch from '../NeuSwitch/NeuSwitch';

import { calculateMatrixGraphValues } from "../../utility/CalculateMatrixGraphValues";
interface IProps {
    outputs: IProjectionTree;
    currentAge: number;
    loading: boolean;
}

export default function Matrix(props: IProps) {

    const [usingWhatIf, setUsingWhatIf] = useState(false);

    let matrixOutput = null;

    const bequestSubtext = "Average bequest at target age";
    const withdrawalSubtext = "How much money can you safely withdraw each year?";
    const shortfallSubtext = "Average shortfall in the worst of 1 in 10 scenarios";
    const likelihoodSubtext = "Chances of the funds lasting until target age";
    const { outputs, currentAge, loading } = props;

    if (outputs && Object.entries(outputs).length > 0) {
        const gfcState = usingWhatIf ? gfc.isGfc : gfc.notGfc;

        const { likelihoodRingValues,shortfallRingValues,withdrawalBarValues,bequestBarValues} = calculateMatrixGraphValues(outputs,currentAge,usingWhatIf);

        matrixOutput = (
            <div className="outputHolder">
                <header className="retirementOutcomes">
                    <div className="icon icon-dashboardmono"></div>
                    <h1>Retirement Income</h1>
                </header>
                <NeuSwitch title="GFC Simulation" subtext="What if the GFC happens again?" optionOn="On" optionOff="Off" onChange={(toggle: boolean) => { setUsingWhatIf(toggle) }} />
                <div className="matrixOutputs">
                    <NeuCircularDisplay ringA={likelihoodRingValues[0]} ringB={likelihoodRingValues[1]} ringC={likelihoodRingValues[2]} rid="likelihood" title="Success" subtext={likelihoodSubtext} postfix="%" />
                    <NeuCircularDisplay ringA={shortfallRingValues[0]} ringB={shortfallRingValues[1]} ringC={shortfallRingValues[2]} rid="shortfall" title="Deficit" subtext={shortfallSubtext} postfix="yrs" />
                    <NeuBarDisplay barA={withdrawalBarValues[0]} barB={withdrawalBarValues[1]} barC={withdrawalBarValues[2]} rid="withdrawal" title="Sustainable" subtext={withdrawalSubtext} />
                    <NeuBarDisplay barA={bequestBarValues[0]} barB={bequestBarValues[1]} barC={bequestBarValues[2]} rid="bequest" title="Estate" subtext={bequestSubtext} />
                </div>
            </div>
        );

    } else {
        matrixOutput = (
            <div className="outputHolder">
                <header className="retirementOutcomes">
                    <div className="icon icon-dashboardmono"></div>
                    <h1>Retirement Income</h1>
                </header>
                <NeuSwitch title="GFC Simulation" subtext="What if the GFC happens again?" optionOn="On" optionOff="Off" onChange={(toggle: boolean) => { setUsingWhatIf(toggle) }} disabled />
                <div className="matrixOutputs">
                    <NeuCircularDisplay ringA={{} as any} ringB={{} as any} ringC={{} as any} rid="likelihood" title="Success" subtext={likelihoodSubtext} postfix="%" disabled />
                    <NeuCircularDisplay ringA={{} as any} ringB={{} as any} ringC={{} as any} rid="shortfall" title="Deficit" subtext={shortfallSubtext} postfix="yrs" disabled />
                    <NeuBarDisplay barA={{} as any} barB={{} as any} barC={{} as any} rid="withdrawal" title="Sustainable" subtext={withdrawalSubtext} postfix="K" disabled />
                    <NeuBarDisplay barA={{} as any} barB={{} as any} barC={{} as any} rid="bequest" title="Estate" subtext={bequestSubtext} postfix="K" disabled />
                </div>
            </div>
        );
    }

    const cssLoading = loading ? "disabled" : "";

    return (
        <div className={"projectionOutputs " + cssLoading}>
            {matrixOutput}
        </div>
    )
}
