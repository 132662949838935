/**
 * @fileoverview gRPC-Web generated client stub for SmartShield.Api.Service.V100
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!



const grpc = {};
grpc.web = require('grpc-web');


var ProjectionData_pb = require('./ProjectionData_pb.js')

var EngineOutputs_pb = require('./EngineOutputs_pb.js')

var PreGeneratedValueOutputs_pb = require('./PreGeneratedValueOutputs_pb.js')

var BackTestOutputs_pb = require('./BackTestOutputs_pb.js')
const proto = {};
proto.SmartShield = {};
proto.SmartShield.Api = {};
proto.SmartShield.Api.Service = {};
proto.SmartShield.Api.Service.V100 = require('./ProjectionService_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.SmartShield.Api.Service.V100.ProjectionClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.SmartShield.Api.Service.V100.ProjectionPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.SmartShield.Api.V100.ProjectionData,
 *   !proto.SmartShield.Api.Output.V100.EngineOutputs>}
 */
const methodDescriptor_Projection_RunProjection = new grpc.web.MethodDescriptor(
  '/SmartShield.Api.Service.V100.Projection/RunProjection',
  grpc.web.MethodType.UNARY,
  ProjectionData_pb.ProjectionData,
  EngineOutputs_pb.EngineOutputs,
  /** @param {!proto.SmartShield.Api.V100.ProjectionData} request */
  function(request) {
    return request.serializeBinary();
  },
  EngineOutputs_pb.EngineOutputs.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.SmartShield.Api.V100.ProjectionData,
 *   !proto.SmartShield.Api.Output.V100.EngineOutputs>}
 */
const methodInfo_Projection_RunProjection = new grpc.web.AbstractClientBase.MethodInfo(
  EngineOutputs_pb.EngineOutputs,
  /** @param {!proto.SmartShield.Api.V100.ProjectionData} request */
  function(request) {
    return request.serializeBinary();
  },
  EngineOutputs_pb.EngineOutputs.deserializeBinary
);


/**
 * @param {!proto.SmartShield.Api.V100.ProjectionData} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.SmartShield.Api.Output.V100.EngineOutputs)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.SmartShield.Api.Output.V100.EngineOutputs>|undefined}
 *     The XHR Node Readable Stream
 */
proto.SmartShield.Api.Service.V100.ProjectionClient.prototype.runProjection =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/SmartShield.Api.Service.V100.Projection/RunProjection',
      request,
      metadata || {},
      methodDescriptor_Projection_RunProjection,
      callback);
};


/**
 * @param {!proto.SmartShield.Api.V100.ProjectionData} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.SmartShield.Api.Output.V100.EngineOutputs>}
 *     A native promise that resolves to the response
 */
proto.SmartShield.Api.Service.V100.ProjectionPromiseClient.prototype.runProjection =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/SmartShield.Api.Service.V100.Projection/RunProjection',
      request,
      metadata || {},
      methodDescriptor_Projection_RunProjection);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.SmartShield.Api.V100.ProjectionData,
 *   !proto.SmartShield.Api.Output.V100.PreGeneratedValueOutputs>}
 */
const methodDescriptor_Projection_GetPreGeneratedValues = new grpc.web.MethodDescriptor(
  '/SmartShield.Api.Service.V100.Projection/GetPreGeneratedValues',
  grpc.web.MethodType.UNARY,
  ProjectionData_pb.ProjectionData,
  PreGeneratedValueOutputs_pb.PreGeneratedValueOutputs,
  /** @param {!proto.SmartShield.Api.V100.ProjectionData} request */
  function(request) {
    return request.serializeBinary();
  },
  PreGeneratedValueOutputs_pb.PreGeneratedValueOutputs.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.SmartShield.Api.V100.ProjectionData,
 *   !proto.SmartShield.Api.Output.V100.PreGeneratedValueOutputs>}
 */
const methodInfo_Projection_GetPreGeneratedValues = new grpc.web.AbstractClientBase.MethodInfo(
  PreGeneratedValueOutputs_pb.PreGeneratedValueOutputs,
  /** @param {!proto.SmartShield.Api.V100.ProjectionData} request */
  function(request) {
    return request.serializeBinary();
  },
  PreGeneratedValueOutputs_pb.PreGeneratedValueOutputs.deserializeBinary
);


/**
 * @param {!proto.SmartShield.Api.V100.ProjectionData} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.SmartShield.Api.Output.V100.PreGeneratedValueOutputs)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.SmartShield.Api.Output.V100.PreGeneratedValueOutputs>|undefined}
 *     The XHR Node Readable Stream
 */
proto.SmartShield.Api.Service.V100.ProjectionClient.prototype.getPreGeneratedValues =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/SmartShield.Api.Service.V100.Projection/GetPreGeneratedValues',
      request,
      metadata || {},
      methodDescriptor_Projection_GetPreGeneratedValues,
      callback);
};


/**
 * @param {!proto.SmartShield.Api.V100.ProjectionData} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.SmartShield.Api.Output.V100.PreGeneratedValueOutputs>}
 *     A native promise that resolves to the response
 */
proto.SmartShield.Api.Service.V100.ProjectionPromiseClient.prototype.getPreGeneratedValues =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/SmartShield.Api.Service.V100.Projection/GetPreGeneratedValues',
      request,
      metadata || {},
      methodDescriptor_Projection_GetPreGeneratedValues);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.SmartShield.Api.V100.ProjectionData,
 *   !proto.SmartShield.Api.Output.V100.BackTestOutputs>}
 */
const methodDescriptor_Projection_RunBackTest = new grpc.web.MethodDescriptor(
  '/SmartShield.Api.Service.V100.Projection/RunBackTest',
  grpc.web.MethodType.UNARY,
  ProjectionData_pb.ProjectionData,
  BackTestOutputs_pb.BackTestOutputs,
  /** @param {!proto.SmartShield.Api.V100.ProjectionData} request */
  function(request) {
    return request.serializeBinary();
  },
  BackTestOutputs_pb.BackTestOutputs.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.SmartShield.Api.V100.ProjectionData,
 *   !proto.SmartShield.Api.Output.V100.BackTestOutputs>}
 */
const methodInfo_Projection_RunBackTest = new grpc.web.AbstractClientBase.MethodInfo(
  BackTestOutputs_pb.BackTestOutputs,
  /** @param {!proto.SmartShield.Api.V100.ProjectionData} request */
  function(request) {
    return request.serializeBinary();
  },
  BackTestOutputs_pb.BackTestOutputs.deserializeBinary
);


/**
 * @param {!proto.SmartShield.Api.V100.ProjectionData} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.SmartShield.Api.Output.V100.BackTestOutputs)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.SmartShield.Api.Output.V100.BackTestOutputs>|undefined}
 *     The XHR Node Readable Stream
 */
proto.SmartShield.Api.Service.V100.ProjectionClient.prototype.runBackTest =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/SmartShield.Api.Service.V100.Projection/RunBackTest',
      request,
      metadata || {},
      methodDescriptor_Projection_RunBackTest,
      callback);
};


/**
 * @param {!proto.SmartShield.Api.V100.ProjectionData} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.SmartShield.Api.Output.V100.BackTestOutputs>}
 *     A native promise that resolves to the response
 */
proto.SmartShield.Api.Service.V100.ProjectionPromiseClient.prototype.runBackTest =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/SmartShield.Api.Service.V100.Projection/RunBackTest',
      request,
      metadata || {},
      methodDescriptor_Projection_RunBackTest);
};


module.exports = proto.SmartShield.Api.Service.V100;

