import { IDateDaily,IDateMonthly } from '../Types/DataInterfaces';

export default function getDateByDivision(divisionCount: number, startDate: IDateDaily) {

    const { day, month, year } = startDate;

    //where divisions are equal to the number of months marked out
    const yearsDefinitelyAdvanced = Math.floor(divisionCount / 12);
    const possibleLeftoverMonths = divisionCount - yearsDefinitelyAdvanced * 12;
    const yearPossiblyAdvanced = possibleLeftoverMonths + month > 12 ? 1 : 0;

    const totalYearsAdvanced = yearsDefinitelyAdvanced + yearPossiblyAdvanced;
    const trueLeftoverMonths = divisionCount - totalYearsAdvanced * 12;

    return { month: month + trueLeftoverMonths, year: year + totalYearsAdvanced } as IDateMonthly;
}
