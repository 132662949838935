// source: BackTestOutputs.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.SmartShield.Api.Output.V100.BackTestOutputs', null, global);
goog.exportSymbol('proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SmartShield.Api.Output.V100.BackTestOutputs.repeatedFields_, null);
};
goog.inherits(proto.SmartShield.Api.Output.V100.BackTestOutputs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SmartShield.Api.Output.V100.BackTestOutputs.displayName = 'proto.SmartShield.Api.Output.V100.BackTestOutputs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue.displayName = 'proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.prototype.toObject = function(opt_includeInstance) {
  return proto.SmartShield.Api.Output.V100.BackTestOutputs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SmartShield.Api.Output.V100.BackTestOutputs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, ""),
    requestid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    version: jspb.Message.getFieldWithDefault(msg, 3, ""),
    statusmessage: jspb.Message.getFieldWithDefault(msg, 4, ""),
    scenariodataList: jspb.Message.toObjectList(msg.getScenariodataList(),
    proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SmartShield.Api.Output.V100.BackTestOutputs}
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SmartShield.Api.Output.V100.BackTestOutputs;
  return proto.SmartShield.Api.Output.V100.BackTestOutputs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SmartShield.Api.Output.V100.BackTestOutputs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SmartShield.Api.Output.V100.BackTestOutputs}
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusmessage(value);
      break;
    case 5:
      var value = new proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue;
      reader.readMessage(value,proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue.deserializeBinaryFromReader);
      msg.addScenariodata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SmartShield.Api.Output.V100.BackTestOutputs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SmartShield.Api.Output.V100.BackTestOutputs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatusmessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getScenariodataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue.prototype.toObject = function(opt_includeInstance) {
  return proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    operatorid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    queryid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    goalid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    timestep: jspb.Message.getFieldWithDefault(msg, 4, 0),
    percentile: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue}
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue;
  return proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue}
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperatorid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQueryid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGoalid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimestep(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercentile(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperatorid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQueryid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGoalid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTimestep();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPercentile();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
};


/**
 * optional string OperatorId = 1;
 * @return {string}
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue.prototype.getOperatorid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue} returns this
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue.prototype.setOperatorid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string QueryId = 2;
 * @return {string}
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue.prototype.getQueryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue} returns this
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue.prototype.setQueryid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 GoalId = 3;
 * @return {number}
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue.prototype.getGoalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue} returns this
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue.prototype.setGoalid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 Timestep = 4;
 * @return {number}
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue.prototype.getTimestep = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue} returns this
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue.prototype.setTimestep = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double Percentile = 5;
 * @return {number}
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue.prototype.getPercentile = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue} returns this
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue.prototype.setPercentile = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double Value = 6;
 * @return {number}
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue} returns this
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string Status = 1;
 * @return {string}
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartShield.Api.Output.V100.BackTestOutputs} returns this
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string RequestID = 2;
 * @return {string}
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartShield.Api.Output.V100.BackTestOutputs} returns this
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Version = 3;
 * @return {string}
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartShield.Api.Output.V100.BackTestOutputs} returns this
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string StatusMessage = 4;
 * @return {string}
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.prototype.getStatusmessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartShield.Api.Output.V100.BackTestOutputs} returns this
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.prototype.setStatusmessage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated ScenarioValue ScenarioData = 5;
 * @return {!Array<!proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue>}
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.prototype.getScenariodataList = function() {
  return /** @type{!Array<!proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue, 5));
};


/**
 * @param {!Array<!proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue>} value
 * @return {!proto.SmartShield.Api.Output.V100.BackTestOutputs} returns this
*/
proto.SmartShield.Api.Output.V100.BackTestOutputs.prototype.setScenariodataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue}
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.prototype.addScenariodata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.SmartShield.Api.Output.V100.BackTestOutputs.ScenarioValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SmartShield.Api.Output.V100.BackTestOutputs} returns this
 */
proto.SmartShield.Api.Output.V100.BackTestOutputs.prototype.clearScenariodataList = function() {
  return this.setScenariodataList([]);
};


goog.object.extend(exports, proto.SmartShield.Api.Output.V100);
