
import * as React from 'react';
import '../NeuComponents.css';
import './legals.css';
import BackButton from '../BackButton/BackButton';

export default function PrivacyPolicy() {

    document.title = "Privacy Policy";

    return (
        <div className="legalDoc">
            <BackButton url="/"/>
            <h1>Privacy Policy</h1>
            <p>Milliman respects your privacy and is committed to protecting the personal information you share with us. The purpose of this privacy policy is to set out the principles governing our use of personal information. If you give us personal information, we will treat it according to this policy.</p>
            <h2>Collection of information and security</h2>
            <h2>Aggregate information</h2>
            <p>Like many companies, we monitor the use of this Web site by collecting aggregate information. No personally identifiable data are collected in this process. Typically, we collect information about the number of visitors to the Web site, to each Web page and the originating domain name of the visitor's Internet Service Provider. This information is used to improve the usability, performance and effectiveness of the Web site.</p>
            <h2>How we collect aggregate information</h2>
            <p>In some instances, Milliman may collect aggregate data through cookies. The majority of Web browsers accept cookies and similar files, but you can usually change the browser settings to prevent this. However, if you do so, some functionality of this Web site may be lost.</p>
            <h2>Personal information</h2>
            <p>If you are required to register in order to use a feature of this Web site or to complete an online application form (e.g. to obtain e-mail updates or online publications), we will collect personally identifiable information about you, such as your name, e-mail address, country of residence, and other information which you voluntarily submit.</p>
            <h2>Security</h2>
            <p>We store your personal data on a secure server that is password protected and shielded from the outside world by a firewall. We have in place security policies that are intended to ensure, as far as possible, the security and integrity of all our information, including your personal information.</p>
            <h2>Sharing your personal information</h2>
            <h2>Data processing and storage</h2>
            <p>If we obtain any personal information about you, we may share it within Milliman or any of our worldwide subsidiaries for purposes of data processing or storage.</p>
            <h2>Authorized third-party agents</h2>
            <p>We also may share your personal information with authorized third-party agents or contractors in order to provide a requested service or transaction. For example, if we need to ship something to you, we must share your name and address with a shipping company. We only provide third-party agents with the minimum amount of personal information necessary to complete the requested service or transaction.</p>
            <h2>Legal requirements and illegal activities</h2>
            <p>We may respond to subpoenas, court orders, or legal process by disclosing your personal information and other related information, if necessary. We may collect and share personal information in order to investigate or take action regarding illegal activities, suspected fraud, violations of Milliman's Terms of Use, or as otherwise required by law.</p>
            <h2>Notice and choice for onward transfer</h2>
            <p>We will ask you for your opt-in consent to share your personal information with a third party for any other reason.</p>
            <h2>Children</h2>
            <p>Our Web sites do not target and are not intended to attract children under the age of 13. We do not knowingly collect information from or about children.</p>
            <h2>Access and corrections</h2>
            <p>Under Australian privacy laws, you have a right to request access to personal information that is held about you. If access is to be provided, a small fee may be charged.</p>
            <p>You also have a right to require any inaccuracies in your information to be corrected free of charge. You can ask us to modify your details by sending an e-mail to the Chief Compliance Officer.</p>
            <h2>Opting out</h2>
            <p>We may, from time to time, contact you by e-mail with information about our products or services. If you do not wish to receive such e-mails you can easily opt out by sending us an e-mail.</p>
            <h2>Third-party links</h2>
            <p>Our Web site may provide links to other third-party Web sites that are outside our control and not covered by this policy. We encourage you to review the privacy policies posted on these (and all) sites.</p>
            <h2>Change</h2>
            <p>We may change our privacy policy from time to time. We therefore ask you to check it occasionally to ensure that you are aware of the most recent version.</p>
            <h2>How to contact us</h2>
            <p>We welcome feedback and questions on this privacy policy. If for any reason you wish to contact us, please send an e-mail.</p>
            <p>This Web site is provided by Milliman, Inc. You may contact Milliman by sending us an e-mail. References to "Milliman," "we," "us," and "our" are references to Milliman, Inc. and its affiliates.</p>
        </div>
    );
}
