import * as React from 'react'
import './component.css';

interface IProps {
    text: string;
    closeModal?: (event:React.MouseEvent<HTMLInputElement,MouseEvent>)=>void;
}

export default function ModalNamedAction(props:IProps) {

    const {text,closeModal} = props;

    const okayButton = closeModal && <input className="okayButton" type="submit" value={"OK"} onClick={closeModal} />;

    return (
        <div className="modalCenterElements okayModalContent">
            <div className="textLayer">
                {text}
            </div>
            <div className="okayLayer">
                {okayButton}
            </div>
        </div>
    )

}
