import *  as React from 'react';
import { InputShapes } from "../../Types/DataInterfaces";
import InputWrapper from '../InputWrapper/InputWrapper';

interface IProps
{
    SS ?: boolean;
    placeholder: string;
    label : string;
    currentValue: number;
    feeValue: number;
    onChange: any;
}

export default function NeuFeeStackRow(props:IProps)
{
    const { SS,label, currentValue, feeValue, onChange} = props;

    const SScss = SS ? "fee-ss" : "fee-nonSS";

    return (
        <InputWrapper label={ label } shape={ InputShapes.plain } highlight={ SS } noFade>
            <div className="percentageUnit"><input className="asset" type="number" name={label} value={currentValue} onChange={(e) => { onChange(parseInt(e.currentTarget.value, 10) || null )}} onBlur={() => { !currentValue && onChange(0) }}/></div>
            <div className="percentageUnit"><input className={"fee " + SScss} type="number" name={label} value={feeValue.toFixed(2)} disabled/></div>
        </InputWrapper>
    );
}