import * as React from 'react';
import './component.css';

interface IProps {
    startValue: number,
    endValue: number,
    name: string,
    outerId: string,
    innerId: string,
    cssClass: string
}

export default function NeuBacktestTag(props:IProps) {

    const { startValue, endValue, name, outerId, innerId, cssClass } = props;

    const percentDiff = (((endValue - startValue) / startValue) * 100);
    const pd = (percentDiff >= 0 ? "+" + percentDiff.toFixed(1) : percentDiff.toFixed(1)) + "%";

    return (
        <div>
            <div id={outerId} className={"lineChartLegend " + cssClass}>
                <div className="details">
                    <h5>{name}</h5>
                    <p>Closing Balance</p>
                    <h4>{"$" + endValue.toLocaleString()}<sup>{pd}</sup></h4>
                </div>
                <div id={innerId} className="lineChartLegendInner"></div>
            </div>    
        </div>
    );

}
