import * as React from 'react';
import './component.css';

export default function EmptyDisplay(){

    return (
        <div className="emptyDisplay">
            SmartShield Tool
        </div>
    )

}
