import * as React from 'react';
import NeuFeeStackHeader from '../NeuFeeStackHeader/NeuFeeStackHeader';
import NeuFeeStackRow from '../NeuFeeStackRow/NeuFeeStackRow';
import NeuFeeStackDropdown from '../NeuFeeStackDropdown/NeuFeeStackDropdown';
import { Portfolios } from '../../Types/DataInterfaces';

interface IProps {
    headers: string[];
    SSFee: number;
    assetFee: number;
    setters: ISetter[];
    smartShieldChoice: any;
    setSmartShieldChoice: any;
    smartShieldChoiceValue: number;
    setSmartShieldChoiceValue: React.Dispatch<React.SetStateAction<number | null>>;
    setPortfolioName:React.Dispatch<React.SetStateAction<string>>;
}

interface ISetter {
    name: string;
    value: number,
    setter: any;
}

export default function NeuFeeStack(props: IProps) {

    const { headers, SSFee, assetFee, setters, smartShieldChoice, setSmartShieldChoice, setSmartShieldChoiceValue,smartShieldChoiceValue,setPortfolioName } = props;

    const weightings = setters.map((setCell: ISetter) => { return setCell.value || 0} );

    const chosenFee = assetFee || 0;

    const [main, ...rest] = headers;
    const [ss, ...normal] = setters; //ASSUMING SS VALUE IS FIRST IN ARRAY

    const SSFeePercent = SSFee / 100;
    const weightedSSFee = smartShieldChoiceValue * SSFeePercent;

    const dropdownOptionsMap = {} as any;
    dropdownOptionsMap[Portfolios.highgrowth] = "High Growth";
    dropdownOptionsMap[Portfolios.growth] = "Growth";
    dropdownOptionsMap[Portfolios.balanced] = "Balanced";
    dropdownOptionsMap[Portfolios.moderate] = "Moderate";

    return (
        <div className="fieldsetStack">
            <NeuFeeStackHeader main={main} headers={rest} />
            <NeuFeeStackDropdown id="SmartShieldFeeStackDropdown" optionsMap={dropdownOptionsMap} smartShieldChoice={smartShieldChoice}
                setSmartShieldChoiceValue={setSmartShieldChoiceValue} smartShieldChoiceValue={smartShieldChoiceValue} setSmartShieldChoice={setSmartShieldChoice} feeValue={SSFee} highlight SS />

            {normal.map(( setCell: ISetter, index:number) => {
                const label = setCell.name;
                const value = setCell.value;
                const setter = (s:string) => {
                    setCell.setter(s)
                    setPortfolioName("");
                };
                return (<NeuFeeStackRow placeholder="0%" onChange={setter} label={label} currentValue={value} feeValue={chosenFee} key={label}/>);
            })}
            <div className="tallyContainer noFade">
                <div className="tallyTotal">Total:</div>
                <div className="percentageUnit">
                    <div className="tally" id="assetAllocationAccum">{(percentageSum(weightings, 100) + smartShieldChoiceValue).toFixed(1)}</div>
                </div>
                <div className="percentageUnit">
                    <div className="tally" id="feeAccum">{(percentageSum(weightings, chosenFee) + weightedSSFee).toFixed(2)}</div>
                </div>
            </div>
        </div>
    );
}

function percentageSum(numArray: number[], percentageModifier:number) {
    const sum = numArray.reduce((acc, numb) => { return acc + numb * (percentageModifier / 100) },0);
    return sum;
}

