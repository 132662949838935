import * as React from "react";
import { useState } from "react";

interface IProps {
    onDelete: Function;
}

export default function DeletePortfolioButton(props: IProps) {

    const [isConfirming, setConfirming] = useState(false);

    const { onDelete } = props;

    const cssConfirming = isConfirming ? "confirmingDelete" : "";

    const stopConfirming = () => {
        setConfirming(false);
    };


    const beginConfirming = (event:React.MouseEvent) => {
        setConfirming(true);
        event.stopPropagation();
    };

    const finalizeDelete = (event:React.MouseEvent) => {
        onDelete();
        event.stopPropagation();
    };

    const buttonContentCss = isConfirming ? "" : "icon-cross";

    const onClick = isConfirming ? finalizeDelete : beginConfirming;

    return (
        <div className={`deletePortfolio ${cssConfirming} ${buttonContentCss}`} onClick={onClick} onMouseLeave={stopConfirming}/>
    );

}
