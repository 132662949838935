import * as React from 'react';

interface IProps {
    startingBalance: number;
}

export default function NeuBacktestAllTag(props : IProps) {

    const { startingBalance } = props;

    return (
        <div id="startingBalance" className="lineChartLegend lineChartLegend--start allFill">
            <div className="extraMarker"></div>
            <div className="details">
                <h5>All</h5>
                <p>Starting Balance</p>
                <h4>{`$${startingBalance.toLocaleString()}`}</h4>
            </div>
        </div>
    );
}
