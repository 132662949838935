import * as React from 'react';
import '../NeuComponents.css';
import './legals.css';
import BackButton from '../BackButton/BackButton';

export default function TnC() {
    document.title = "Terms and Conditions";

    return (
        <div className="legalDoc">
            <BackButton url="/"/>
            <h1>Terms of Use</h1>
            <p>This site is provided by Milliman Pty Ltd (“Milliman”) ACN 093 828 418 for informational purposes only. By accessing the site, you are indicating your acceptance of these Terms of Use.</p>
            <h2>Restrictions on use</h2>
            <p>Milliman’s Australian Financial Services Licence AFSL 340679 authorises the company to provide financial product dealing and advice services to wholesale clients only, as defined by Section 761G of the Corporations Act 2001. </p>
            <p>The analysis provided within this site is complex, technical in nature, and is intended for use by qualified financial advice professionals acting in a professional capacity only.  If you are not a financial advice professional – and in particular if you are a retail client or investor – then use of the site is not permitted.  </p>
            <p>This site, and the analysis provided within, is intended for use by authorised financial advice firms and their representatives only for information purposes only.  You may not use this site for any other purpose, including any commercial purpose. For example, you may not pass on any general financial product advice from this site to a retail client; or you may not co-brand or frame this site without the express prior written permission of Milliman.</p>
            <h2>Proprietary and copyright protected information</h2>
            <p>All material and content accessible on the Site, including without limitation (a) text, images, photographs, graphics, stories, indices, reports, research, guidelines, information, data, articles, descriptions, studies, position papers, instructions, advice, documents, templates, and other works of authorship, (b) samples and demos, (c) technology, designs, methods, ideas, concepts, know-how, techniques, and (d) software, source code, architecture, hidden text and symbols, user interfaces, look and feel, visual interfaces, layout, design, graphics, arrangements, structure, selection, coordination, and expression (all of the foregoing is referred to collectively as the "Content") is the proprietary information of Milliman and its licensors, and Milliman and its licensors retain all right, title, and interest in and to the Content now or in the future. This Site, including all Content, is protected by trade dress, copyright, patent and trademark laws, and various other Australian and international laws.”</p>
            <p>All intellectual property rights subsisting in any material or content available on this site (the "Content") are, unless stated otherwise, owned by Milliman or the party that provided the Content.</p>
            <p>You agree to comply with all copyright laws applicable to your use of this site, and agree that the site and Content may not be copied, distributed, republished, uploaded, posted, or transmitted in any way without the prior written consent of Milliman, except that you may print out a copy of the Content solely for your personal use. In doing so, you may not remove or alter any copyright, trademark or other proprietary notice appearing on any of the Content. No intellectual property rights are transferred to you by access to this site or otherwise.</p>
            <h2>Third-party sites</h2>
            <p>This site is hyperlinked to other sites which are not maintained by, or related to, Milliman. Hyperlinks to such sites are provided as a service to you and the sites are not sponsored by or affiliated with this site or Milliman. Milliman has not reviewed the sites and is not responsible for the content of those sites. Hyperlinks are accessed at your own risk, and Milliman makes no representations or warranties about the content, completeness or accuracy of the hyperlinks or the sites hyperlinked to this site.</p>
            <h2>Rules for external links to site</h2>
            <p>Milliman welcomes links to this site. You may establish a hypertext link, provided that the link does not state or imply any sponsorship or endorsement of your products or your site by Milliman. You may not frame or otherwise incorporate into another Web site any of the content or other materials on the Milliman site. Milliman may revoke this consent at any time and for any reason. If you do link to the site, you should be aware that internal pages change frequently and without notice, and your link may be repositioned or deleted as a result.</p>
            <h2>Submissions</h2>
            <p>You hereby grant to Milliman the royalty-free, perpetual, irrevocable, worldwide, nonexclusive right and license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform, and display all content, remarks, suggestions, ideas, graphics, or other information communicated to Milliman through this site (together, the "Submission"), and to incorporate any Submission in other works in any form, media, or technology now known or later developed. Milliman will not be required to treat any Submission as confidential, and may use any Submission in its business (including without limitation, for products or advertising) without incurring any liability for royalties or any other consideration of any kind, and will not incur any liability as a result of any similarities that may appear in future Milliman operations. Milliman will treat any personal information that you submit through this site in accordance with its Privacy Policy.</p>
            <h2>Disclaimers</h2>
            <p>You understand that Milliman does not guarantee or warrant that files available for downloading from the Internet will be free of viruses, worms, Trojan horses or other code that may manifest contaminating or destructive properties. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for accuracy of data input and output, and for maintaining a means external to this site for the reconstruction of any lost data. Milliman does not assume any responsibility or risk for your use of this site.</p>
            <p>The Content should not be used to replace any written reports, statements, or notices provided by Milliman. The information in this site does not constitute accounting, consulting, investment, legal, tax or any other type of professional advice, and should be used only in conjunction with appropriate professional advisors who have full knowledge of the user's situation. Professionals and other persons should use the Content in the same manner as any other educational medium and should not rely on the Content to the exclusion of their own professional judgment. Milliman does not warrant the accuracy or completeness of the Content or the reliability of any advice, opinion, statement or other information displayed or distributed through the site.</p>
            <p>YOUR USE OF THIS SITE IS AT YOUR OWN RISK. THE CONTENT IS PROVIDED “AS IS” AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED. TO THE FULLEST EXTENT PERMITTED AT LAW, MILLIMAN DISCLAIMS ALL WARRANTIES, TERMS, CONDITIONS, AND UNDERTAKINGS PERTAINING TO THE PROVISIONS OF THIS SITE, WHETHER EXPRESS OR IMPLIED, WRITTEN OR ORAL, STATUTORY OR OTHERWISE, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT. MILLIMAN DOES NOT WARRANT THAT THE FUNCTIONS OR CONTENT CONTAINED IN THIS SITE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THIS SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. MILLIMAN DOES NOT WARRANT OR MAKE ANY REPRESENTATION REGARDING USE, OR THE RESULT OF USE, OF THE CONTENT IN TERMS OF ACCURACY, RELIABILITY, OR OTHERWISE. THE CONTENT MAY INCLUDE TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS, AND MILLIMAN MAY MAKE CHANGES OR IMPROVEMENTS AT ANY TIME. YOU, AND NOT MILLIMAN, ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION IN THE EVENT OF ANY LOSS OR DAMAGE ARISING FROM THE USE OF THIS SITE OR ITS CONTENT. MILLIMAN MAKES NO WARRANTIES THAT YOUR USE OF THE CONTENT WILL NOT INFRINGE THE RIGHTS OF OTHERS AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ERRORS OR OMISSIONS IN SUCH CONTENT.</p>
            <p>All of the information in this site, whether historical in nature or forward-looking, speaks only as of the date the information is posted on this site, and Milliman does not undertake any obligation to update such information after it is posted or to remove such information from this site if it is not, or is no longer, accurate or complete.</p>
            <h2>Limitation on liability</h2>
            <p>TO THE FULLEST EXTENT PERMITTED AT LAW, MILLIMAN, ITS SUBSIDIARIES, AFFILIATES, LICENSORS, SERVICE PROVIDERS, CONTENT PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, AND DIRECTORS WILL NOT BE LIABLE FOR ANY INCIDENTAL, DIRECT, INDIRECT, PUNITIVE, ACTUAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR OTHER DAMAGES, INCLUDING LOSS OF REVENUE OR INCOME, PAIN AND SUFFERING, EMOTIONAL DISTRESS, OR SIMILAR DAMAGES, EVEN IF MILLIMAN HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO THE FULLEST EXTENT PERMITTED AT LAW, IN NO EVENT WILL THE COLLECTIVE LIABILITY OF MILLIMAN AND ITS SUBSIDIARIES, AFFILIATES, LICENSORS, SERVICE PROVIDERS, CONTENT PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, AND DIRECTORS TO ANY PARTY (REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT, OR OTHERWISE) EXCEED THE GREATER OF $100 OR THE AMOUNT YOU HAVE PAID TO MILLIMAN FOR THE APPLICABLE CONTENT OUT OF WHICH LIABILITY AROSE. TO THE EXTENT THAT ANY LIABILITY MAY NOT BE LAWFULLY EXCLUDED, RESTRICTED, OR MODIFIED, THE COLLECTIVE LIABILITY OF MILLIMAN AND ITS SUBSIDIARIES, AFFILIATES, LICENSORS, SERVICE PROVIDERS, CONTENT PROVIDERS, EMPLOYEES, AGENTS, OFFICERS AND DIRECTORS, SHALL BE LIMITED AT THE DISCRETION OF THE RESPONSIBLE PERSON TO THE COST OF REPLACING ANY RELEVANT GOODS OR TO RESUPPLYING ANY RELEVANT SERVICES OR TO THE COST OF PROVIDING ANALOGOUS GOODS OR SERVICES.</p>
            <h2>Indemnity</h2>
            <p>You will indemnify and hold Milliman, its subsidiaries, affiliates, licensors, content providers, service providers, employees, agents, officers, directors, and contractors (the "Indemnified Parties") harmless from any breach of these Terms of Use by you, including any use of Content other than as expressly authorized in these Terms of Use. You agree that the Indemnified Parties will have no liability in connection with any such breach or unauthorized use, and you agree to indemnify any and all resulting loss, damages, judgments, awards, costs, expenses, and attorneys’ fees of the Indemnified Parties in connection therewith. You will also indemnify and hold the Indemnified Parties harmless from and against any claims brought by third parties arising out of your use of the information accessed from this site.</p>
            <h2>Trademarks</h2>
            <p>Trademarks, service marks, and logos appearing in this site are the property of Milliman or the party that provided the trademarks, service marks, and logos to Milliman. Milliman and any party that provided trademarks, service marks, and logos to Milliman retain all rights with respect to any of their respective trademarks, service marks, and logos appearing in this site.</p>
            <h2>Security</h2>
            <p>Any passwords used for this site are for individual use only. You will be responsible for the security of your password (if any). Milliman will be entitled to monitor your password and, at its discretion, require you to change it. If you use a password that Milliman considers insecure, Milliman will be entitled to require the password to be changed and/or terminate your account.</p>
            <p>You are prohibited from using any services or facilities provided in connection with this site to compromise security or tamper with system resources and/or accounts. The use or distribution of tools designed for compromising security (e.g., password-guessing programs, cracking tools or network probing tools) is strictly prohibited. If you become involved in any violation of system security, Milliman reserves the right to release your details to system administrators at other sites in order to assist them in resolving security incidents. Milliman reserves the right to investigate suspected violations of these Terms of Use.</p>
            <p>Milliman reserves the right to fully cooperate with any law enforcement authorities or court order requesting or directing Milliman to disclose the identity of anyone posting any e-mail messages, or publishing or otherwise making available any materials that are believed to violate these Terms of Use. BY ACCEPTING THESE TERMS OF USE YOU WAIVE AND HOLD HARMLESS MILLIMAN FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY MILLIMAN DURING OR AS A RESULT OF ITS INVESTIGATIONS AND/OR FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER MILLIMAN OR LAW ENFORCEMENT AUTHORITIES.</p>
            <h2>International users and choice of law</h2>
            <p>This site is controlled, operated and administered by Milliman from its offices within Australia. Milliman makes no representation that materials at this site are appropriate or available for use at other locations outside of Australia and access to them from territories where their contents are illegal is prohibited. If you access this site from a location outside of Australia, you are responsible for compliance with all local laws. These Terms of Use shall be governed by the laws of the State of New South Wales.</p>
            <h2>Access to this site</h2>
            <p>To access this site or some of the resources it has to offer, you may be asked to provide certain registration details or other information. It is a condition of your use of this site that all the information you provide on this site will be correct, current, and complete. If Milliman believes the information you provide is not correct, current, or complete, or Milliman considers that use of the site by you is in breach of these Terms of Use, Milliman has the right to refuse you access to this site or any of its resources, and to terminate or suspend your access at any time.</p>
            <h2>Changes</h2>
            <p>Milliman reserves the right, at its sole discretion, to change, modify, add or remove any portion of these Terms of Use in whole or in part, at any time. Changes will be effective when notice of such change is posted. Your continued use of the site after any changes to these Terms of Use are posted will be considered acceptance of those changes.</p>
            <p>Milliman may terminate, change, suspend or discontinue any aspect of the site, including the availability of any features of the site, at any time. Milliman may also impose limits on certain features and services or restrict your access to parts or all of the site without notice or liability.</p>
            <h2>Miscellaneous</h2>
            <p>If any part of these Terms of Use is unlawful, void or unenforceable, that part will be deemed severable and will not affect the validity and enforceability of any remaining provisions. These Terms of Use constitute the entire agreement among the parties relating to this subject matter. Notwithstanding the foregoing, any additional terms and conditions on this site will govern the items to which they pertain.</p>
            <p>Last updated: 2 April 2020</p>
            <p>Copyright© 2020 Milliman Pty Ltd.</p>
            <p>By accessing the site, you are indicating your acceptance of these Terms.</p>

        </div>
    );

}

