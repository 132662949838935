import * as React from 'react';
import InputWrapper from '../InputWrapper/InputWrapper';
import { InputShapes } from '../../Types/DataInterfaces';

interface IProps {
    label: string;
    cssClass: string;
    value: number;
    setter: React.Dispatch<React.SetStateAction<number>>;
    help ?: JSX.Element;
}

export default function NeuColoredInput(props:IProps) {

    const { label, cssClass, value, setter,help } = props;

    return (
        <InputWrapper label={label} shape={InputShapes.plain} noFade>
            {help}
            <div className="percentageUnit">
                <input onChange={(e) => { setter(to2Dp(e.currentTarget.value)) }} onBlur={() => {!value && setter(0)}} className={ cssClass } type="number" value={value} step="0.01" />
            </div>
        </InputWrapper>
    );

}

function to2Dp(num:string){
    return parseFloat(parseFloat(num).toFixed(2));
}



