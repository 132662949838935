import * as React from 'react';
import '../NeuComponents.css';
import './component.css';
//import '../fontello.css';

interface IProps {
    tabName: string;
    tabClassName: string;
    onTabChange: any;
}

export default function NeuTabSelector(props: IProps) {

    const { tabName, tabClassName, onTabChange} = props;

    return (
        <li onClick={onTabChange}>
                <div className="spacer">
                    <div className={"icon demo-icon " + tabClassName}></div>
                    <label className="easeOpacity easeInOut">{tabName}</label>
                </div>
        </li>
    );

}
