import * as React from 'react';
import '../NeuComponents.css';
import './component.css';

interface IProps {
    main: string;
    headers:string[]
}

export default function NeuFeeStackHeader(props: IProps) {

    const { main, headers } = props;

    return (
        <div className="feeStackHeader field noFade ">
            <label className="normal"><u>{main}</u></label>
            {headers.map((header:string) => {
                return <label className="normal numberFormat" key={header}><u>{header}</u></label>
            })}
        </div>
    );

}
