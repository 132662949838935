import * as React from 'react';
import '../NeuComponents.css';


interface IProps {
    title: string;
    description: string;
    helpId?: number;
    isExtended?: boolean;
}


export default function NeuHelp(props:IProps){

    const { title, description, helpId, isExtended} = props;


    //const cssExpanded = toggle ? "expanded" : "";

    const uniqueId = `HELPBUTTON_${helpId || "DEADBEEF"}`;

    function getMouseHeight(event: any) {
        const $ = require("jquery");
        $(".helpContent").removeAttr("style");
        $(".helpContent").removeClass("expanded");


        const potentialExcess = (event.clientY + 300) - window.innerHeight; 

        const adjustedTop = potentialExcess > 0 ?  event.clientY- potentialExcess : event.clientY - 10; //-10 is for aesthetics

        const elem = document.getElementById(uniqueId);
        const elemRect = elem?.getBoundingClientRect();
        if (elemRect && elemRect.bottom < 0) {
            $(`#${uniqueId}`).addClass("expanded");
            $(`#${uniqueId}`).css("top", adjustedTop);
            $(`#${uniqueId}`).css("maxHeight", 300);
        };

    }

    return (
        <div>
            <div className="helpIcon" onClick={(e) => { getMouseHeight(e);}}/>
            <div className={"helpContent "}  id={uniqueId}>
                <div className="icon icon-cross" onClick={(e) => {getMouseHeight(e) }}></div>
                <h4>{title}</h4>
                <p>{description}</p>
            </div>
        </div>
    );
}
