import * as React from 'react'
import { useState } from 'react'
import './component.css';
import { ISubmissionResponse } from '../../Types/DataInterfaces';

interface IProps {
    submitHandler(name:string, callback?:Function): ISubmissionResponse | Promise<ISubmissionResponse>;
    buttonText?: string;
    placeholderText?: string;
    inProgressText?: string;
    submitCallback?(alertElement:HTMLElement): void;
    maxLength?: number;
    disableDoubleSubmit?: boolean;
    alphaNumericOnly?: boolean;
}

export default function ModalNamedAction(props:IProps) {

    const { submitHandler, buttonText,maxLength,placeholderText,inProgressText, submitCallback, disableDoubleSubmit} = props;
    const [name, setName] = useState("");
    const [isDoubleClickDisabled, setDisabled] = useState(false);

    function handleChange(e: any) {
        const currentValue = e.currentTarget.value;
        const letters = currentValue.split('');
        const lastChar = letters[letters.length - 1] as string;

        const alphaNumericRegex = /[a-zA-Z0-9\s-_]+/; 
        const notAlphaNumeric = !alphaNumericRegex.test(lastChar); 
        notAlphaNumeric ? badCharacterTyped(letters) : okCharacterTyped(letters);
        isDoubleClickDisabled === true && setDisabled(false);
    }

    function badCharacterTyped(letters:string[]) {
        const alertRoot = document.getElementById("MODAL_ALERT_ROOT");
        if (!!alertRoot) {
            alertRoot.innerText = "Non-Alpha numeric characters are currently not allowed when naming."
        }

        const omitLastLetter = (letters: string[]) => { return letters.slice(0, letters.length - 1) };
        setName(omitLastLetter(letters).join(''));
    }


    function okCharacterTyped(letters:string[]) {
        const alertRoot = document.getElementById("MODAL_ALERT_ROOT");
        if (!!alertRoot) {
            alertRoot.innerText = "";
        }
        setName(letters.join(''))
    }

    const runSubmit = () => {
        const alertRoot = document.getElementById("MODAL_ALERT_ROOT");
        if (inProgressText && alertRoot) {
            alertRoot.innerText = inProgressText;
        }

        const defaultClearTextCallback = () => { if (alertRoot) { alertRoot.innerText = "" } };
        const callbackRunner = () => {
            if (alertRoot) {
                 submitCallback && submitCallback(alertRoot) 
            }
        } 

        const callback = submitCallback ? callbackRunner : defaultClearTextCallback;
        setTimeout(async () => {
            const submissionResponse = await submitHandler(name, callback);
            if (!submissionResponse.isSuccess && alertRoot) {
                alertRoot.innerText = submissionResponse.message || "Submission Failed.";
            }
        }, 500);

        //disable button on submit
        disableDoubleSubmit && setDisabled(true);
    }

    const cssDisabled = isDoubleClickDisabled ? "disabled" : "";

    return (
        <div className="modalFlatGrid">
            <input type="text" value={name} onChange={handleChange} placeholder={placeholderText || "Enter a name..."} maxLength={maxLength} pattern="[a-zA-Z0-9\s]"/>
            <input type="submit" className={cssDisabled} value={buttonText || "Submit"} onClick={runSubmit}/>
            <div id="MODAL_ALERT_ROOT"/>
        </div>
    )

}
