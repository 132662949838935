import * as React from 'react';
import { useState } from 'react';
import { SVG } from '@svgdotjs/svg.js';
import '../NeuComponents.css'; 

const maxTime = 3;

interface INameValueMap {
    name: string;
    display: string;
    value: string;
    offset: number;
}

interface IProps {
    barA: INameValueMap;
    barB: INameValueMap;
    barC: INameValueMap;
    title: string;
    subtext: string;
    rid: string;
    postfix?: string;
    truncateValue?: boolean;
    disabled?: boolean;
}

export default function NeuBarDisplay(props: IProps) {

    const { title, subtext, barA, barB, barC, rid, postfix, truncateValue, disabled } = props;

    const [lastBars, setLastBars] = useState([] as any[]);
    const [lastValues, setLastValues] = useState("");

    //We dont show this last bar if the user defined portfoilio is selected
    let clientBar : any = <div className="bar barNotFirst" id={rid + "bar3"}></div>;
    if (title.toUpperCase() === "SUSTAINABLE" && barC.name === "UserDefinedAllocation") { clientBar = null;} //in this very special case, don't show the client bar

    if (!disabled) {
        setTimeout(() => {

            const newValues = barA.value.toString() + barB.value.toString() + barC.value.toString();
            if (newValues === lastValues) {
                return;
            }

            //Clean up bars from last render so we can add them again
            if (lastBars) lastBars.forEach((element: any) => {
                element.remove();
            });

            const bar1 = SVG().addTo('#' + rid + 'bar1').size(84, 394)
            const bar2 = SVG().addTo('#' + rid + 'bar2').size(84, 394)
            const bar3 = clientBar ? SVG().addTo('#' + rid + 'bar3').size(84, 394) : null;

            const bar1Value = barA.value && barA.value !== "NaN" ? `$${barA.value}${postfix || ''}` : "Unknown";
            const bar2Value = barB.value && barB.value !== "NaN" ? `$${barB.value}${postfix || ''}` : "Unknown";
            const bar3Value = barC.value && barC.value !== "NaN" ? `$${barC.value}${postfix || ''}` : "Unknown";

            setLastValues(newValues);

            const toRemoveLater = [bar1, bar2];
            if (bar3) { toRemoveLater.push(bar3); }
            setLastBars(toRemoveLater);

            drawBar(bar1, 216, barA.offset, 'investmentss', 'bar', bar1Value, barA.display, "SmartShield");
            drawBar(bar2, 216, barB.offset, 'investmentpair', 'bar', bar2Value, barB.display, "Reference");
            if (clientBar) { drawBar(bar3, 216, barC.offset, 'investmentthird', 'bar', bar3Value, barC.display, "Client") };

        }, 0);
    } else {

        setTimeout(() => {

            const newValues = "disabled";
            if (newValues === lastValues) {
                return;
            }

            //Clean up bars from last render so we can add them again
            if (lastBars) lastBars.forEach((element: any) => {
                element.remove();
            });

            const bar1 = SVG().addTo('#' + rid + 'bar1').size(84, 394)
            const bar2 = SVG().addTo('#' + rid + 'bar2').size(84, 394)
            const bar3 = SVG().addTo('#' + rid + 'bar3').size(84, 394)

            setLastValues(newValues);
            setLastBars([bar1, bar2, bar3]);

            drawBar(bar1, 216, 0, 'investmentss', 'bar', "0", "...", "SmartShield");
            drawBar(bar2, 216, 0, 'investmentpair', 'bar', "0", "...", "Reference");
            drawBar(bar3, 216, 0, 'investmentthird', 'bar', "0", "...", "Client");

        }, 0);
    }


    return (
        <div className="widgetContainer" id={rid}>
            <div className="header">
                <div className="icon"></div>
                <div className="details">
                    <h2>{title}</h2>
                    <p className="description">{subtext}</p>
                </div>
            </div>
            <div className="bars">
                <div className="bar" id={rid + "bar1"}></div>
                <div className="bar barNotFirst" id={rid + "bar2"}></div>
                {clientBar}
            </div>
        </div>
    );
}


function drawBar(parent: any, height: number, segment: number, investmentClass: string, styleClass: string, quantumLabel: string | null = null, investmentName: string | null = null, investmentType?: string) {
    var timeDuration = segment * maxTime;
    var dashOffset = height * (1 - segment);
    var fauxStyles = '--maxHeight:' + height + 'px; --offset:' + dashOffset + 'px; --duration:' + timeDuration + 's';
    var barTrack = parent.line(0, height, 0, 0).attr({ 'stroke-opacity': 0.2 }).addClass(styleClass).addClass(investmentClass).move(42, 14);
    var bar = parent.line(0, height, 0, 0).attr({ 'style': fauxStyles, 'stroke-dasharray': height, 'stroke-dashoffset': dashOffset }).addClass(styleClass).addClass(investmentClass).move(42, 14);

    var quantum = parent.text(quantumLabel).attr({ x: 39, y: 304 });
    if (investmentClass == "investmentss") {
        quantum.font({
            family: 'benton-sans,Arial',
            size: 15,
            anchor: 'middle',
            leading: '1.5em',
            weight: 600,
            fill: '#272a2b'
        })
    }
    else {
        {
            quantum.font({
                family: 'benton-sans,Arial',
                size: 15,
                anchor: 'middle',
                leading: '1.5em',
                weight: 400,
                fill: '#272a2b'
            })
        }
    }
    var typeLabel = parent.text(investmentType).attr({ x: 39, y: 362 });
  typeLabel.font({
    family:   'benton-sans,Arial',
    size:     11,
    anchor:   'middle',
    leading:  '1.5em',
    weight: 600,
    fill: '#272a2b'
  })
var investmentLabel = parent.text(investmentName).attr({x: 39, y: 373});
  investmentLabel.font({
    family:   'benton-sans,Arial',
    size:     11,
    anchor:   'middle',
    leading:  '1.5em',
    weight: 600,
    fill: '#272a2b'
  })

}
