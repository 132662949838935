import * as React from 'react';
import { useState } from 'react';

interface IProps {
    title:string,
    subtext:string,
    optionOn:string,
    optionOff:string
    onChange: any;
    disabled?: boolean;
}

export default function NeuSwitch(props: IProps) {

    const [toggle, setToggle] = useState(true);

    const { title, subtext, optionOn, optionOff, onChange, disabled } = props;

    async function handleClick() {
        if (!disabled) {
            await setToggle(!toggle);
            onChange(toggle);
        }
    }

    const cssDisabled = disabled ? "disabled" : "";

    return (
        <div className={"marketConditions " + cssDisabled}>
        <div className="description">
                <h4>{title}</h4>
                <p>{subtext}</p>
        </div>
        <div className="selectConditions">
                <label>{optionOff}</label>
                <input type="checkbox" className="toggle" id="gfcToggle" name="gfc" onClick={handleClick}/>
                <label htmlFor="gfc">{optionOn}</label>
        </div>
        </div>
    );

}
