import * as React from 'react';
import '../NeuComponents.css';
import '../fontello.css';
import './component.css';

import NeuTabSelector from '../NeuTabSelector/NeuTabSelector';

interface IProps {
    onTabChange: React.Dispatch<React.SetStateAction<number>>;
}

export default function NeuTabs(props: IProps) {

    const { onTabChange} = props;

    return (<div id="navigation" className="easeWidth">
        <div className="branding"></div>
            <ul className="menu">
                <NeuTabSelector tabClassName="icon-dashboardmono" tabName="Retirement Income" onTabChange={() => { onTabChange(0) }}/>
                <NeuTabSelector tabClassName="icon-investment" tabName="Back Test" onTabChange={() => { onTabChange(1) }}/>
                <NeuTabSelector tabClassName="icon-fingerprint" tabName="Account" onTabChange={() => { window.location.href = '/Identity/Account/Manage'; }} />
                <NeuTabSelector tabClassName="icon-help" tabName="Help" onTabChange={() => { onTabChange(2)}} />
            </ul>
        </div>);

}
