import * as React from 'react';
import { useContext } from 'react';
import './component.css';
import NeuBacktest from '../NeuBacktest/NeuBacktest';
import { IBackTestBranch,PortfolioContext } from '../../Types/DataInterfaces';
import { backtestSend } from '../../utility/Senders';
import { reduceBacktestData } from '../../utility/EngineResponseToReducedData';
import EmptyDisplay from '../EmptyDisplay/EmptyDisplay';

import { ConfigContext } from '../../index';

interface IProps {
    values: IBackTestBranch;
    currentRequestValues: any;
    onBacktestResponse: any;
    loading: boolean;
    setIsBacktestLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Backtest(props: IProps) {

    const config : any = useContext(ConfigContext);
    const { values,  currentRequestValues, onBacktestResponse, loading, setIsBacktestLoading } = props;

    const graphData = {} as any;

    let backtestDisplay = <EmptyDisplay/>;
    if (Object.entries(values).length > 0) {
        Object.keys(values).forEach(key => {
            const { name,data } = values[key];
            graphData[key] = { name: name, data: []}
            data.forEach(value => { graphData[key].data.push([value.time, value.value]) });
        });

        //for debugging
        if (config.isDebugging) {
            prettyPrintData(graphData[PortfolioContext.Chosen].data, graphData[PortfolioContext.Equiv].data, graphData[PortfolioContext.SS].data);
        }

        const { BackTestScenarioCases } = require('../../JSProtoBuffer/GeneratedCode/Types_pb');
        const dateOptionsList = Object.keys(BackTestScenarioCases);

        const onDropdownChange = function odc(dropdownSelection: string) {
            setIsBacktestLoading(true);
            const currentRequestCopy = Object.assign(currentRequestValues); //THIS IS A SHALLOW COPY.  THE MUTATION BELOW WILL CHANGE THE ORIGINAL DATA.  THIS BEHAVIOUR IS  NOT THE BEST
            currentRequestCopy.standardInputValues.backTestScenario = dropdownSelection;
            const backtestPromise = backtestSend(currentRequestCopy, currentRequestCopy.destination, reduceBacktestData).then((result) => { setIsBacktestLoading(false); onBacktestResponse(result) });
        }
        //todo: this janky ass bit of code below needs a refactor
        backtestDisplay = <NeuBacktest currentSelection={currentRequestValues.standardInputValues.backTestScenario} investmentBenchmark={graphData[PortfolioContext.Chosen]} investmentEquivalent={graphData[PortfolioContext.Equiv]} investmentSmartShield={graphData[PortfolioContext.SS]} dateOptionsList={dateOptionsList} onDropdownChange={onDropdownChange} />

    }

    const cssLoading = loading ? "disabled" : "";

    return (
        <div className={"backtestData "+ cssLoading}>
            {backtestDisplay}
        </div>
    )

}

function prettyPrintData(client:number[], reference:number[], SS:number[]){

    console.log("===Backtest Graph Data===")
    console.log("SmartShield Line Data:");
    SS.forEach((result) => { console.log(result)});    
    console.log("Reference Line Data:");
    reference.forEach((result) => { console.log(result)});    
    console.log("Client Line Data:");
    client.forEach((result) => { console.log(result)});    
    console.log("=========================")

}
