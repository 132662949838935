import * as React from 'react';
import './component.css';
import { useState, useEffect } from 'react';
import NeuInputs from '../NeuInputs/NeuInputs';
import Outputs from '../Outputs/Outputs';
import { IReducedMatrixValueList, IBackTestBranch, IReducedGraphValues, IProjectionTree, IUserData, IPortfolio } from '../../Types/DataInterfaces';
import { getUserData } from '../../utility/GetUserData';

import useModal from '../../Hooks/useModal';

import downloadPdf from "../../utility/DownloadPdf";
interface IBacktestOutputs {
    backtestOutput: IBackTestBranch;
    currentAge: number;
    AssetAllocationInRequest: string
}


interface IProjectionOutputs{
    matrixOutput: IProjectionTree;
    graphOutput: IReducedGraphValues;
    currentAge: number;
    AssetAllocationInRequest: string;
}
export default function Home(props: any) {
    
    const [projectionData, setProjectionData] = useState({ matrixOutput: {} as IReducedMatrixValueList, graphOutput: {} as IReducedGraphValues,  AssetAllocationInRequest:"", currentAge:0} as IProjectionOutputs);
    const [backtestData, setBacktestData] = useState({backtestOutput: {} as IBackTestBranch, AssetAllocationInRequest:"", currentAge:0} as IBacktestOutputs);
    const [currentTab, setCurrentTab] = useState(0);
    const [currentRequest, setCurrentRequest] = useState();
    const [isRetirementLoading, setIsRetirementLoading] = useState(false);
    const [isBacktestLoading, setIsBacktestLoading] = useState(false);
    const [userData, setUserData] = useState<IUserData<IPortfolio[]>|undefined>();

    const [Modal, setModalOpen, formModal] = useModal();

    //Get user data on first load
    //NIGHTMARE DEMNI CODE REMOVE PLZ
    useEffect(() => {
        getUserData().then(result => {
            setUserData(result);
        })
    }, []);

    function handleProjectionResponse(values: IProjectionOutputs) {
        const newValues = {...projectionData, ...values};
        setProjectionData(newValues);
    }

    function handleBacktestResponse(values: IBacktestOutputs) {
        const newValues = {...backtestData,...values};
        setBacktestData(newValues);
    }


    const rootElement = document.getElementById("root") as HTMLElement;
    rootElement.style.width = "100%";
    rootElement.style.height = "100%";

    document.title = "SmartShield.App";

    const isDownloadable = !!projectionData && !!currentRequest && !!userData && !isRetirementLoading && !isBacktestLoading;
    const downloadHandler = downloadPdf(projectionData.matrixOutput, currentRequest, userData);

    return (
      <div >
            {Modal}
            <div id="main-container">
                <NeuInputs onProjectionResponse={handleProjectionResponse} onBacktestResponse={handleBacktestResponse} onTabChange={setCurrentTab} onRequest={setCurrentRequest} setRetirementLoader={setIsRetirementLoading} setBacktestLoader={setIsBacktestLoading} userData={userData} setUserData={setUserData} canDownload={isDownloadable} formModal={formModal} setIsModalOpen={setModalOpen} downloadHandler={downloadHandler}/>
                <Outputs projectionData={projectionData} isRetirementLoading={isRetirementLoading} backtestData={backtestData} isBacktestLoading={isBacktestLoading} setIsBacktestLoading={setIsBacktestLoading} currentTabId={currentTab}  currentRequest={currentRequest} onBacktestResponse={handleBacktestResponse} userData={userData}/>
            </div>
      </div>
    );


}
