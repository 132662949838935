import * as React from 'react';
import { InputShapes } from "../../Types/DataInterfaces";
import { CSSProperties } from 'styled-components';

interface IProps {
    label: string;
    easeOpacity?: boolean;
    shape: InputShapes;
    children?: any;
    noFade?: boolean;
    percentageUnit?: boolean;
    highlight?: boolean;
    help?: JSX.Element;
    zIndex?: number;
}

export default function InputWrapper(props:IProps) {

    const { label, easeOpacity, shape, noFade, percentageUnit, highlight, help, zIndex} = props;

    const cssEase = easeOpacity ? "easeOpacity easeInOut " : "";
    const cssNoFade = noFade ? "noFade" : "";
    const cssPercentageUnit = percentageUnit ? "percentageUnit" : "";
    const cssHighlight = highlight ? "highlight" : "";

    const zStyle: CSSProperties = zIndex ? { "zIndex": zIndex } : {};

    return (
        <div className={`field ${cssEase} ${shape} ${cssNoFade} ${cssPercentageUnit}`} style={zStyle}>    
            <label className={cssHighlight}>{label}</label>
            {help}
            {props.children}
        </div>
    )

}

