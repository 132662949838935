import * as React from 'react';

import './component.css';

import NeuHelp from '../NeuHelp/NeuHelp';
import NeuColoredInput from '../NeuColoredInput/NeuColoredInput';

import { CSSProperties } from 'styled-components';

interface IProps {
    isUserDefined: boolean;
    additionalComparisonFees: any;
    additionalComparisonAlpha: any;
    slideOutSetters: any;
    customAssetAllocationForm: any;
    savePortfolioButton: any;
    loadPortfolioButton: any;
    portfolioName: string;
    closeSlideOut: Function;
    canSave: boolean;
    zIndex?: number;
}


export default function NeuSlideout(props: IProps) {

    const {additionalComparisonFees,additionalComparisonAlpha, slideOutSetters, isUserDefined, customAssetAllocationForm,savePortfolioButton,loadPortfolioButton,portfolioName,closeSlideOut,canSave,zIndex } = props;
    const { setAdditionalComparisonFees, setAdditionalComparisonAlpha } = slideOutSetters;

    const feeHelp = "Investment management fees are charged as a percentage of the total assets managed. These types of fees can often be at least partially paid with pre-tax or tax-deductible dollars.";
    const feeHelpElement = <NeuHelp title="Fees" description={feeHelp} helpId={101} isExtended/>;

    const zStyle: CSSProperties = zIndex ? { "zIndex": zIndex } : {};


    return (
        <div style={zStyle}>
            <div className="formColumn formColumn--hide easeOpacity easeInOut">
                <div className="closeColumn" onClick={closeSlideOut as any}></div>
                <div className="column-2">
                    <div className="portfolioName">{isUserDefined && portfolioName}</div>
                    <NeuColoredInput label="Fees" setter={setAdditionalComparisonFees} cssClass="fee fee--large" value={additionalComparisonFees} help={feeHelpElement} />
                    <NeuColoredInput label="Alpha" value={additionalComparisonAlpha} setter={setAdditionalComparisonAlpha} cssClass="alpha" />
                    {isUserDefined ? customAssetAllocationForm : ""}
                </div>
                <div className="portfolioSaves">
                    { isUserDefined && loadPortfolioButton}
                    <div className={canSave ? "" : "disabled"}>{isUserDefined && savePortfolioButton}</div>
                </div>
            </div>
        </div>
    );
}
