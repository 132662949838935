// source: ProjectionData.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var Types_pb = require('./Types_pb.js');
goog.object.extend(proto, Types_pb);
goog.exportSymbol('proto.SmartShield.Api.V100.ProjectionData', null, global);
goog.exportSymbol('proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType', null, global);
goog.exportSymbol('proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SmartShield.Api.V100.ProjectionData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SmartShield.Api.V100.ProjectionData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SmartShield.Api.V100.ProjectionData.displayName = 'proto.SmartShield.Api.V100.ProjectionData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.displayName = 'proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.displayName = 'proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SmartShield.Api.V100.ProjectionData.prototype.toObject = function(opt_includeInstance) {
  return proto.SmartShield.Api.V100.ProjectionData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SmartShield.Api.V100.ProjectionData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartShield.Api.V100.ProjectionData.toObject = function(includeInstance, msg) {
  var f, obj = {
    advisortool: (f = msg.getAdvisortool()) && proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SmartShield.Api.V100.ProjectionData}
 */
proto.SmartShield.Api.V100.ProjectionData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SmartShield.Api.V100.ProjectionData;
  return proto.SmartShield.Api.V100.ProjectionData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SmartShield.Api.V100.ProjectionData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SmartShield.Api.V100.ProjectionData}
 */
proto.SmartShield.Api.V100.ProjectionData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType;
      reader.readMessage(value,proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.deserializeBinaryFromReader);
      msg.setAdvisortool(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SmartShield.Api.V100.ProjectionData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SmartShield.Api.V100.ProjectionData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SmartShield.Api.V100.ProjectionData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartShield.Api.V100.ProjectionData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdvisortool();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.toObject = function(opt_includeInstance) {
  return proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.toObject = function(includeInstance, msg) {
  var f, obj = {
    effectivedate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    currentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    targetage: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    currentbalance: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    requiredincome: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    riskprofile: jspb.Message.getFieldWithDefault(msg, 6, 0),
    assetallocation: jspb.Message.getFieldWithDefault(msg, 7, 0),
    userdefinedassetallocation: (f = msg.getUserdefinedassetallocation()) && proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.toObject(includeInstance, f),
    inflationadjusted: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    percentagefeesuserdefined: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    percentagefeesriskprofilebase: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    percentagefeesriskprofileek: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    adviserid: jspb.Message.getFieldWithDefault(msg, 13, ""),
    businessid: jspb.Message.getFieldWithDefault(msg, 14, ""),
    emailaddress: jspb.Message.getFieldWithDefault(msg, 15, ""),
    alphariskprofilebase: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    alphauserdefined: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    shockevents: jspb.Message.getFieldWithDefault(msg, 18, 0),
    returnmode: jspb.Message.getFieldWithDefault(msg, 19, ""),
    backtestscenario: jspb.Message.getFieldWithDefault(msg, 20, 0),
    percentagefeesuserdefinedbacktest: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType}
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType;
  return proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType}
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEffectivedate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCurrentage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTargetage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCurrentbalance(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRequiredincome(value);
      break;
    case 6:
      var value = /** @type {!proto.SmartShield.Api.V100.RiskProfile} */ (reader.readEnum());
      msg.setRiskprofile(value);
      break;
    case 7:
      var value = /** @type {!proto.SmartShield.Api.V100.AssetAllocation} */ (reader.readEnum());
      msg.setAssetallocation(value);
      break;
    case 8:
      var value = new proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment;
      reader.readMessage(value,proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.deserializeBinaryFromReader);
      msg.setUserdefinedassetallocation(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInflationadjusted(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercentagefeesuserdefined(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercentagefeesriskprofilebase(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercentagefeesriskprofileek(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdviserid(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessid(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailaddress(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAlphariskprofilebase(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAlphauserdefined(value);
      break;
    case 18:
      var value = /** @type {!proto.SmartShield.Api.V100.ShockCase} */ (reader.readEnum());
      msg.setShockevents(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setReturnmode(value);
      break;
    case 20:
      var value = /** @type {!proto.SmartShield.Api.V100.BackTestScenarioCases} */ (reader.readEnum());
      msg.setBacktestscenario(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercentagefeesuserdefinedbacktest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEffectivedate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCurrentage();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTargetage();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getCurrentbalance();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getRequiredincome();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getRiskprofile();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAssetallocation();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getUserdefinedassetallocation();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.serializeBinaryToWriter
    );
  }
  f = message.getInflationadjusted();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getPercentagefeesuserdefined();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getPercentagefeesriskprofilebase();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getPercentagefeesriskprofileek();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getAdviserid();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getBusinessid();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getEmailaddress();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getAlphariskprofilebase();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getAlphauserdefined();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getShockevents();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getReturnmode();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getBacktestscenario();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
  f = message.getPercentagefeesuserdefinedbacktest();
  if (f !== 0.0) {
    writer.writeDouble(
      21,
      f
    );
  }
};


/**
 * optional string EffectiveDate = 1;
 * @return {string}
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.getEffectivedate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.setEffectivedate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double CurrentAge = 2;
 * @return {number}
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.getCurrentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.setCurrentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double TargetAge = 3;
 * @return {number}
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.getTargetage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.setTargetage = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double CurrentBalance = 4;
 * @return {number}
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.getCurrentbalance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.setCurrentbalance = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double RequiredIncome = 5;
 * @return {number}
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.getRequiredincome = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.setRequiredincome = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional RiskProfile RiskProfile = 6;
 * @return {!proto.SmartShield.Api.V100.RiskProfile}
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.getRiskprofile = function() {
  return /** @type {!proto.SmartShield.Api.V100.RiskProfile} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.SmartShield.Api.V100.RiskProfile} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.setRiskprofile = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional AssetAllocation AssetAllocation = 7;
 * @return {!proto.SmartShield.Api.V100.AssetAllocation}
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.getAssetallocation = function() {
  return /** @type {!proto.SmartShield.Api.V100.AssetAllocation} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.SmartShield.Api.V100.AssetAllocation} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.setAssetallocation = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional UserDefinedInvestment UserDefinedAssetAllocation = 8;
 * @return {?proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment}
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.getUserdefinedassetallocation = function() {
  return /** @type{?proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment} */ (
    jspb.Message.getWrapperField(this, proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment, 8));
};


/**
 * @param {?proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment|undefined} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType} returns this
*/
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.setUserdefinedassetallocation = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.clearUserdefinedassetallocation = function() {
  return this.setUserdefinedassetallocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.hasUserdefinedassetallocation = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool InflationAdjusted = 9;
 * @return {boolean}
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.getInflationadjusted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.setInflationadjusted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional double PercentageFeesUserDefined = 10;
 * @return {number}
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.getPercentagefeesuserdefined = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.setPercentagefeesuserdefined = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double PercentageFeesRiskProfileBase = 11;
 * @return {number}
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.getPercentagefeesriskprofilebase = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.setPercentagefeesriskprofilebase = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double PercentageFeesRiskProfileEK = 12;
 * @return {number}
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.getPercentagefeesriskprofileek = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.setPercentagefeesriskprofileek = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional string AdviserID = 13;
 * @return {string}
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.getAdviserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.setAdviserid = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string BusinessID = 14;
 * @return {string}
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.getBusinessid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.setBusinessid = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string EmailAddress = 15;
 * @return {string}
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.getEmailaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.setEmailaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional double AlphaRiskProfileBase = 16;
 * @return {number}
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.getAlphariskprofilebase = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.setAlphariskprofilebase = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional double AlphaUserDefined = 17;
 * @return {number}
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.getAlphauserdefined = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.setAlphauserdefined = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional ShockCase ShockEvents = 18;
 * @return {!proto.SmartShield.Api.V100.ShockCase}
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.getShockevents = function() {
  return /** @type {!proto.SmartShield.Api.V100.ShockCase} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.SmartShield.Api.V100.ShockCase} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.setShockevents = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional string ReturnMode = 19;
 * @return {string}
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.getReturnmode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.setReturnmode = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional BackTestScenarioCases BackTestScenario = 20;
 * @return {!proto.SmartShield.Api.V100.BackTestScenarioCases}
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.getBacktestscenario = function() {
  return /** @type {!proto.SmartShield.Api.V100.BackTestScenarioCases} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.SmartShield.Api.V100.BackTestScenarioCases} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.setBacktestscenario = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * optional double PercentageFeesUserDefinedBackTest = 21;
 * @return {number}
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.getPercentagefeesuserdefinedbacktest = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType.prototype.setPercentagefeesuserdefinedbacktest = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.prototype.toObject = function(opt_includeInstance) {
  return proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.toObject = function(includeInstance, msg) {
  var f, obj = {
    australianequityweight: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    intlequityhedgedweight: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    intlequityunhedgedweight: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    augovfixedincomeweight: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    aucorpfixedincomeweight: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    intlgovfixedincomeweight: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    aulistedpropertyweight: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    audirectpropertyweight: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    australiancashweight: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    smartshieldhighgrowthweight: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    smartshieldgrowthweight: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    smartshieldmoderateweight: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    smartshieldbalancedweight: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment}
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment;
  return proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment}
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAustralianequityweight(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setIntlequityhedgedweight(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setIntlequityunhedgedweight(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAugovfixedincomeweight(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAucorpfixedincomeweight(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setIntlgovfixedincomeweight(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAulistedpropertyweight(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAudirectpropertyweight(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAustraliancashweight(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSmartshieldhighgrowthweight(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSmartshieldgrowthweight(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSmartshieldmoderateweight(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSmartshieldbalancedweight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAustralianequityweight();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getIntlequityhedgedweight();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getIntlequityunhedgedweight();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getAugovfixedincomeweight();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getAucorpfixedincomeweight();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getIntlgovfixedincomeweight();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getAulistedpropertyweight();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getAudirectpropertyweight();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getAustraliancashweight();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getSmartshieldhighgrowthweight();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getSmartshieldgrowthweight();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getSmartshieldmoderateweight();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getSmartshieldbalancedweight();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
};


/**
 * optional double AustralianEquityWeight = 1;
 * @return {number}
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.prototype.getAustralianequityweight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.prototype.setAustralianequityweight = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double IntlEquityHedgedWeight = 2;
 * @return {number}
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.prototype.getIntlequityhedgedweight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.prototype.setIntlequityhedgedweight = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double IntlEquityUnhedgedWeight = 3;
 * @return {number}
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.prototype.getIntlequityunhedgedweight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.prototype.setIntlequityunhedgedweight = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double AuGovFixedIncomeWeight = 4;
 * @return {number}
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.prototype.getAugovfixedincomeweight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.prototype.setAugovfixedincomeweight = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double AuCorpFixedIncomeWeight = 5;
 * @return {number}
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.prototype.getAucorpfixedincomeweight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.prototype.setAucorpfixedincomeweight = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double IntlGovFixedIncomeWeight = 6;
 * @return {number}
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.prototype.getIntlgovfixedincomeweight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.prototype.setIntlgovfixedincomeweight = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double AuListedPropertyWeight = 7;
 * @return {number}
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.prototype.getAulistedpropertyweight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.prototype.setAulistedpropertyweight = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double AuDirectPropertyWeight = 8;
 * @return {number}
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.prototype.getAudirectpropertyweight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.prototype.setAudirectpropertyweight = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double AustralianCashWeight = 9;
 * @return {number}
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.prototype.getAustraliancashweight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.prototype.setAustraliancashweight = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double SmartShieldHighGrowthWeight = 10;
 * @return {number}
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.prototype.getSmartshieldhighgrowthweight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.prototype.setSmartshieldhighgrowthweight = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double SmartShieldGrowthWeight = 11;
 * @return {number}
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.prototype.getSmartshieldgrowthweight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.prototype.setSmartshieldgrowthweight = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double SmartShieldModerateWeight = 12;
 * @return {number}
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.prototype.getSmartshieldmoderateweight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.prototype.setSmartshieldmoderateweight = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double SmartShieldBalancedWeight = 13;
 * @return {number}
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.prototype.getSmartshieldbalancedweight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.UserDefinedInvestment.prototype.setSmartshieldbalancedweight = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional AdvisorToolType AdvisorTool = 1;
 * @return {?proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType}
 */
proto.SmartShield.Api.V100.ProjectionData.prototype.getAdvisortool = function() {
  return /** @type{?proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType} */ (
    jspb.Message.getWrapperField(this, proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType, 1));
};


/**
 * @param {?proto.SmartShield.Api.V100.ProjectionData.AdvisorToolType|undefined} value
 * @return {!proto.SmartShield.Api.V100.ProjectionData} returns this
*/
proto.SmartShield.Api.V100.ProjectionData.prototype.setAdvisortool = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SmartShield.Api.V100.ProjectionData} returns this
 */
proto.SmartShield.Api.V100.ProjectionData.prototype.clearAdvisortool = function() {
  return this.setAdvisortool(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SmartShield.Api.V100.ProjectionData.prototype.hasAdvisortool = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.SmartShield.Api.V100);
