import * as React from 'react';
import InputWrapper from '../InputWrapper/InputWrapper';
import { InputShapes } from '../../Types/DataInterfaces';


interface IProps {
    currentValue: string;
    onChange: Function;
    label: string;
    options: any[];
    helpGenerator ?: Function;
    shape ?: InputShapes;
    optionsMapper ?: Function;
}

export default function NeuSelect(props: IProps){

    const {currentValue, options, label, shape, onChange, optionsMapper, helpGenerator } = props

    const selectClass = shape ? "investment" + " " + shape : ""; 
    const id = label + "SELECT";

    const help = helpGenerator && helpGenerator(label);

    const optionsinlist = optionsMapper
        ? options.map((option: string) => { return  optionsMapper(option) })
        : options;


    const reverseMap = optionsMapper && options.reduce((map: any, option: string) => {
        const temp = Object.assign({}, map);
        temp[optionsMapper(option)] = option
        return temp;
    }, {});

    function getValue() {
        const select = document.getElementById(id) as HTMLSelectElement; 
        const unmappedValue = reverseMap ? reverseMap[select.value] : select.value; 
        return unmappedValue;
    }

    return (
        <InputWrapper label={label} easeOpacity={true} shape={shape || InputShapes.plain} help={help} >
            <select id={id} value={optionsMapper ? optionsMapper(currentValue) : currentValue} className={selectClass} onChange={() => { onChange(getValue()) }}>
                {
                    optionsinlist.map((option: string) => {
                        return (<option value={option} key={option}>{option}</option>)
                    })
                }
            </select>
         </InputWrapper>    
    );
}
