import * as React from 'react'
import { useState, useEffect } from 'react'
import './component.css';
import { IPortfolio, ISubmissionResponse } from '../../Types/DataInterfaces';
import DeletePortfolioButton from './DeletePortfolioButton';

interface IProps {
    portfolios : IPortfolio[],
    onPortfolioSelect: Function;
    closeModal?: Function;
    portfolioDeleter(portfolioName:string): Promise<ISubmissionResponse>; 
    cleanUp?: Function;
}

export default function ModalPortfolioList(props:IProps){

    const { portfolios,onPortfolioSelect,closeModal, portfolioDeleter, cleanUp} = props;
    const [visiblePortfolios, setPortfolios] = useState([] as IPortfolio[]);

    useEffect(() => {
        setAndSortPortfolios(portfolios);
    }, []);

    function setAndSortPortfolios(p:IPortfolio[]) {
        const clone = [...p];
        const sorted = clone.sort((a, b) => {
            const aComesFirst = a.portfolioName.toUpperCase() > b.portfolioName.toUpperCase();
            return aComesFirst ? 1 : -1;
        });
        setPortfolios(sorted);
    }

    const onPortfolioClick = (data:IPortfolio) => {
        onPortfolioSelect(data);
        closeModal && closeModal();
    }

    const onDelete = (portfolioName:string) => {
        return async () => {
            const deleteResult = await portfolioDeleter(portfolioName);
            if (deleteResult.isSuccess) {
                const portfolioClone = [...visiblePortfolios];
                const afterRemoving = portfolioClone.filter(portfolio => portfolio.portfolioName !== portfolioName);
                setAndSortPortfolios(afterRemoving);
            } else {
                const alertRoot = document.getElementById("MODAL_ALERT_ROOT");
                if (!!alertRoot) alertRoot.innerText = deleteResult.message;
            }
        }
    }

    const listContents =
            visiblePortfolios.map(data =>
                <div className="portfolioRow" onClick={() => { onPortfolioClick(data) }} key={data.portfolioName}>
                    {data.portfolioName}
                    <DeletePortfolioButton onDelete={onDelete(data.portfolioName)}/>
                </div>
            )

    const noContents = "You have no portfolios saved at the moment.";

    return (
        <>
            <div className="portfolioList modalCenterElements">
                {visiblePortfolios.length > 0 ? listContents : noContents}
            </div>
            <div id="MODAL_ALERT_ROOT" />
        </>
    )

}
