import * as React from 'react';
import { useState } from 'react';
import { SVG } from '@svgdotjs/svg.js';
import '../NeuComponents.css';

const maxTime = 3;

interface INameValueMap {
    name: string;
    display: string;
    value: string;
    offset: number;
}

interface IProps {
    ringA: INameValueMap;
    ringB: INameValueMap;
    ringC: INameValueMap;
    rid: string;
    title: string;
    subtext: string;
    postfix: string;
    disabled?: boolean;
}

export default function NeuCircularDisplay(props : IProps) {

    const { ringA, ringB, ringC, rid, title, subtext, postfix, disabled } = props;

    const [lastRings, setLastRings] = useState([] as any[]);
    const [lastValues, setLastValues] = useState("");

    if (!disabled) {
        setTimeout(() => {
            const newValues = ringA.value.toString() + ringB.value.toString() + ringC.value.toString();
            if (newValues === lastValues) {
                return;
            }

            if (lastRings) lastRings.forEach((element: any) => {
                element.remove();
            });

            var rings = SVG().addTo('#' + rid + 'rings').size(256, 256)
            var legendRing1 = SVG().addTo('#' + rid + 'legendRing1').size(78, 110)
            var legendRing2 = SVG().addTo('#' + rid + 'legendRing2').size(78, 110)
            var legendRing3 = SVG().addTo('#' + rid + 'legendRing3').size(78, 110)
            setLastValues(newValues);
            setLastRings([rings, legendRing1, legendRing2, legendRing3]);

            drawRing(rings, 108, ringC.offset, 128, 128, 'investmentthird', 'ring');
            drawRing(rings, 168, ringB.offset, 128, 128, 'investmentpair', 'ring');
            drawRing(rings, 228, ringA.offset, 128, 128, 'investmentss', 'ring');

            drawRing(legendRing1, 70, ringA.offset, 39, 39, 'investmentss', 'legendRing', shortenHundred(ringA.value) + postfix, ringA.display, "SmartShield");
            drawRing(legendRing2, 70, ringB.offset, 39, 39, 'investmentpair', 'legendRing', shortenHundred(ringB.value) + postfix, ringB.display, "Reference");
            drawRing(legendRing3, 70, ringC.offset, 39, 39, 'investmentthird', 'legendRing', shortenHundred(ringC.value) + postfix,ringC.display, "Client");
        }, 0);
    } else { //If the element has no values fed into it
        setTimeout(() => {
            const newValues = "disabled";
            if (newValues === lastValues) {
                return;
            }

            if (lastRings) lastRings.forEach((element: any) => {
                element.remove();
            });

            var rings = SVG().addTo('#' + rid + 'rings').size(256, 256)
            var legendRing1 = SVG().addTo('#' + rid + 'legendRing1').size(78, 110)
            var legendRing2 = SVG().addTo('#' + rid + 'legendRing2').size(78, 110)
            var legendRing3 = SVG().addTo('#' + rid + 'legendRing3').size(78, 110)
            setLastValues(newValues);
            setLastRings([rings, legendRing1, legendRing2, legendRing3]);

            drawRing(rings, 108, 0, 128, 128, 'investmentthird', 'ring');
            drawRing(rings, 168, 0, 128, 128, 'investmentpair', 'ring');
            drawRing(rings, 228,0, 128, 128, 'investmentss', 'ring');

            drawRing(legendRing1, 70, 0, 39, 39, 'investmentss', 'legendRing', "0" + postfix, "...", "SmartShield");
            drawRing(legendRing2, 70, 0, 39, 39, 'investmentpair', 'legendRing', "0" + postfix, "...", "Reference");
            drawRing(legendRing3, 70, 0, 39, 39, 'investmentthird', 'legendRing', "0" + postfix, "...", "Client");
        },0);
    }

    return (
        <div className="widgetContainer" id={rid}>
            <div className="header">
                <div className="icon"></div>
                <div className="details">
                    <h2>{title}</h2>
                    <p className="description">{subtext}</p>
                </div>
            </div>
            <div id={rid + "rings"} className="masterRing"></div>
            <div className="legend">
                <div id={rid + "legendRing1"} className="legendRing"></div>
                <div id={rid + "legendRing2"} className="legendRing legendRingNotFirst"></div>
                <div id={rid + "legendRing3"} className="legendRing legendRingNotFirst"></div>
            </div>
        </div>
    );
}

function drawRing(parent:any, diameter:number, segment:number, cx:number, cy:number, investmentClass:string, styleClass:string, quantumLabel : string|null = null, investmentName : string|null = null, investmentType ?: string) {
    var circumference = Math.PI * diameter;
    var dashOffset = circumference * (1 - segment);
    var timeDuration = segment * maxTime;
    var fauxStyles = '--circumference:' + circumference + 'px; --offset:' + dashOffset + 'px; --duration:' + timeDuration + 's';
    const baseStyles = { ...(getInvestmentClass(investmentClass)), ...(getStyleClass(styleClass)) }
    var ring = parent.circle(diameter).attr({ cx: cx, cy: cy, 'style': fauxStyles, 'stroke-dasharray': circumference, 'stroke-dashoffset': dashOffset, ...baseStyles }).addClass(styleClass).addClass(investmentClass).rotate(-90);
    var ringBackground = parent.circle(diameter).attr({ cx: cx, cy: cy, 'stroke-opacity': 0.2, ...baseStyles }).addClass(styleClass).addClass(investmentClass).backward();
    if (styleClass == 'legendRing') {
        const quantum = parent.text(quantumLabel).attr({ x: 40, y: 22 });
        if (investmentClass == "investmentss") {
            quantum.font({
                family: 'benton-sans,Arial',
                size: 15,
                anchor: 'middle',
                leading: '1.5em',
                weight: 600,
                fill: '#272a2b'
            })
        }
        else {
            {
                quantum.font({
                    family: 'benton-sans,Arial',
                    size: 15,
                    anchor: 'middle',
                    leading: '1.5em',
                    weight: 400,
                    fill: '#272a2b'
                })
            }
        }
        
        const typeLabel = parent.text(investmentType).attr({ x: 39, y: 81 });
        typeLabel.font({
            family: 'benton-sans,Arial',
            size: 11,
            anchor: 'middle',
            leading: '1.5em',
            weight: 600,
            fill: '#272a2b'
        })

        const investmentLabel = parent.text(investmentName).attr({ x: 39, y: 92 });
        investmentLabel.font({
            family: 'benton-sans,Arial',
            size: 11,
            anchor: 'middle',
            leading: '1.5em',
            weight: 600,
            fill: '#272a2b'
        })
    }
}

function shortenHundred(value:string){
    return value.length >= 5 ? value.split('.')[0] : value
}

function getInvestmentClass(investment: string) {
    switch (investment) {
        case "investmentss":
            return { "stroke": "#33ca96" };
        case "investmentpair":
            return { "stroke": "#27a6d2" };
        case "investmentthird":
            return { "stroke": "#504b98" };
    }
    return { "stroke": "#000000" };
}

function getStyleClass(style: string) {
    switch (style) {
        case "ring":
            return {
                "fill": "none",
                "stroke-linecap": "round",
                "stroke-width": 28,
            };
        case "legendRing":
            return {
                "fill": "none",
                "float": "left",
                "stroke-linecap": "round",
                "stroke-width": 8,
            };
    }
    return {};
}

