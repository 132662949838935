import * as React from 'react';
import { useState } from 'react';
import InputWrapper from '../InputWrapper/InputWrapper';
import { InputShapes } from '../../Types/DataInterfaces';

import { parseCommaified, commaify } from "../../utility/Commaify";

import './component.css';

interface IProps {
    placeholder: string;
    onChange ?: Function;
    label: string;
    helpGenerator: Function;
    currentValue: number | string;
    shape ?: InputShapes;
    readonly ?: boolean;
    noFade?: boolean;
    percentageUnit?: boolean;
    highlight?: boolean;
    numbersOnly?: boolean;
    range ?: [number, number];
    onRangeLeave?: Function;
}

export default function NeuInputBox(props:IProps){

    const { highlight, currentValue, label, placeholder, shape, onChange, readonly, noFade, percentageUnit, numbersOnly, helpGenerator, range, onRangeLeave } = props;
    const inputRange = range ? normalizeRange(range) : { start: Number.MIN_SAFE_INTEGER, end: Number.MAX_SAFE_INTEGER };

    const [onBlurValue, setOnBlurValue] = useState(inputRange.start);


    const pattern = numbersOnly ? "^-?[0-9\,]+.?[0-9]*$" : ".*";

    const helpElement = helpGenerator && helpGenerator(label);

    const changeFunction =  (value: any) => {
                                const numberValue = parseCommaified(value) || null;
                                if (onChange) { onChange(numberValue);}
                            }

    let invalidInput = false;
    if (typeof onBlurValue === "number") {
        if (onBlurValue < inputRange.start || onBlurValue > inputRange.end) {
            invalidInput = true;
        }
    }
    const cssInvalid = invalidInput ? "invalid" : ""; 

    const inputClass = shape ? "investment" + " " + shape : "";


    return (
        <InputWrapper label={label} easeOpacity={true} shape={shape || InputShapes.plain} noFade={noFade} percentageUnit={percentageUnit} highlight={highlight} help={helpElement}>
            <input className={cssInvalid + " " + inputClass} value={commaify(currentValue)} type="text" pattern={pattern} placeholder={placeholder} onBlur={(e) => { setOnBlurValue(parseCommaified(e.currentTarget.value)) }} onChange={(e) => { changeFunction(e.currentTarget.value) }} readOnly={readonly}/>
        </InputWrapper>    
    )

}

function normalizeRange(range: [number, number]) {

    if (range[0] > range[1]) {
        return { start: range[1], end: range[0] };
    }

    return { start: range[0], end: range[1] };
}

