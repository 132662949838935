// source: ProjectionService.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var ProjectionData_pb = require('./ProjectionData_pb.js');
goog.object.extend(proto, ProjectionData_pb);
var EngineOutputs_pb = require('./EngineOutputs_pb.js');
goog.object.extend(proto, EngineOutputs_pb);
var PreGeneratedValueOutputs_pb = require('./PreGeneratedValueOutputs_pb.js');
goog.object.extend(proto, PreGeneratedValueOutputs_pb);
var BackTestOutputs_pb = require('./BackTestOutputs_pb.js');
goog.object.extend(proto, BackTestOutputs_pb);
