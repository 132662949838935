import * as React from 'react';
import { useContext,useState,useCallback } from 'react';

import NeuTabs from '../NeuTabs/NeuTabs';
import NeuSelect from '../NeuSelect/NeuSelect';
import NeuInputBox from '../NeuInputBox/NeuInputBox';
import NeuExpando from '../NeuExpando/NeuExpando';
import NeuButton from '../NeuButton/NeuButton';
import NeuSlideOut from '../NeuSlideout/NeuSlideout';
import NeuHelp from '../NeuHelp/NeuHelp';
import NeuFeeStack from '../NeuFeeStack/NeuFeeStack';
import CreatePortfolioSaver from '../../utility/CreatePortfolioSaver';


import { InputShapes,Portfolios, IUserData, IPortfolio, ISubmissionResponse } from "../../Types/DataInterfaces";

import { PToE } from '../../utility/ProtobufferAndExternalNameConversion';

import "./component.css";
import "../NeuComponents.css";
import '../fontello.css';
import ClickVeil from '../ClickVeil/ClickVeil';
import SSFees from '../../utility/SSFeeTable';
import RequestSender from '../../utility/SendRequest';

import ModalNamedAction from '../Modals/ModalNamedAction';
import ModalPortfolioList from '../Modals/ModalPortfolioList';
import ModalOkay from '../Modals/ModalOkay';
import { ConfigContext } from '../../index';
import CreatePortfolioDeleter from '../../utility/DeletePortfolio';

interface IProps {
    onProjectionResponse: any;
    onBacktestResponse: any;
    onTabChange: React.Dispatch<React.SetStateAction<number>>;
    onRequest: React.Dispatch<React.SetStateAction<any>>;
    setRetirementLoader: React.Dispatch<React.SetStateAction<boolean>>;
    setBacktestLoader: React.Dispatch<React.SetStateAction<boolean>>;
    setUserData: React.Dispatch<React.SetStateAction<IUserData<IPortfolio[]>|undefined>>;
    userData: IUserData<IPortfolio[]> | undefined;
    canDownload : boolean;
    formModal: Function;
    downloadHandler(name:string, callback?: Function): ISubmissionResponse;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function NeuInputs(props : IProps) {

    const { onProjectionResponse, onBacktestResponse, onTabChange, onRequest, setBacktestLoader, setRetirementLoader,userData,setUserData,canDownload,formModal,downloadHandler,setIsModalOpen} = props;

    const config :any = useContext(ConfigContext);

    const [investmentEquivalentFees, setInvestmentEquivalentFees] = useState(0);
    const [additionalComparisonFees, setAdditionalComparisonFees] = useState(0.9);
    const [investmentEquivalentAlpha, setInvestmentEquivalentAlpha] = useState(0);
    const [additionalComparisonAlpha, setAdditionalComparisonAlpha] = useState(0);
    const [slideFocus, setSlideFocus] = useState("Asset"); 

    const [portfolioName, setPortfolioName] = useState("");

    const [australianEquityWeight, setAustralianEquityWeight] = useState(0);
    const [intlEquityHedgedWeight,setIntlEquityHedgedWeight] = useState(0);
    const [intlEquityUnhedgedWeight,setIntlEquityUnhedgedWeight] = useState(0);
    const [auGovFixedIncomeWeight,setAuGovFixedIncomeWeight] = useState(0);
    const [auCorpFixedIncomeWeight,setAuCorpFixedIncomeWeight] = useState(0);
    const [intlGovFixedIncomeWeight,setIntlGovFixedIncomeWeight] = useState(0);
    const [auListedPropertyWeight,setAuListedPropertyWeight] = useState(0);
    const [auDirectPropertyWeight,setAuDirectPropertyWeight] = useState(0);
    const [australianCashWeight, setAustralianCashWeight] = useState(0);
    
    const [smartShieldChoice, setSmartShieldChoice] = useState(Portfolios.highgrowth);
    const [smartShieldChoiceValue, setSmartShieldChoiceValue] = useState(0);
    const smartShieldSetters = { setSmartShieldChoice, setSmartShieldChoiceValue };
    const ssChoiceData = {smartShieldChoice,smartShieldChoiceValue};

    const [ currentAge, setCurrentAge ] = useState(67);
    const [ targetAge, setTargetAge] = useState(90);
    const [ currentBalance, setCurrentBalance] = useState(500000)
    const [ annualWithdrawal, setAnnualWithdrawal] = useState(20000);
    const [ ssEquivalent, setSSEquivalent] = useState("RPBALANCED");
    const [ investmentEquivalent, setInvestmentEquivalent] = useState("RPBALANCED");
    const [ additionalComparison, setAdditionalComparison] = useState("MODERATE");

    const [additionalCompBuffer, setAdditionalCompBuffer] = useState("MODERATE"); //This is required to make that janky client portfolio dropdown work
    const isUserDefined = additionalCompBuffer === "USERDEFINEDALLOCATION";

    const customAssetAllocationSetters = { setPortfolioName,setAustralianEquityWeight, setIntlEquityHedgedWeight, setIntlEquityUnhedgedWeight, setAuGovFixedIncomeWeight, setAuCorpFixedIncomeWeight, setIntlGovFixedIncomeWeight, setAuListedPropertyWeight, setAuDirectPropertyWeight, setAustralianCashWeight }; 
    const customAssetAllocationValues = { australianEquityWeight, intlEquityHedgedWeight, intlEquityUnhedgedWeight, auGovFixedIncomeWeight, auCorpFixedIncomeWeight, intlGovFixedIncomeWeight, auListedPropertyWeight, auDirectPropertyWeight, australianCashWeight }; 

    const slideOutValues = { slideFocus, investmentEquivalentFees, investmentEquivalentAlpha, additionalComparisonFees, additionalComparisonAlpha };
    const slideOutSetters = {setInvestmentEquivalentFees, setInvestmentEquivalentAlpha, setAdditionalComparisonFees, setAdditionalComparisonAlpha};

    const standardInputValues = {currentAge, targetAge, currentBalance, annualWithdrawal, ssEquivalent, investmentEquivalent, additionalComparison, isGfc:true}; //

    const { AssetAllocation, RiskProfile} = require('../../JSProtoBuffer/GeneratedCode/Types_pb');
    const assetAllocationEnums = Object.keys(AssetAllocation);
    const riskProfileSSEnums = Object.keys(RiskProfile);

    const currentAgeHelp = "The current age of the client at the start of the simulation.  This can be selected to be any age between ages 55 and 90.";
    const targetAgeHelp = "The age of the client at the end of the projection period. This can be selected to be any age between ages 65 and 110, and must be larger than Current Age.  It must also be between 10 and 40 years after Current Age.";
    const currentBalanceHelp = "The amount of any investment assets to be included in projections.";
    const annualIncomeHelp = "The amount of income to be drawn from the product each year.  This amount is assumed to be deducted in each time period within the backtests and simulations, and is assumed to vary each year in line with changes in Consumer Price Inflation (CPI).";
    const ssHelp = "This portfolio assumes 100% investment in one of the available SmartShield Series portfolios:  High Growth, Growth, Balanced or Moderate.  Fees for these options will be set equal to the actual fees (model manager and investment) payable on the relevant portfolio.  No alpha is assumed to apply to this portfolio.";
    const referenceHelp = "This portfolio assumes 100% investment in a portfolio constructed in line with the Morningstar Australia Multisector Category Average benchmarks: Aggressive, Growth, Balanced or Moderate.  Returns used in Backtests for these portfolios are net of fees and any alpha.  For Retirement Outcomes, fees are based on assumptions representative of the Morningstar benchmarks, and zero alpha. For the Covid-19 scenario, the Morningstar Australia MultiSector Indices have been used instead."
    const clientHelp = "A user-defined portfolio can be constructed by providing asset allocation weights to a number of standard asset classes, or to an investment in one of the SmartShield Series portfolios.  Fees and alpha can be entered for the non-SmartShield Series portfolio elements.  For SmartShield Series portfolios, actual fees and no alpha will be assumed.";

    const customSetters = [
        { name: "Smart Shield", value: 0, setter: () => { }},
        { name: "AU Equity", value: australianEquityWeight, setter: setAustralianEquityWeight },
        { name: "Intl Equity Hedged", value: intlEquityHedgedWeight, setter:setIntlEquityHedgedWeight },
        { name: "Intl Equity Unhedged", value: intlEquityUnhedgedWeight, setter: setIntlEquityUnhedgedWeight },
        { name: "AU Govt Fixed Income", value: auGovFixedIncomeWeight, setter:setAuGovFixedIncomeWeight },
        { name: "AU Corp Fixed Income", value: auCorpFixedIncomeWeight, setter:setAuCorpFixedIncomeWeight },
        { name: "Intl Govt Fixed Income", value: intlGovFixedIncomeWeight, setter:setIntlGovFixedIncomeWeight},
        { name: "AU Listed Property", value: auListedPropertyWeight, setter:setAuListedPropertyWeight },
        { name: "AU Direct Property", value: auDirectPropertyWeight, setter:setAuDirectPropertyWeight },
        { name: "AU Cash", value: australianCashWeight, setter: setAustralianCashWeight } ]


    const customAssetAllocationForm = (
        <NeuFeeStack headers={["Asset Class", "Weight", "Fees"]} SSFee={SSFees[smartShieldChoice]}
            assetFee={additionalComparisonFees} setters={customSetters} smartShieldChoice={smartShieldChoice} setSmartShieldChoice={setSmartShieldChoice}
            smartShieldChoiceValue={smartShieldChoiceValue} setSmartShieldChoiceValue={setSmartShieldChoiceValue as React.Dispatch<React.SetStateAction<number | null>>}
            setPortfolioName={setPortfolioName}/> 
    );

    //const loadPortfolioButton = <NeuLoadPortfolio />

    const slideOutCloser = closeSlideOut(setAdditionalComparison, additionalCompBuffer, [smartShieldChoiceValue, ...Object.values(customAssetAllocationValues)], isUserDefined, formModal);
    const requestSender = RequestSender({inputValidator:createValidator(formModal), standardInputValues, slideOutValues, customAssetAllocationValues, smartShieldChoiceValue, smartShieldChoice, onRequest, setRetirementLoader, setBacktestLoader, onProjectionResponse, onBacktestResponse, config, userData });


    const doWhenDownloadClicked = useCallback(() => {
        const downloadModalContent = <ModalNamedAction submitHandler={downloadHandler} buttonText="Download" placeholderText="PDF Name..." inProgressText="Creating your PDF.  This may take a moment..." />;
        formModal("Download PDF", "Save the current simulation outputs as a PDF", downloadModalContent, 100);
    },[downloadHandler])

    const doWhenSavingPortfolio = () => {
        if (!!userData) {
            const savePortfolio = CreatePortfolioSaver(customAssetAllocationValues, slideOutValues, { smartShieldChoice, smartShieldChoiceValue }, userData, setUserData);
            const savePortfolioModalContent = <ModalNamedAction submitHandler={savePortfolio} buttonText="Save" inProgressText="Saving..." placeholderText="Portfolio Name..." submitCallback={(alertElement) => { if (alertElement) { alertElement.innerText = "Portfolio saved." } }}  maxLength={40}   disableDoubleSubmit/>;

            const cleanUp = () => {
                const alertRoot = document.getElementById("MODAL_ALERT_ROOT");
                if (alertRoot) {
                    alertRoot.innerText = "";
                }
            }

            formModal("Save Portfolio", "Save the current custom portfolio for later use.", savePortfolioModalContent, 100, cleanUp);
        }
    };

    const doWhenPorfolioSelected = useCallback(createValueSetter(customAssetAllocationSetters, slideOutSetters, smartShieldSetters), [customAssetAllocationSetters, slideOutSetters,smartShieldSetters]);
    const showPortfolioList = () => {
        const cleanUp = () => {
            const alertRoot = document.getElementById("MODAL_ALERT_ROOT");
            if (alertRoot) {
                alertRoot.innerText = "";
            }
        }
        if (userData) {
            const portfolioDeleter = CreatePortfolioDeleter(userData.id);
            const portfolioList = <ModalPortfolioList portfolios={userData.portfolioData} onPortfolioSelect={doWhenPorfolioSelected} portfolioDeleter={portfolioDeleter} />;
            formModal("Load Portfolio", "Select the portfolio you want to load", portfolioList, 100,cleanUp);
        }
    }

    const allStackValues = Object.values(customAssetAllocationValues).concat([smartShieldChoiceValue]);
    const canSave = allStackValues.reduce((acc, num) => { return acc+num },0) == 100;
    const savePortfolioButton = <NeuButton text="SAVE PORTFOLIO" onClick={doWhenSavingPortfolio} enabled={!!userData} />;
    const loadPortfolioButton = <NeuButton text="LOAD PORTFOLIO" onClick={showPortfolioList} enabled={!!userData} inverted />;
    return (
        <div style={{position:"relative"}} className="inputsContainer">

            <NeuTabs onTabChange={onTabChange}/> 

            <div className="inputs easeWidth">
                <header><h1>SmartShield</h1></header>
                <div className="formColumns-2">
                    <div className="formColumn">
                        <div className="column-2">
                            <NeuInputBox placeholder="0" currentValue={standardInputValues.currentAge} label="Current Age" onChange={setCurrentAge} helpGenerator={makeHelpElement(1,currentAgeHelp)} range={[55, 90]} numbersOnly/>               
                            <NeuInputBox placeholder="0" currentValue={standardInputValues.targetAge} label="Target Age" onChange={setTargetAge} helpGenerator={makeHelpElement(2,targetAgeHelp)}  range={[65,110]} numbersOnly/>               
                        </div>
                        <NeuInputBox placeholder="$0" label="Current Balance" onChange={setCurrentBalance} currentValue={currentBalance}  helpGenerator={makeHelpElement(3,currentBalanceHelp)}  range={[1,Number.MAX_SAFE_INTEGER]} numbersOnly />
                        <NeuInputBox placeholder="$0" label="Annual Income" onChange={setAnnualWithdrawal} currentValue={annualWithdrawal}  helpGenerator={makeHelpElement(4,annualIncomeHelp)}  range={[0, Number.MAX_SAFE_INTEGER]} numbersOnly />
                        <NeuSelect currentValue={ssEquivalent} shape={InputShapes.investmentSmartShield} options={riskProfileSSEnums} label="SmartShield Portfolio" optionsMapper={PToE(true)} helpGenerator={makeHelpElement(5,ssHelp)} onChange={(value: string) => { setSSEquivalent(value); setInvestmentEquivalent(value.replace(/^SS/gi, '')); }}   />
                        <NeuInputBox shape={InputShapes.investmentEquivalent} placeholder="$0" label="Reference Portfolio" currentValue={PToE(false)(investmentEquivalent)}  helpGenerator={makeHelpElement(6,referenceHelp)}  readonly />
                        <NeuExpando label="Client Portfolio" currentValue={additionalComparison} onChange={setAdditionalCompBuffer} options={assetAllocationEnums} optionsMapper={PToE(false)} helpGenerator={makeHelpElement(7,clientHelp)}  zIndex={6} />
                        <ClickVeil zIndex={5} onClick={slideOutCloser} />
                        <NeuButton onClick={requestSender} text="Calculate" enabled={!!userData && !!userData.id}/>
                        <NeuButton onClick={doWhenDownloadClicked} text="Save as PDF >" enabled={canDownload} inverted/>
                        <div className="legals">
                            Copyright © 2024 Milliman, Inc. All Rights Reserved
                            <div>
                                <a href="/tnc">Terms of use</a>
                                <a href="/disclaimer">Disclaimer</a>
                                <a href="/privacy">Privacy policy</a>
                            </div>
                            <div><span>For more on SmartShield visit us <a style={{display: "table-cell"}} href="https://advice.milliman.com/en/" target="_blank">here</a></span></div>
                        </div>
                    </div>
                    <NeuSlideOut {...{ customAssetAllocationForm, additionalComparisonFees, additionalComparisonAlpha ,slideOutSetters, isUserDefined, savePortfolioButton, loadPortfolioButton, portfolioName, canSave}} closeSlideOut={slideOutCloser} zIndex={5} />
                </div>
            </div>

        </div>
    );        

}

//<DisplayPortfolio doWhenPortolioIsSelected={doWhenPorfolioSelected} zIndex={5} portfolios={userData ? userData.portfolios : []}/>

function closeSlideOut(confirmAdditionalComparison: Function, potentialAdditionalComparison: string, feeStackValues: number[], isOnUserDefined:boolean, formModal:Function, onInvalidFeeStack ?: Function) {
    return () => {
        const sum = feeStackValues.reduce((acc, val) => { return acc + val; });

        if (sum == 100 || !isOnUserDefined) {
            const $ = require('jquery');
            $('.editableSelect').removeClass("expanded");
            $('.inputs').removeClass("expanded");
            $(".editableSelect li").removeClass("expanded");
            $(".legals").removeClass("voided");
            $(".veil").addClass("hideVeil");
            confirmAdditionalComparison(potentialAdditionalComparison);
        } else {
            if (onInvalidFeeStack) {
                onInvalidFeeStack()
            } else {
                const modalContent = <ModalOkay text="The total of all weightings for a custom portfolio must equal 100%.  Please modify these values or select a non-custom client portfolio option." />;
                formModal("Invalid Weights", "We can't process your custom portfolio.", modalContent);
            }
        }
    }
}


function createValidator(formModal: Function) {
    return (standardInputValues: any, slideOutValues: any) => {

        let isValid = true;
        let alertText = "";

        //The grpc can only handle values in these ranges
        if (standardInputValues["targetAge"] - standardInputValues["currentAge"] > 40) {
            alertText = "Gap between current age and target age must not be greater than 40.  Please try again.";
            isValid = false;
        } else if (standardInputValues["targetAge"] - standardInputValues["currentAge"] < 10) {
            alertText = "Gap between current age and target age must not be less than 10.  Please try again.";
            isValid = false;
        } else if (standardInputValues["currentAge"] < 55 || standardInputValues["currentAge"] > 90) {
            alertText = "Current age must be between 55 - 90 (inclusive).  Please try again.";
            isValid = false;
        } else if (standardInputValues["targetAge"] < 65 || standardInputValues["targetAge"] > 110) {
            alertText = "Target age must be between 65 - 110 (inclusive).  Please try again.";
            isValid = false;
        }

        const trueFee = slideOutValues["additionalComparisonFees"] - slideOutValues["additionalComparisonAlpha"];
        if ((trueFee > 2.0 || trueFee < -2.0) && standardInputValues.additionalComparison !== "UserDefinedAllocation.") {
            alertText = `Difference between fees and alpha for the client portfolio is currently ${trueFee} which is not within the allowed -2% to %2 range.  Please modify the fees and alpha in Client Portfolio settings.`;
            isValid = false;
        }

        if (standardInputValues["currentBalance"] <= 0) {
            alertText = `Current Balance must be greater than 0. Please increase these values.`;
            isValid = false;
        }

        if (standardInputValues["annualWithdrawal"] < 0) {
            alertText = `Annual Income must not be negative. Please increase these values.`;
            isValid = false;
        }

        if (!isValid) {
            const modalContents = <ModalOkay text={alertText}/>
            formModal("Invalid Inputs","We've highlighted which fields require adjustment.", modalContents);
        }
        return isValid;
    }
}


function makeHelpElement(helpID:number, helpText:string){
    return (title:string)=>{
        return <NeuHelp title={title} description={helpText} helpId={helpID}/>;
    }
}

function createValueSetter( customSetters:any, comparisonSetters:any, smartShieldSetters:any) {

    const { setPortfolioName,setAustralianEquityWeight, setIntlEquityHedgedWeight, setIntlEquityUnhedgedWeight, setAuGovFixedIncomeWeight,
        setAuCorpFixedIncomeWeight, setIntlGovFixedIncomeWeight, setAuListedPropertyWeight, setAuDirectPropertyWeight, setAustralianCashWeight } = customSetters;
    const { setAdditionalComparisonFees, setAdditionalComparisonAlpha } = comparisonSetters;
    const { setSmartShieldChoice, setSmartShieldChoiceValue } = smartShieldSetters;

    return (customPortfolioData: IPortfolio) => {
        const { fee, alpha, australianEquity, intlEquityHedged, intlEquityUnhedged, auGovFixedIncome,
            auCorpFixedIncome, intlGovFixedIncome, auListedProperty, auDirectProperty, australianCash,smartshieldSelection, smartshieldValue, portfolioName } = customPortfolioData;

        setPortfolioName(portfolioName);
        setAustralianEquityWeight(australianEquity);
        setIntlEquityHedgedWeight(intlEquityHedged);
        setIntlEquityUnhedgedWeight(intlEquityUnhedged);
        setAuGovFixedIncomeWeight(auGovFixedIncome);
        setAuCorpFixedIncomeWeight(auCorpFixedIncome);
        setIntlGovFixedIncomeWeight(intlGovFixedIncome);
        setAuListedPropertyWeight(auListedProperty);
        setAuDirectPropertyWeight(auDirectProperty);
        setAustralianCashWeight(australianCash);
        setAdditionalComparisonFees(fee);
        setAdditionalComparisonAlpha(alpha);

        setSmartShieldChoice(smartshieldSelection);
        setSmartShieldChoiceValue(smartshieldValue);

    }
}
