
export default function digitToMonthName(month:number, isLong?: boolean){
    switch (month) {
        case 1:
            return isLong ? "January" : 'JAN';
            
        case 2:
            return isLong ? "February" : 'FEB';
            
        case 3:
            return isLong ? "March" : 'MAR';
            
        case 4:
            return isLong ? "April" : 'APR';
            
        case 5:
            return isLong ? "May" : 'MAY';
            
        case 6:
            return isLong ? "June" : 'JUN';
            
        case 7:
            return isLong ? "July" : 'JUL';
            
        case 8:
            return isLong ? "August" : 'AUG';
            
        case 9:
            return isLong ? "September" : 'SEP';
            
        case 10:
            return isLong ? "October" : 'OCT';
            
        case 11:
            return isLong ? "November" : 'NOV';
            
        case 12:
            return isLong ? "December" : 'DEC';
    }
}
